import { Activity, AggregatedJob, AggregatedTeamMember, Job } from "dashboard/miter";
import pluralize from "pluralize";
import { useCallback, useMemo } from "react";
import { useTeamMemberGroupUnfurler } from "../abilities-hooks/useTeamMemberScopes";
import { useActiveCompany, useActivities, useJobs, useTeam } from "../atom-hooks";

type AccessFilterEntity = Activity | AggregatedJob | Job;

type GlobalAccessFilterEntity = Activity | AggregatedJob | Job | AggregatedTeamMember;

type TeamMemberIdFuncParams = {
  entity: AccessFilterEntity;
  accessType?: string;
};

type EntityIdFuncParams = {
  teamMember: AggregatedTeamMember;
};

type AccessFilter = {
  entityName: string;
};

type AccessFilterResults = {
  teamMemberIds: ({ entity }: TeamMemberIdFuncParams) => Set<string>;
  excludedTeamMemberIds: ({ entity }: TeamMemberIdFuncParams) => Set<string>;
  entityIds: ({ teamMember }: EntityIdFuncParams) => Set<string>;
};

export const useAccessFilter = ({ entityName }: AccessFilter): AccessFilterResults => {
  const teamMembers = useTeam();
  const activeCompany = useActiveCompany();

  const activities = useActivities();
  const jobs = useJobs();
  const entities = useMemo((): AccessFilterEntity[] => {
    switch (entityName) {
      case "activity":
        return activities;
      case "job":
        return jobs;
      default:
        return [];
    }
  }, [entityName, activities, jobs]);

  const globalAccessFilter = activeCompany?.settings?.[pluralize(entityName, 2)]?.global_access_filter;
  const departmentEnabled = !!globalAccessFilter?.department;
  const locationEnabled = !!globalAccessFilter?.location;
  const grouping = globalAccessFilter?.grouping || "or";
  const unfurlTeamMemberGroup = useTeamMemberGroupUnfurler();

  const evaluateAccessFilters = useCallback(
    (entityA: GlobalAccessFilterEntity, entityB: GlobalAccessFilterEntity): boolean => {
      const entityALocationId = entityA.location_id ?? undefined;
      const entityADepartmentId = entityA.department_id ?? undefined;
      const entityBLocationId = entityB.location_id ?? undefined;
      const entityBDepartmentId = entityB.department_id ?? undefined;

      if (departmentEnabled && !locationEnabled) {
        return entityADepartmentId === entityBDepartmentId;
      }

      if (!departmentEnabled && locationEnabled) {
        return entityALocationId === entityBLocationId;
      }

      if (departmentEnabled && locationEnabled) {
        const departmentMatch = entityADepartmentId === entityBDepartmentId;
        const locationMatch = entityALocationId === entityBLocationId;

        return grouping === "and" ? departmentMatch && locationMatch : departmentMatch || locationMatch;
      }

      return true;
    },
    [departmentEnabled, locationEnabled, grouping]
  );

  const applyGlobalAccessFilterOnEntity = useCallback(
    (entity: AccessFilterEntity): Set<string> => {
      if (!departmentEnabled && !locationEnabled)
        return new Set(teamMembers.map((teamMember) => teamMember._id));

      const tmIds = teamMembers
        .filter((teamMember) => evaluateAccessFilters(teamMember, entity))
        .map((tm) => tm._id);

      return new Set(tmIds);
    },
    [evaluateAccessFilters, teamMembers, departmentEnabled, locationEnabled]
  );

  const applyCustomAccessFilterOnEntity = useCallback(
    (entity: AccessFilterEntity): Set<string> => {
      const includedGroups = entity.custom_access_filter?.included_groups || [];
      const excludedGroups = entity.custom_access_filter?.excluded_groups || [];

      // if enable_custom_access_filter is true but neither included_groups or
      // excluded_groups is specified, then no team member has access to the entity
      if (includedGroups.length === 0 && excludedGroups.length === 0) return new Set();

      const excludedTeamMembers = excludedGroups.length === 0 ? [] : unfurlTeamMemberGroup(excludedGroups);
      const excludedTMIds = new Set(excludedTeamMembers.map((teamMemberExcluded) => teamMemberExcluded._id));

      // if enable_custom_access_filter is true but only excluded_groups are specified,
      // then respect global filters for all other team members
      if (includedGroups.length === 0 && excludedGroups.length !== 0) {
        const teamMembersWithGlobalFilterAccess = applyGlobalAccessFilterOnEntity(entity);

        excludedTMIds.forEach((excludedTMId) => {
          if (teamMembersWithGlobalFilterAccess.has(excludedTMId))
            teamMembersWithGlobalFilterAccess.delete(excludedTMId);
        });

        return teamMembersWithGlobalFilterAccess;
      }

      const includedTeamMembers = unfurlTeamMemberGroup(includedGroups);

      // if enable_custom_access_filter is true and both included_groups and excluded_groups
      // are specified, then give access only to team members that are in the included_groups
      // and not the excluded_groups
      const entityAccessibleTeamMembers = includedTeamMembers.filter(
        (includedTeamMember) => !excludedTMIds.has(includedTeamMember._id)
      );

      return new Set(entityAccessibleTeamMembers.map((tm) => tm._id));
    },
    [applyGlobalAccessFilterOnEntity, unfurlTeamMemberGroup]
  );

  const teamMemberIds = useCallback(
    ({ entity }: TeamMemberIdFuncParams): Set<string> => {
      if (entity.enable_custom_access_filter) {
        return applyCustomAccessFilterOnEntity(entity);
      }

      return applyGlobalAccessFilterOnEntity(entity);
    },
    [applyCustomAccessFilterOnEntity, applyGlobalAccessFilterOnEntity]
  );

  const excludedTeamMemberIds = useCallback(
    ({ entity }: TeamMemberIdFuncParams): Set<string> => {
      if (entity.enable_custom_access_filter) {
        const excludedGroups = entity.custom_access_filter?.excluded_groups || [];
        const excludedTeamMembers = excludedGroups.length === 0 ? [] : unfurlTeamMemberGroup(excludedGroups);
        const excludedTMIds = new Set(
          excludedTeamMembers.map((teamMemberExcluded) => teamMemberExcluded._id)
        );

        return excludedTMIds;
      }

      return new Set();
    },
    [unfurlTeamMemberGroup]
  );

  const entityIds = useCallback(
    ({ teamMember }: EntityIdFuncParams): Set<string> => {
      const filteredEntities = entities.filter((entity) => {
        if (entity.enable_custom_access_filter) {
          const includedGroups = entity.custom_access_filter?.included_groups || [];
          const excludedGroups = entity.custom_access_filter?.excluded_groups || [];

          // if enable_custom_access_filter is true but neither included_groups or
          // excluded_groups is specified, then the team member does not have access
          // to the activity
          if (includedGroups.length === 0 && excludedGroups.length === 0) return false;

          const excludedTeamMembers =
            excludedGroups.length === 0 ? [] : unfurlTeamMemberGroup(excludedGroups);
          const excludedTMIds = new Set(
            excludedTeamMembers.map((teamMemberExcluded) => teamMemberExcluded._id)
          );

          // if enable_custom_access_filter is true but only excluded_groups are specified,
          // then check if team member is not in the excluded_groups and has global access
          if (includedGroups.length === 0 && excludedTMIds.size !== 0) {
            return !excludedTMIds.has(teamMember._id) && evaluateAccessFilters(entity, teamMember);
          }

          const includedTeamMembers = unfurlTeamMemberGroup(includedGroups);
          const entityAccessibleTeamMembers = includedTeamMembers.filter(
            (includedTeamMember) => !excludedTMIds.has(includedTeamMember._id)
          );

          // if enable_custom_access_filter is true and both included_groups and excluded_groups
          // are specified, then only give access only to the team member if they are in the
          // included_groups and not the excluded_groups
          return entityAccessibleTeamMembers.some((tm) => tm._id === teamMember._id);
        } else if (departmentEnabled || locationEnabled) {
          return evaluateAccessFilters(entity, teamMember);
        }

        // access filters aren't enabled the team member has access to the activity
        return true;
      });

      return new Set(filteredEntities.map((filteredEntity) => filteredEntity._id));
    },
    [unfurlTeamMemberGroup, evaluateAccessFilters, departmentEnabled, locationEnabled, entities]
  );

  return useMemo(
    () => ({ teamMemberIds, excludedTeamMemberIds, entityIds }),
    [teamMemberIds, excludedTeamMemberIds, entityIds]
  );
};
