import React, { FC, useState, useMemo, useEffect } from "react";
import { ActionModal, Formblock, Notifier } from "ui";
import { useForm } from "react-hook-form";
import { Option } from "ui/form/Input";
import {
  TeamMemberGroupSelectValue,
  TeamMemberOptionGroup,
  useTeamMemberGroupOptions,
} from "dashboard/components/team-members/useTeamMemberGroupOptions";
import { isMiterRep, notNullish } from "miter-utils";
import { OnboardingCustomTaskTableRow } from "./OnboardingCustomTasksTable";
import styles from "./OnboardingItemModal.module.css";
import { useUser } from "dashboard/hooks/atom-hooks";
import { DueDateForm, DueDateFormProps, DueType } from "dashboard/components/team-members/DueDateForm";
import {
  TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
  TEAM_MEMBER_GROUP_EXCLUDED_GROUPS,
  DEFAULT_ASSIGNEE_EXCLUDED_GROUPS,
  DEFAULT_ASSIGNEE_EXCLUDED_TYPES,
} from "dashboard/pages/team-members/teamSettings/OnboardingItemModal";
import pluralize from "pluralize";

export type OnboardingCustomTaskFormParams = {
  _id: string;
  title: string;
  description?: string;
  default_due_days_from_start: number | null;
  default_team_member_checklists: Option<TeamMemberGroupSelectValue>[] | null;
  default_assignee: Option<TeamMemberGroupSelectValue>[] | null;
};

const buildDefaultValues = (
  teamMemberGroupOptions: TeamMemberOptionGroup[],
  object?: OnboardingCustomTaskTableRow
): OnboardingCustomTaskFormParams | undefined => {
  if (!object) {
    return {
      _id: "",
      title: "",
      description: undefined,
      default_due_days_from_start: null,
      default_team_member_checklists: [],
      default_assignee: [],
    };
  }

  const teamMemberOptions = teamMemberGroupOptions.flatMap((group) => group.options);

  const defaultTeamMemberChecklists = (object.default_team_member_checklists || [])
    .map((group) => {
      const option = teamMemberOptions.find(
        (tm) => tm.value?.type === group.type && tm.value?.value === group.value
      );
      return option;
    })
    .filter(notNullish);

  const defaultAssignee = (object.default_assignee || [])
    .map((group) => {
      const option = teamMemberOptions.find(
        (tm) => tm.value?.type === group.type && tm.value?.value === group.value
      );
      return option;
    })
    .filter(notNullish);

  return {
    _id: object._id,
    title: object.title ?? "",
    description: object.description,
    default_due_days_from_start: object.default_due_days_from_start ?? null,
    default_team_member_checklists: defaultTeamMemberChecklists || [],
    default_assignee: defaultAssignee || [],
  };
};

export type CustomModalProps = {
  selectedObject?: OnboardingCustomTaskTableRow;
  onHide: () => void;
  handleRemove: (selectedRows: OnboardingCustomTaskTableRow[]) => Promise<void>;
  handleUpdate: (row: OnboardingCustomTaskFormParams) => Promise<void>;
  handleCreate: (row: OnboardingCustomTaskFormParams) => Promise<void>;
  resourceName?: string;
};

const OnboardingCustomTaskModal: FC<CustomModalProps> = ({
  selectedObject,
  onHide,
  handleRemove,
  handleUpdate,
  handleCreate,
  resourceName,
}) => {
  const activeUser = useUser();
  const [loading, setLoading] = useState<boolean>(false);

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: !isMiterRep(activeUser),
    labelOverrides: TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
    excludedGroups: TEAM_MEMBER_GROUP_EXCLUDED_GROUPS,
  });

  const defaultAssigneeOptions = useTeamMemberGroupOptions({
    hideMitosaurs: !isMiterRep(activeUser),
    labelOverrides: TEAM_MEMBER_GROUP_LABEL_OVERRIDES,
    excludedGroups: DEFAULT_ASSIGNEE_EXCLUDED_GROUPS,
    excludedTypes: DEFAULT_ASSIGNEE_EXCLUDED_TYPES,
  });

  const form = useForm<OnboardingCustomTaskFormParams>({
    defaultValues: buildDefaultValues(teamMemberGroupOptions, selectedObject),
  });

  const { control, handleSubmit, errors, watch, setValue, register } = form;

  useEffect(() => {
    register("default_due_days_from_start");
  }, [register]);

  const default_due_days_from_start = watch("default_due_days_from_start");

  const { days, dueType } = useMemo(() => {
    if (default_due_days_from_start == null) {
      return { days: null, dueType: null };
    }
    const days = Math.abs(default_due_days_from_start);
    const dueType =
      default_due_days_from_start && default_due_days_from_start >= 0
        ? "days_from_start"
        : "days_before_start";
    return { days, dueType };
  }, [default_due_days_from_start]);

  const handleDueDateChange: DueDateFormProps["onChange"] = (days, dueType) => {
    const newDueDaysFromStart = dueType === "days_from_start" ? days : days * -1;
    setValue("default_due_days_from_start", newDueDaysFromStart);
  };

  const updateOnboardingCustomTask = async (data: OnboardingCustomTaskFormParams) => {
    setLoading(true);
    if (!data.title) {
      Notifier.error("Title is required.");
      setLoading(false);
      return;
    }

    if (data.default_due_days_from_start != null && isNaN(data.default_due_days_from_start)) {
      Notifier.error("Due date days field must be a number.");
      setLoading(false);
      return;
    }

    if (!data.default_team_member_checklists?.length && !data.default_assignee?.length) {
      Notifier.error("At least one default team member group or employer task assignee must be selected.");
      setLoading(false);
      return;
    }
    if (selectedObject) {
      await handleUpdate({ ...data, _id: selectedObject._id });
    } else {
      await handleCreate(data);
    }
    setLoading(false);
    onHide();
  };

  const handleDelete = async () => {
    if (!selectedObject) return;

    setLoading(true);
    await handleRemove([selectedObject]);
    setLoading(false);
    onHide();
  };

  const renderForm = () => {
    return (
      <div className={`${styles.modalBody}`}>
        <Formblock
          type="text"
          name="title"
          label="Title*"
          className="modal"
          form={form}
          editing={true}
          errors={errors}
        />
        <Formblock
          type="paragraph"
          name="description"
          label="Description"
          className="modal"
          form={form}
          editing={true}
          errors={errors}
        />
        <DueDateForm
          className={styles.dueDateFormMargin}
          days={days}
          dueType={dueType as DueType}
          onChange={handleDueDateChange}
        />
        <Formblock
          type="multiselect"
          name="default_team_member_checklists"
          label="Team member group(s)"
          labelInfo="Select the team member group(s). If a new hire belongs to any of these groups, this task will be added by default when creating their onboarding checklist."
          form={form}
          control={control}
          editing={true}
          className="modal"
          placeholder="Select team member groups"
          options={teamMemberGroupOptions}
          height="unset"
        />
        <Formblock
          type="multiselect"
          name="default_assignee"
          label="Employer task assignee(s)"
          labelInfo="Select employer task assignee(s). When creating a new admin onboarding checklist, this admin task will be assigned to the selected assignee(s) and show up in their Needs Attention inbox."
          form={form}
          control={control}
          editing={true}
          className="modal"
          placeholder="Select assignee groups"
          options={defaultAssigneeOptions}
          height="unset"
        />
      </div>
    );
  };

  return (
    <ActionModal
      headerText={
        selectedObject
          ? `Edit ${pluralize.singular(resourceName || "")}`
          : `Create ${pluralize.singular(resourceName || "")}`
      }
      showSubmit={true}
      showCancel={true}
      showDelete={!!selectedObject}
      deleteDisabled={!selectedObject}
      onDelete={handleDelete}
      deleteText="Remove"
      cancelText="Close"
      onCancel={onHide}
      submitText="Save"
      onHide={onHide}
      onSubmit={handleSubmit(updateOnboardingCustomTask)}
      loading={loading}
      wrapperClassName={"form"}
    >
      {renderForm()}
    </ActionModal>
  );
};

export default OnboardingCustomTaskModal;
