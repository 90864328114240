import { BasicModal, Button, ModalHeader } from "ui";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import Notifier from "dashboard/utils/notifier";
import { useNavigate } from "react-router";
import { BenefitFormFields, useCleanBenefitFormData } from "./BenefitFormUtils";
import { AggregatedTeamMember, CompanyBenefit, EmployeeBenefit, MiterAPI, MiterError } from "dashboard/miter";
import Banner from "dashboard/components/shared/Banner";
import { displayFieldErrors } from "dashboard/utils/errors";
import { useActiveCompanyId, useIsMiterAdmin, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { CleanedCompanyBenefit, CleanedEmployeeBenefit } from "./benefitsUtils";
import { DescendentBenefitFailure } from "backend/services/benefits-service";
import FailuresModal, { FailureItem } from "dashboard/components/shared/FailuresModal";
import { useDisableBackgroundScroll } from "ui/hooks/useDisableBackgroundScroll";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useEmployeeBenefitAbilities } from "dashboard/hooks/abilities-hooks/useEmployeeBenefitAbilities";
import { AuditLogHistoryModal } from "dashboard/components/audit-logs/AuditLogHistoryModal";

type Props = {
  benefitToUpdate?: CleanedEmployeeBenefit | CleanedCompanyBenefit;
  hide: () => void;
  onSuccess: (id) => void;
  isEmployeeBenefit?: boolean;
  employee?: AggregatedTeamMember;
};

export const BenefitModal: React.FC<Props> = (props) => {
  const { hide, benefitToUpdate, onSuccess, employee } = props;
  const activeCompanyId = useActiveCompanyId();
  const isMiterAdmin = useIsMiterAdmin();
  const lookupTeam = useLookupTeam();
  const navigate = useNavigate();

  const miterAbilities = useMiterAbilities();
  const benefitAbilities = useEmployeeBenefitAbilities();
  const cleanBenefitFormData = useCleanBenefitFormData();

  const [saving, setSaving] = useState(false);
  const [failures, setFailures] = useState<FailureItem[]>([]);
  const [showAuditLogModal, setShowAuditLogModal] = useState(false);
  const [createdBenefitId, setCreatedBenefitId] = useState<string>();
  const form = useForm();
  const { handleSubmit } = form;

  const isEmployeeBenefitTest = (
    benefit: CleanedEmployeeBenefit | CleanedCompanyBenefit | undefined
  ): benefit is CleanedEmployeeBenefit | undefined => !!props.isEmployeeBenefit;

  const isEmployeeBenefit = isEmployeeBenefitTest(benefitToUpdate);
  const parentBenefitId = isEmployeeBenefit && benefitToUpdate && benefitToUpdate.company_benefit;

  const canUpdate = () => {
    if (benefitToUpdate) {
      if ("company_benefit" in benefitToUpdate && benefitAbilities.cannot("update", benefitToUpdate)) {
        return false;
      }

      if (!("company_benefit" in benefitToUpdate) && miterAbilities.cannot("benefits:update")) {
        return false;
      }
    }

    return true;
  };

  const renderHeader = () => {
    let headingText: string;
    if (benefitToUpdate) {
      headingText = isEmployeeBenefit
        ? `Update ${benefitToUpdate.miter_tm.first_name}'s benefit`
        : "Update company benefit plan";
    } else {
      headingText = isEmployeeBenefit
        ? employee
          ? `Create a benefit for ${employee.first_name}`
          : "Create employee benefit"
        : "Create company benefit plan";
    }

    return (
      <div className="flex space-between width-100-percent">
        {headingText}

        {isMiterAdmin ? (
          <Button
            className="button-1"
            onClick={() => setShowAuditLogModal(true)}
            text="View Audit Log"
            style={{ marginLeft: "auto", marginRight: 10 }}
          />
        ) : null}
      </div>
    );
  };

  const save = async (data) => {
    if (benefitToUpdate) {
      if (!canUpdate()) {
        Notifier.error("You do not have permission to update benefits.");
        return;
      }
    } else {
      if (miterAbilities.cannot("benefits:create")) {
        Notifier.error("You do not have permission to create benefits.");
        return;
      }
    }

    setSaving(true);
    try {
      const cleanedData = cleanBenefitFormData(data, activeCompanyId!, employee?._id, benefitToUpdate);
      let response: Partial<(EmployeeBenefit | CompanyBenefit) & MiterError>;
      let descendentFailures: DescendentBenefitFailure[] = [];
      if (employee || cleanedData.employee) {
        // If this is a new employee benefit, check to see if employee already has benefit of same type
        if (!benefitToUpdate) {
          response = await MiterAPI.benefits.employee.create(cleanedData);
        } else {
          response = await MiterAPI.benefits.employee.update(benefitToUpdate._id, cleanedData);
        }
      } else {
        if (!benefitToUpdate) {
          response = await MiterAPI.benefits.company.create(cleanedData);
        } else {
          const fullResponse = await MiterAPI.benefits.company.update(benefitToUpdate._id, cleanedData);
          response = fullResponse.error ? (fullResponse as MiterError) : fullResponse.updatedCompanyBenefit;
          descendentFailures = fullResponse.error ? [] : fullResponse.descendentFailures;
        }
      }
      if (response.error) {
        if (response.fields) displayFieldErrors(response.fields || [], form.setError);
        if (response.error_type === "no_overlapping_benefit_effective_dates") {
          Notifier.error(response.error);
        } else {
          throw new Error(response.error);
        }
      } else {
        if (descendentFailures.length > 0) {
          Notifier.warning(`Error saving some descendent benefits`);
          setFailures(
            descendentFailures.map((f) => {
              return {
                label: lookupTeam(f.tmId)?.full_name || "",
                message: f.error,
              };
            })
          );
          setCreatedBenefitId(response._id);
        } else {
          Notifier.success("Benefit saved successfully.");
          onSuccess(response._id);
          hide();
        }
      }
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setSaving(false);
  };

  const renderParentBenefitRedirectModal = () => {
    if (!parentBenefitId) return null;
    return (
      <BasicModal
        button2Action={() => navigate("/benefits/company-plan/" + parentBenefitId)}
        headerText="Update benefit"
        button2Text="Continue"
        button1Action={hide}
        button1Text="Cancel"
      >
        <div className="payroll-approval-text-wrapper">
          <span className="bold">{`${benefitToUpdate.description}`}</span>
          {` is a company benefit plan. You can visit `}
          <span className="blue-link" onClick={() => navigate("/benefits/company-plan/" + parentBenefitId)}>
            the plan&apos;s page
          </span>
          {` to edit the plan or to unenroll ${benefitToUpdate.miter_tm.first_name}.`}
        </div>
      </BasicModal>
    );
  };

  const renderUpdateFailuresModal = () => {
    if (!failures.length) return;

    return (
      <FailuresModal
        headerText={"Update failures"}
        onClose={() => {
          setFailures([]);
          hide();
          if (createdBenefitId) onSuccess(createdBenefitId);
        }}
        failures={failures}
      />
    );
  };

  const renderBanner = () => {
    if (benefitToUpdate?.guideline_id) {
      return (
        <Banner
          content={"Note: This benefit is managed by Guideline."}
          type="modal"
          onClick={() => window.open("https://guideline.com/", "_blank")}
        />
      );
    } else if (benefitToUpdate?.integrations?.employee_navigator?.connected) {
      return (
        <Banner
          content={"Manage this benefit in Employee Navigator."}
          type="modal"
          onClick={() => window.open("https://employeenavigator.com/", "_blank")}
        />
      );
    } else if (benefitToUpdate?.integrations?.payroll_integrations?.external_id) {
      return <Banner content={"Manage this benefit in your 401(k) provider."} type="modal" />;
    } else if (benefitToUpdate?.integrations?.clasp?.clasp_benefit?.id) {
      return (
        <Banner
          content={"Manage this benefit in the benefits administration tab."}
          type="modal"
          onClick={() => navigate("/benefits")}
        />
      );
    } else if (benefitToUpdate?.source === "int_Xf90bJAKE9Lf2jVjbgne") {
      return <Banner content={"Manage this benefit from the SimplyInsured portal."} type="modal" />;
    }
    return <></>;
  };

  useDisableBackgroundScroll();

  return (
    <>
      <div className="modal-background">
        {parentBenefitId ? (
          renderParentBenefitRedirectModal()
        ) : (
          <div className="modal-wrapper form two-column">
            <ModalHeader
              heading={renderHeader()}
              onHide={hide}
              className="modal"
              headingStyle={{ width: "100%" }}
            />
            <div style={{ paddingLeft: 0, paddingRight: 0, overflow: "auto" }}>
              {renderBanner()}
              <div className="modal-body form two-column">
                <BenefitFormFields
                  form={form}
                  benefitToUpdate={benefitToUpdate}
                  employee={employee}
                  isEmployeeBenefit={isEmployeeBenefit}
                  readonly={(!benefitToUpdate && miterAbilities.cannot("benefits:create")) || !canUpdate()}
                />
              </div>
            </div>
            <div className="modal-footer form">
              <button className={saving ? "button-1 inactive " : "button-1"} onClick={hide}>
                Cancel
              </button>
              {!benefitToUpdate && miterAbilities.can("benefits:create") && (
                <Button onClick={handleSubmit(save)} text={"Create"} loading={saving} className="button-2" />
              )}
              {benefitToUpdate && canUpdate() && (
                <Button onClick={handleSubmit(save)} text={"Save"} loading={saving} className="button-2" />
              )}
            </div>
            {renderUpdateFailuresModal()}
          </div>
        )}
      </div>
      {showAuditLogModal && benefitToUpdate && (
        <AuditLogHistoryModal
          itemId={benefitToUpdate._id}
          type="employee_benefit"
          onHide={() => setShowAuditLogModal(false)}
        />
      )}
    </>
  );
};
