import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useEnhancedSearchParams } from "miter-utils";
import {
  FaArrowAltCircleRight,
  FaBullhorn,
  FaClipboardList,
  FaClock,
  FaFileAlt,
  FaHardHat,
  FaUserFriends,
  FaUsers,
} from "react-icons/fa";
import { GetCompanyDashboardDataResponse, MiterAPI } from "dashboard/miter";
import { Loader } from "ui";

import { Helmet } from "react-helmet";
import PayrollBanner from "../payrolls/PayrollBanner";
import {
  useActionableItems,
  useActiveCompany,
  useActiveTeamMember,
  useExpensePolicyOptions,
  useIsSuperAdmin,
  useReimbursementPolicyOptions,
  useTimeOffRequestPolicyOptions,
  useTimesheetPolicyOptions,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useVisibleReports } from "../reports/reportHooks";
import pluralize from "pluralize";
import { SecuritySettingsBanner } from "dashboard/components/security/SecuritySettingsBanner";
import { FilingCheckList } from "./FilingCheckList";
import { DateTime } from "luxon";

type TodoData = GetCompanyDashboardDataResponse;

const Home: React.FC = () => {
  const activeCompany = useActiveCompany();
  const activeTeamMember = useActiveTeamMember();
  const [actionableItems] = useActionableItems();
  const activeCompanyId = activeCompany?._id;
  const user = useUser();
  const { can } = useMiterAbilities();
  const visibleReports = useVisibleReports();
  const [todoData, setTodoData] = useState<TodoData | null>(null);
  const [fetched, setFetched] = useState(false);
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });

  const timesheetPolicyOptions = useTimesheetPolicyOptions();
  const expensePolicyOptions = useExpensePolicyOptions();
  const reimbursementPolicyOptions = useReimbursementPolicyOptions();
  const timeOffRequestPolicies = useTimeOffRequestPolicyOptions();
  const isSuperAdmin = useIsSuperAdmin();

  const hasTimesheetPolicy = timesheetPolicyOptions.length > 0;
  const hasExpensePolicy = expensePolicyOptions.length > 0;
  const hasReimbursementPolicy = reimbursementPolicyOptions.length > 0;
  const hasTimeOffRequestPolicy = timeOffRequestPolicies.length > 0;

  const canShowFilingChecklist = useMemo(() => {
    const today = DateTime.now();
    return isSuperAdmin && today.month >= 11 && !!activeCompany?.has_payroll;
  }, [isSuperAdmin, activeCompany]);

  const getDashboardData = useCallback(async () => {
    setFetched(false);
    try {
      const response = await MiterAPI.companies.get_dashboard_data(activeCompanyId!);

      if (response.error) {
        throw response.error;
      }

      setTodoData(response);
    } catch (e) {
      console.log(e);
    }
    setFetched(true);
  }, [activeCompanyId]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  const renderGreeting = () => {
    const today = new Date();
    const curHr = today.getHours();
    const name = user?.first_name ? " " + user.first_name + "!" : "!";

    let greeting = "";

    if (curHr < 12) {
      greeting = `Good morning${name}`;
    } else if (curHr < 18) {
      greeting = `Good afternoon${name}`;
    } else {
      greeting = `Good evening${name}`;
    }

    return (
      <div className="greeting">
        <p className={"greeting-title"}>{greeting}</p>
        <h1 style={{ fontSize: 27 }}>{"Welcome back to Miter."}</h1>
      </div>
    );
  };

  const quickActionsConfig: QuickActionItem[] = useMemo(() => {
    const actions = [
      {
        show: can("chat:send"),
        path: "/chat?show=broadcast",
        IconComponent: FaBullhorn,
        name: "Send a broadcast",
        isNew: false,
      },
      {
        show: can("timesheets:others:create") || can("timesheets:personal:create"),
        path: "/timesheets?action=create",
        IconComponent: FaClock,
        name: "Create a timesheet",
      },
      {
        show: can("jobs:create"),
        path: "/jobs?action=create",
        IconComponent: FaHardHat,
        name: "Add a new job",
      },
      {
        show: visibleReports.length > 0,
        path: "/reports",
        IconComponent: FaFileAlt,
        name: "Generate a report",
      },
      {
        show: can("team:create"),
        path: "/team-members/new?type=employee",
        IconComponent: FaUserFriends,
        name: "Onboard an employee",
      },
      {
        show: can("team:create"),
        path: "/team-members/new?type=contractor",
        IconComponent: FaUsers,
        name: "Onboard a 1099 contractor",
      },
      {
        name: "Filing Checklist",
        path: "/home?showFiling=true",
        IconComponent: FaClipboardList,
        show: canShowFilingChecklist,
      },
    ];

    return actions.filter((action) => action.show);
  }, [can, visibleReports.length, canShowFilingChecklist]);

  const renderQuickActions = () => {
    return (
      <div className="quick-actions-container">
        <div className="dashboard-subheader">Quick actions</div>
        <div className="quick-actions">
          {quickActionsConfig.map((action, index) => (
            <QuickAction key={"quick-action-" + index} {...action} />
          ))}
          {quickActionsConfig.length === 0 && (
            <div className="quick-actions-item">
              <p className="quick-actions-name">No quick actions available</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const taskConfig = useMemo(() => {
    const timesheetCount =
      hasTimesheetPolicy && activeTeamMember
        ? actionableItems?.counts.timesheets.approvable || 0
        : todoData?.unapproved_timesheets || 0;

    const expenseCount =
      hasExpensePolicy && activeTeamMember ? actionableItems?.counts.expenses.approvable || 0 : 0;

    const reimbursementCount =
      hasReimbursementPolicy && activeTeamMember ? actionableItems?.counts.reimbursements.approvable || 0 : 0;

    const timeOffRequestCount = hasTimeOffRequestPolicy
      ? actionableItems?.counts.time_off_requests.approvable || 0
      : todoData?.unapproved_time_off_requests || 0;

    const tasks: DashboardTask[] = [
      {
        title: "Year End Filing",
        count: 1,
        description: `Review company taxes and team filing information.`,
        link: "/home?showFiling=true",
        show: canShowFilingChecklist,
      },
      {
        title: "Unapproved Timesheets",
        count: timesheetCount,
        description: `You have ${timesheetCount} ${
          timesheetCount === 1 ? "timesheet" : "timesheets"
        } waiting for approval.`,
        link:
          hasTimesheetPolicy && activeTeamMember
            ? "/inbox/approvals/timesheets"
            : "/timesheets?status=unapproved",
        show: timesheetCount > 0,
      },
      {
        title: "Unapproved Card Transactions",
        count: expenseCount,
        description: `You have ${expenseCount} ${pluralize(
          "card transaction",
          expenseCount
        )} waiting for approval.`,
        link: hasExpensePolicy && activeTeamMember ? "/inbox/approvals/expenses" : "/spend/cards/",
        show: expenseCount > 0,
      },
      {
        title: "Unapproved Reimbursements",
        count: reimbursementCount,
        description: `You have ${reimbursementCount} ${
          reimbursementCount === 1 ? "reimbursement" : "reimbursements"
        } waiting for approval.`,
        link:
          hasExpensePolicy && activeTeamMember ? "/inbox/approvals/reimbursements" : "/spend/reimbursements",
        show: reimbursementCount > 0,
      },
      {
        title: "Upcoming Payrolls",
        count: todoData?.draft_payrolls,
        description: `You have ${todoData?.draft_payrolls} draft ${
          todoData?.draft_payrolls === 1 ? "payroll" : "payrolls"
        } waiting for approval.`,
        link: "/payrolls?status=draft",
        show: can("payrolls:approve") || can("payrolls:read"),
      },
      {
        title: "Team Members Needing Attention",
        count: todoData?.blocking_or_needs_attention_tms,
        description: `You have ${todoData?.blocking_or_needs_attention_tms} ${
          todoData?.blocking_or_needs_attention_tms === 1 ? "team member who is" : "team members who are"
        } not fully set up for payroll.`,
        link: "/team-members",
        show: can("team:create") || can("team:update"),
      },
      {
        title: "Unapproved Time Off Requests",
        count: timeOffRequestCount,
        description: `You have ${todoData?.unapproved_time_off_requests} time off ${
          timeOffRequestCount === 1 ? "request" : "requests"
        } waiting for approval.`,
        link: "/time-off/requests?status=unapproved",
        show: can("time_off:requests:others:approve") || can("time_off:requests:personal:approve"),
      },
    ];

    return tasks.filter((task) => task.show);
  }, [
    hasTimesheetPolicy,
    activeTeamMember,
    actionableItems?.counts,
    todoData,
    hasExpensePolicy,
    hasReimbursementPolicy,
    hasTimeOffRequestPolicy,
    can,
    canShowFilingChecklist,
  ]);

  const renderTODO = () => {
    if (taskConfig.every((t) => !t.count)) return <></>;

    return (
      <div className="dashboard-column-left">
        <div className="tasks-container">
          <h2 className="dashboard-subheader">To Do List</h2>
          <div className="tasks">
            {taskConfig.map((task, index) => (
              <DashboardTask key={"task-" + index} {...task} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Home | Miter</title>
      </Helmet>
      {renderGreeting()}
      <SecuritySettingsBanner />
      <PayrollBanner homePage />

      {fetched && (
        <>
          {parsedSearchParams.showFiling === "true" && canShowFilingChecklist && (
            <FilingCheckList hide={() => setSearchParams({ showFiling: "false" })} />
          )}
          <div className="dashboard-columns">
            {renderTODO()}
            <div className="dashboard-column-right">{renderQuickActions()}</div>
          </div>
        </>
      )}
      {!fetched && <Loader />}
    </div>
  );
};

export default Home;

type DashboardTask = {
  count?: number;
  title: string;
  description: string;
  link: string;
  show: boolean;
};

const DashboardTask: FC<DashboardTask> = ({ count, title, description, link }): ReactElement => {
  if (!count) return <></>;

  return (
    <>
      <Link className="task-item" to={link}>
        <div className="task-column-left">
          <p className="task-count">{count}</p>
        </div>
        <div className="task-column-right">
          <p className={"task-title"}>{title}</p>
          <p className={"task-description"}>{description}</p>
          <span className="task-arrow">
            <FaArrowAltCircleRight />
          </span>
        </div>
      </Link>
    </>
  );
};

type QuickActionItem = {
  show?: boolean;
  path: string;
  IconComponent: React.ComponentType<$TSFixMe>;
  name: string;
  isNew?: boolean;
};

const QuickAction: FC<QuickActionItem> = ({ path, IconComponent, name, isNew }) => {
  return (
    <Link className="quick-actions-item" to={path}>
      <IconComponent className="quick-actions-icon" />
      <p className="quick-actions-name">{name}</p>
      {isNew && <div className="new-quick-actions-badge">New!</div>}
    </Link>
  );
};
