import {
  Users,
  Link,
  ArrowsClockwise,
  Book,
  PresentationChart,
  Upload,
  ArrowCircleRight,
} from "phosphor-react";
import React from "react";
import { Helmet } from "react-helmet";
import styles from "../../components/banking/Lander.module.css";
import { Button } from "ui";

const LearningLander: React.FC = () => {
  const renderHeader = () => {
    return (
      <div className={styles["header"]}>
        <h1 className={styles["header-title"]} style={{ fontSize: "1.8rem" }}>
          Learning
        </h1>
        <p className={styles["header-subtitle"]}>
          Train and develop your employees with Miter&apos;s construction-focused learning management system.
        </p>
      </div>
    );
  };

  const renderFeatures = () => {
    const featureElements = learningFeatures.map((feature) => {
      return (
        <div className={styles["feature"]} key={feature.label}>
          <div className={styles["feature-icon"]}>{feature.icon}</div>
          <div className={styles["feature-label"]}>
            <p>{feature.label}</p>
          </div>
        </div>
      );
    });

    return <div className={styles["features"] + " " + styles["learning-features"]}>{featureElements}</div>;
  };

  const handleLearningOnboard = async () => {
    window.open("https://forms.gle/AEgYEcyfUAEL7e8RA", "_blank");
  };

  const renderCTA = () => {
    return (
      <div className={styles["cta"]}>
        <div className="flex">
          <Button
            className={styles["cta-button"] + " button-2 no-margin"}
            onClick={handleLearningOnboard}
            disabled={!!status}
            style={{ marginRight: "10px" }}
          >
            {"Get started with learning"}
            <ArrowCircleRight weight="fill" style={{ marginBottom: -2, marginLeft: 10 }} />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Learning | Miter</title>
      </Helmet>
      <div className="page-content">
        {renderHeader()}
        {renderFeatures()}
        {renderCTA()}
      </div>
    </div>
  );
};

const learningFeatures = [
  {
    icon: <Users color="#4D54B6" weight="duotone" />, // New icon for team management
    label: "Easily set up and manage your team's training programs.",
  },
  {
    icon: <Link color="#4D54B6" weight="duotone" />,
    label: "Employees can complete training in the field and office",
  },
  {
    icon: <ArrowsClockwise color="#4D54B6" weight="duotone" />,
    label: "Generate course completion certificates.",
  },
  {
    icon: <Book color="#4D54B6" weight="duotone" />, // New icon for learning material
    label: "Access a course catalog with 1000s of pre-built courses.",
  },
  {
    icon: <Upload color="#4D54B6" weight="duotone" />, // New icon for course creation
    label: "Build your own courses and training material.",
  },
  {
    icon: <PresentationChart color="#4D54B6" weight="duotone" />, // New icon for training metrics
    label: "Analyze training effectiveness with detailed reports.",
  },
];

export default LearningLander;
