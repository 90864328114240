import { useGateValue } from "@statsig/react-bindings";
import { StatSigFeatureFlagEnum } from "packages/ui/constants";

type FeatureFlagEnum = StatSigFeatureFlagEnum;

/**
 * @param featureFlagEnum this string **must** match the feature flag key in Statsig!
 * @returns boolean value - whether the currently logged in **user** has access to the feature flag
 */
export const useFeatureFlag = (featureFlagEnum: FeatureFlagEnum): boolean => {
  const statsigFeatureGateValue = useGateValue(featureFlagEnum);
  return statsigFeatureGateValue;
};
