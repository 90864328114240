import React from "react";
import { Formblock } from "ui";
import { DeepPartial } from "utility-types";
import { UseFormMethods, FieldValues } from "react-hook-form";
import {
  AcumaticaConfigObject,
  AcumaticaConnectionMetadata,
  AcumaticaDataForConfig,
} from "backend/services/acumatica/acumatica-types";
import { DEFAULT_ACUMATICA_LABEL_STYLE, ACUMATICA_INPUT_LENGTH } from "./AcumaticaConfig";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  updateAcumaticaMetadata: (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => Promise<void>;
  dataForConfig?: AcumaticaDataForConfig;
  configObject?: AcumaticaConfigObject;
  loading?: boolean;
  form: UseFormMethods<FieldValues>;
};

export const AcumaticaFallbackSelections: React.FC<Props> = ({
  updateAcumaticaMetadata,
  configObject,
  form,
}) => {
  const updateDefaultSelection = async (subObj: AcumaticaConfigObject["fallbackSelections"]) => {
    await updateAcumaticaMetadata({ configObject: { fallbackSelections: subObj } }, { collapseCount: 2 });
  };

  const debouncedHandler = useDebouncedCallback(
    (
      property: keyof NonNullable<AcumaticaConfigObject["fallbackSelections"]>,
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      updateDefaultSelection({ [property]: e.target.value || null });
    },
    400
  );

  const { accountGroupId, branchId, costCodeId, laborItemId, expenseItemId, subaccountId } =
    configObject?.fallbackSelections || {};

  return (
    <>
      <Formblock
        form={form}
        name="expenseItemId"
        type="text"
        defaultValue={expenseItemId}
        onChange={(o) => debouncedHandler("expenseItemId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default expense item"
        labelInfo="The expense item Miter will use when pushing expense claims."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="laborItemId"
        type="text"
        defaultValue={laborItemId}
        onChange={(o) => debouncedHandler("laborItemId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default labor item"
        labelInfo="The labor item Miter will use when pushing Journal and Project Transactions for payrolls."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="costCodeId"
        type="text"
        defaultValue={costCodeId}
        onChange={(o) => debouncedHandler("costCodeId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default cost code"
        labelInfo="If a cost code is missing for a project transaction line item, Miter will use this one."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="branchId"
        type="text"
        defaultValue={branchId}
        onChange={(o) => debouncedHandler("branchId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default branch"
        labelInfo="If a branch is missing for a project or journal transaction line item, Miter will use this one."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="accountGroupId"
        type="text"
        defaultValue={accountGroupId}
        onChange={(o) => debouncedHandler("accountGroupId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default account group"
        labelInfo="If the account group (cost type) is missing for a project or journal transaction line item, Miter will use this one."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="subaccountId"
        type="text"
        defaultValue={subaccountId}
        onChange={(o) => debouncedHandler("subaccountId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default subaccount"
        labelInfo="If the subaccount (Miter class) is missing for a project or journal transaction line item, Miter will use this one."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
    </>
  );
};
