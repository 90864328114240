import { createObjectMapToArray } from "dashboard/utils";
import { useMemo } from "react";
import { useLookupPrg, usePrgOptions } from "./atom-hooks";
import { Option } from "ui/form/Input";

export const usePrgOptionsByTradeMap = (): Record<string, Option<string>[]> => {
  const prgLookup = useLookupPrg();
  const prgOptions = usePrgOptions();
  const prgOptionsByTradeMap = useMemo(() => {
    return createObjectMapToArray(prgOptions, (prg) => {
      return prgLookup(prg.value)?.trade_id;
    });
  }, [prgOptions, prgLookup]);

  return prgOptionsByTradeMap;
};
