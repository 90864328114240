import { useMemo } from "react";
import { ActionModal } from "ui";
import { Activity, Job, AggregatedJob } from "dashboard/miter";
import { useAccessFilter } from "dashboard/hooks/access-filters/useAccessFilter";
import { SEARCH_FIELDS } from "dashboard/pages/team-members/TeamMembers";
import TeamMembersTable from "dashboard/pages/team-members/TeamMembersTable";

type EntityTeamMembersModalProps = {
  selectedEntity: Activity | Job | AggregatedJob;
  setOpenTeamMembersModal: (open: boolean) => void;
  accessType: "included" | "excluded";
  entityType: "activity" | "job";
};

export const EntityTeamMembersModal: React.FC<EntityTeamMembersModalProps> = ({
  selectedEntity,
  setOpenTeamMembersModal,
  accessType,
  entityType,
}) => {
  const accessFilters = useAccessFilter({ entityName: entityType });

  const filterByTeamMemberIds = useMemo(() => {
    if (accessType === "included") {
      return [...accessFilters.teamMemberIds({ entity: selectedEntity })];
    } else if (accessType === "excluded") {
      return [...accessFilters.excludedTeamMemberIds({ entity: selectedEntity })];
    }
    return [];
  }, [accessType, accessFilters, selectedEntity]);

  return (
    <ActionModal
      headerText={`Team members ${accessType === "included" ? "with" : "without"} access`}
      showSubmit={false}
      showCancel={false}
      onCancel={() => setOpenTeamMembersModal(false)}
      onHide={() => setOpenTeamMembersModal(false)}
      wrapperStyle={{ minWidth: 1000, maxHeight: 1000 }}
      bodyStyle={{ padding: 30, overflowY: "visible", maxHeight: "fit-content" }}
      hideFooter={true}
    >
      <TeamMembersTable
        filterByTeamMemberIds={filterByTeamMemberIds}
        showToggler={false}
        showMiniTitle={false}
        showButtons={false}
        showSearch={true}
        showReportViews={false}
        searchFields={SEARCH_FIELDS}
        defaultFilters={[]}
      />
    </ActionModal>
  );
};
