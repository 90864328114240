import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { reportList } from "../../reportList";
import { DateTime } from "luxon";
import Notifier from "dashboard/utils/notifier";
import { AggregatedMiterEarning, CustomFieldValue, TeamMember, MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { DateRange } from "ui/form/DateRangePicker";
import { useActiveCompanyId, useLookupJob, useLookupTeam } from "dashboard/hooks/atom-hooks";
import { Assign } from "utility-types";
import { GridApi } from "ag-grid-enterprise";
import { PayRateItem } from "backend/utils/payroll/types";
import { CheckAddress } from "backend/utils/check/check-types";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { useJobHierarchyTableColumns } from "dashboard/utils/jobUtils";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { useEarningSummaryColumnDefs } from "./earningSummaryColumnDefs";

export type EarningsSummaryEntry = Assign<
  AggregatedMiterEarning,
  {
    team_member: TeamMember;
    prg_label?: string;
    classification_label?: string;
    classification_type?: "Rate Group" | "Activity";
    pay_rate_item?: Assign<PayRateItem, { hourly_employer_benefit_contributions: undefined }>;
    address?: CheckAddress;
    burdenRateTotal?: number;
  }
>;

export const CreateEarningsSummary: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const lookupTeam = useLookupTeam();
  const lookupJob = useLookupJob();

  const earningSummaryColumnDefs = useEarningSummaryColumnDefs();

  const jobHierarchyTableColumns = useJobHierarchyTableColumns<EarningsSummaryEntry>();

  const { payroll } = useContext(PayrollContext);
  const [disableRange, setDisableRange] = useState(false);

  const teamAbilities = useTeamAbilities();

  // State
  const [dateRange, setDateRange] = useState<DateRange>({
    start: DateTime.now().minus({ months: 1 }),
    end: DateTime.now(),
  });
  const [gridApi, setGridApi] = useState<GridApi<EarningsSummaryEntry>>();
  const [data, setData] = useState<EarningsSummaryEntry[]>([]);

  const buildDataWithCustomFields = useCallback(
    (earning: EarningsSummaryEntry) => {
      const fullTeamMember = lookupTeam(earning.team_member._id);
      const fullJob = earning.job ? lookupJob(earning.job._id) : null;

      if (!fullJob && !fullTeamMember) return earning;

      const teamMemberCustomFieldValues = fullTeamMember?.custom_field_values.reduce((acc, cfv) => {
        acc[cfv.custom_field_id] = cfv.value;
        return acc;
      }, {} as Record<string, CustomFieldValue["value"]>);

      const jobCustomFieldValues = fullJob?.custom_field_values.reduce((acc, cfv) => {
        acc[cfv.custom_field_id] = cfv.value;
        return acc;
      }, {} as Record<string, CustomFieldValue["value"]>);

      return {
        ...earning,
        ...jobCustomFieldValues,
        ...teamMemberCustomFieldValues,
      };
    },
    [lookupJob, lookupTeam]
  );

  const columns = useMemo(() => {
    const cols = earningSummaryColumnDefs;
    if (payroll?.check_payroll.status === "draft") {
      return cols.filter((col) => col.field !== "burdenRateTotal");
    }
    return [...cols, ...jobHierarchyTableColumns];
  }, [earningSummaryColumnDefs, payroll?.check_payroll.status, jobHierarchyTableColumns]);

  useEffect(() => {
    const getData = async () => {
      if (!activeCompanyId || !gridApi) return;
      gridApi?.showLoadingOverlay();
      try {
        const payload = {
          type: "earnings_summary",
          params: {
            start: payroll ? payroll.check_payroll.period_start : dateRange.start?.toISODate(),
            end: payroll ? payroll.check_payroll.period_end : dateRange.end?.toISODate(),
            company: activeCompanyId,
            draftPayrollId: payroll?._id.toString(),
          },
          format: "json",
        };

        setDisableRange(true);
        const res = await MiterAPI.reports.create(payload);
        setDisableRange(false);

        if (res.error) throw new Error(res.error);

        const miterEarnings: EarningsSummaryEntry[] = res;
        const filteredMiterEarnings = miterEarnings
          .filter((earning) => {
            const teamMember = lookupTeam(earning.team_member._id);
            return !teamMember || teamAbilities.can("read_sensitive", teamMember);
          })
          .map(buildDataWithCustomFields);

        setData(filteredMiterEarnings);
      } catch (e) {
        console.log(e);
        Notifier.error("There was an error retrieving data. We're looking into it!");
      }
      gridApi?.hideOverlay();
    };

    getData();
  }, [activeCompanyId, buildDataWithCustomFields, dateRange, gridApi, lookupTeam, payroll, teamAbilities]);

  const reportObject = reportList.find((report) => report.slug === "earnings_summary");

  const fileName =
    "Miter Earnings Summary " + dateRange.start?.toISODate() + " - " + dateRange.end?.toISODate();

  return (
    <div className={payroll ? "payroll-report-container" : "page-content"}>
      <div className="page-content-header">
        {!payroll && (
          <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
            REPORTS
          </div>
        )}
        <div className="flex">
          <h1 style={{ margin: 0 }}>Earnings summary</h1>
        </div>
      </div>
      <div className="report-page-description">{reportObject?.description}*</div>
      <div className="vertical-spacer-small"></div>
      <>
        <AgGridTable
          reportId="earnings-summary"
          dateRange={payroll ? undefined : dateRange}
          dateRangeLabel="Payday range"
          onDateRangeChange={setDateRange}
          data={data}
          columnDefs={columns}
          fileName={fileName}
          setGridApi={setGridApi}
          disableRange={disableRange}
        />
        <div style={{ marginTop: 25, fontSize: 13, color: "#3C3C3C" }}>
          * Includes earnings associated with paid, processing, and pending payrolls.
        </div>
        <div className="vertical-spacer-large"></div>
      </>
    </div>
  );
};
