import { useCostTypesAtom, useLookupCostType } from "dashboard/hooks/atom-hooks";
import { CostType, MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { Plus, TrashSimple } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { ConfirmModal } from "ui";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { CostTypeModal } from "./CostTypeModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useEnhancedSearchParams } from "miter-utils";

const COST_TYPE_SEARCH_PARAM_KEY = "ctid";

export const CostTypesTable: React.FC = () => {
  //Hooks
  const [costTypes, setCostTypes] = useCostTypesAtom();

  const { can, cannot } = useMiterAbilities();

  // States related to the table
  const tableData = useMemo(() => {
    return costTypes.filter((ct) => ct.is_standard);
  }, [costTypes]);

  const [selectedRows, setSelectedRows] = useState<CostType[]>([]);

  // States related to table actions
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState<boolean>(false);
  const lookupCostType = useLookupCostType();
  const { setSearchParams, parsedSearchParams } = useEnhancedSearchParams();
  const selectedCostType = lookupCostType(parsedSearchParams[COST_TYPE_SEARCH_PARAM_KEY]);
  const [archiving, setArchiving] = useState<boolean>(false);

  const handleArchive = async () => {
    if (cannot("lists:cost_types:manage", { showToast: true })) return;

    setLoading(true);
    try {
      const archivedCostTypes: CostType[] = [];
      await Promise.all(
        selectedRows.map(async (costType) => {
          const response = await MiterAPI.cost_types.archive(costType._id);
          if (response.error) {
            console.error(response.error);
            Notifier.error(`Error deleting "${costType.label}"`);
          } else {
            Notifier.success(`Successfully deleted "${costType.label}"`);
            archivedCostTypes.push(response);
          }
        })
      );

      setCostTypes((prev) => prev.concat(archivedCostTypes));
      setArchiving(false);
      setSelectedRows([]);
    } catch (e) {
      console.error("Outer error deleting cost types:", e);
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    setAdding(false);
    setArchiving(false);
    setSearchParams({ [COST_TYPE_SEARCH_PARAM_KEY]: undefined });
  };

  const handleModalSubmit = () => {
    setAdding(false);
    setArchiving(false);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add cost type",
        className: "button-2 no-margin",
        action: () => setAdding(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:cost_types:manage"),
      },
    ],
    [can]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiving(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:cost_types:manage"),
      },
    ],
    [can]
  );

  return (
    <div>
      {adding && <CostTypeModal onHide={handleModalClose} onFinish={handleModalSubmit} />}
      {archiving && (
        <ConfirmModal
          loading={loading}
          yesText="Delete"
          onYes={handleArchive}
          noText="Cancel"
          onNo={() => setArchiving(false)}
          title={"Delete cost type" + (selectedRows.length > 1 ? "s" : "")}
          body={
            "Are you sure you want to delete the selected cost type" +
            (selectedRows.length > 1 ? "s" : "") +
            "? Deleting the cost type will remove it from all team members. To restore a deleted cost type, you must contact Miter Support."
          }
          yellowBodyText
        />
      )}
      {selectedCostType && (
        <CostTypeModal
          onHide={handleModalClose}
          onFinish={handleModalSubmit}
          costTypeId={selectedCostType._id}
        />
      )}
      <TableV2
        id={"cost-types-table"}
        resource="cost types"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={(d) => setSearchParams({ [COST_TYPE_SEARCH_PARAM_KEY]: d._id })}
        showReportViews={true}
      />
    </div>
  );
};

const columns: ColumnConfig<CostType>[] = [
  {
    headerName: "Name",
    field: "label",
    dataType: "string",
  },
  {
    headerName: "ID",
    field: "code",
    dataType: "string",
  },
  {
    headerName: "Available on timesheets?",
    field: "scopes",
    dataType: "boolean",
    valueGetter: (row) => row.data?.scopes.includes("timesheets"),
  },
  {
    headerName: "Available in spend management?",
    field: "scopes",
    dataType: "boolean",
    valueGetter: (row) => row.data?.scopes.includes("expense_management"),
  },
];
