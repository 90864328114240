import { useSuperAdmins } from "dashboard/hooks/useSuperAdmins";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Option } from "packages/ui/form/Input";
import { Formblock, SettingsCard } from "ui";
import { Notifier } from "dashboard/utils";
import { useDebouncedCallback } from "use-debounce/lib";
import { IntegrationConnection, MiterIntegrationForCompany } from "dashboard/miter";
import { IntegrationNotificationSettings } from "backend/models/integrations/integration-connection";

type Props = {
  integration: MiterIntegrationForCompany;
  updateIntegrationConnection: (
    update: Partial<IntegrationConnection> | Record<string, $TSFixMe>
  ) => Promise<void>;
};

const IntegrationNotifications: React.FC<Props> = ({ integration, updateIntegrationConnection }) => {
  const [notificationSettings, setNotificationSettings] = useState<
    IntegrationNotificationSettings | undefined
  >(integration?.connection?.notification_settings);
  const form = useForm();
  const { adminOptions, adminOptionsLoading } = useSuperAdmins();

  const updateIntegration = async () => {
    try {
      await updateIntegrationConnection({ notification_settings: notificationSettings });
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error updating team settings. Our engineers are looking into it!");
    }
  };

  useEffect(() => {
    setNotificationSettings(integration?.connection?.notification_settings);
  }, [integration, adminOptions]);

  const updateIntegrationDebounced = useDebouncedCallback(() => {
    updateIntegration();
  }, 1000);

  const handle = (settings: IntegrationNotificationSettings) => {
    setNotificationSettings(settings);

    updateIntegrationDebounced();
  };

  return (
    <>
      <SettingsCard
        title="Failed Daily Sync"
        subtitle="Configure who should be notified when a failure occurs while syncing to/from your integration"
      >
        <div style={{ width: "75%" }}>
          {!adminOptionsLoading && (
            <Formblock
              label="Admins to notify"
              options={adminOptions}
              type="multiselect"
              name="users_to_notify_on_daily_sync_failure"
              form={form}
              className="modal wizard"
              editing={true}
              height="unset"
              style={{ marginTop: 10 }}
              onChange={(e) => {
                handle({
                  on_failure: {
                    users_to_notify: e?.map((item: Option<string>) => item.value) || [],
                  },
                });
              }}
              defaultValue={notificationSettings?.on_failure.users_to_notify || []}
            />
          )}
        </div>
      </SettingsCard>
    </>
  );
};

export default IntegrationNotifications;
