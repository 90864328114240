import { IntegrationEntity, MiterIntegrationKey } from "backend/models/integrations/integrations";
import { SuiteAppOptions } from "backend/services/netsuite/types/netsuite-types";

export const miterGuidesIntegrationPaths: Record<
  Exclude<MiterIntegrationKey, "vista" | "wbs" | "astrada" | "assignar" | "hcss">,
  string
> = {
  // Accounting
  foundation: "integrations/accounting/foundation",
  jonas: "integrations/accounting/jonas-premier",
  qbd: "integrations/accounting/quickbooks-desktop",
  qbo: "integrations/accounting/quickbooks-online",
  sage_300: "integrations/accounting/sage-300",
  sage_intacct: "integrations/accounting/sage-intacct",
  xero: "integrations/accounting/xero",
  acumatica: "integrations/accounting/acumatica/establishing-the-connection",
  sage_100: "integrations/accounting/sage-300-1",
  netsuite: "integrations/accounting/netsuite",

  // Benefits
  contractors_plan: "integrations/benefits/the-contractors-plan",
  guideline: "integrations/benefits/guideline",
  employee_navigator: "integrations/benefits/employee-navigator",
  payroll_integrations: "integrations/benefits/payroll-integrations",

  // Project management
  procore: "integrations/project-management/procore",
  buildops: "integrations/accounting/buildops",

  // HR
  checkr: "integrations/hr/checkr",
  bamboo: "integrations/hr/bamboo-hr",
};

export const NETSUITE_ENABLED_SYNCS_BY_SUITEAPP: Record<
  SuiteAppOptions,
  Partial<Record<IntegrationEntity, { pull?: { enabled: boolean }; push?: { enabled: boolean } }>>
> = {
  none: {
    jobs: {
      pull: {
        enabled: true,
      },
    },
    activities: {
      pull: {
        enabled: true,
      },
    },
    cost_types: {
      pull: {
        enabled: true,
      },
    },
    ledger_accounts: {
      pull: {
        enabled: true,
      },
    },
    ledger_entries: {
      push: {
        enabled: true,
      },
    },
    departments: {
      pull: {
        enabled: true,
      },
    },
    locations: {
      pull: {
        enabled: true,
      },
    },
    team_members: {
      push: {
        enabled: true,
      },
    },
    timesheets: {
      push: {
        enabled: true,
      },
    },
  },
  rabb_it: {
    jobs: {
      pull: {
        enabled: true,
      },
    },
    activities: {
      pull: {
        enabled: true,
      },
    },
    cost_types: {
      pull: {
        enabled: true,
      },
    },
    ledger_accounts: {
      pull: {
        enabled: true,
      },
    },
    ledger_entries: {
      push: {
        enabled: true,
      },
    },
    departments: {
      pull: {
        enabled: true,
      },
    },
    locations: {
      pull: {
        enabled: true,
      },
    },
    team_members: {
      push: {
        enabled: true,
      },
    },
  },
  appficiency: {
    jobs: {
      pull: {
        enabled: true,
      },
    },
    activities: {
      pull: {
        enabled: true,
      },
    },
    cost_types: {
      pull: {
        enabled: true,
      },
    },
    ledger_accounts: {
      pull: {
        enabled: true,
      },
    },
    ledger_entries: {
      push: {
        enabled: true,
      },
    },
    departments: {
      pull: {
        enabled: true,
      },
    },
    locations: {
      pull: {
        enabled: true,
      },
    },
    team_members: {
      push: {
        enabled: true,
      },
    },
    timesheets: {
      push: {
        enabled: true,
      },
    },
  },
  fsm: {
    jobs: {
      pull: {
        enabled: true,
      },
    },
    activities: {
      pull: {
        enabled: true,
      },
    },
    cost_types: {
      pull: {
        enabled: false,
      },
    },
    ledger_accounts: {
      pull: {
        enabled: true,
      },
    },
    ledger_entries: {
      push: {
        enabled: true,
      },
    },
    departments: {
      pull: {
        enabled: true,
      },
    },
    locations: {
      pull: {
        enabled: true,
      },
    },
    team_members: {
      push: {
        enabled: true,
      },
    },
    timesheets: {
      push: {
        enabled: false,
      },
      pull: {
        enabled: true,
      },
    },
  },
  blue_collar: {
    jobs: {
      pull: {
        enabled: true,
      },
    },
    activities: {
      pull: {
        enabled: true,
      },
    },
    cost_types: {
      pull: {
        enabled: false,
      },
    },
    ledger_accounts: {
      pull: {
        enabled: true,
      },
    },
    ledger_entries: {
      push: {
        enabled: true,
      },
    },
    departments: {
      pull: {
        enabled: true,
      },
    },
    locations: {
      pull: {
        enabled: true,
      },
    },
    team_members: {
      push: {
        enabled: true,
      },
    },
    timesheets: {
      push: {
        enabled: false,
      },
    },
  },
};
