import React from "react";
import "ui/table/Table.css";
import styles from "./Expenses.module.css";
import emptyCards from "dashboard/assets/empty-cards.svg";

type ExpenseProps = {
  headline: string;
  description: string;
  ctaButton: React.ReactNode;
};

const ExpenseCTA = ({ headline, description, ctaButton }: ExpenseProps): React.ReactElement => {
  return (
    <div>
      <div className="vertical-spacer-small" />
      <div className={`table-empty-state-wrapper ${styles.expense_table}`}>
        <img className={styles.height_40vh} src={emptyCards} />
        <div className={`table-empty-state-header ${styles.margin_top_32}`}>{headline}</div>
        <div className={styles.expense_content_wrapper}>{description}</div>
        <div className={styles.expense_content_wrapper}>{ctaButton}</div>
        <div className={styles.margin_top_32}>
          Have questions? Check out our{" "}
          <a
            target="_blank"
            rel="noreferrer"
            className={styles.expense_link}
            href="https://guides.miter.com/miter-guides/spend-management/card-transactions"
          >
            Knowledge Base{" "}
          </a>
          or email us at{" "}
          <a target="_blank" rel="noreferrer" href="mailto:sales@miter.com" className={styles.expense_link}>
            sales@miter.com
          </a>
        </div>
      </div>
    </div>
  );
};
export default ExpenseCTA;
