import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { DeepPartial } from "utility-types";
import { useDebouncedCallback } from "use-debounce";
import { IntegrationConnection } from "backend/models";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { NetSuiteConnectionMetadata, SuiteAppOptions } from "backend/services/netsuite/types/netsuite-types";
import { useGetSubsidiaries } from "dashboard/hooks/integrations/netsuite/useGetSubsidiaries";
import { useGetJobStatuses } from "dashboard/hooks/integrations/netsuite/useGetJobStatuses";
import { SettingsCard, Formblock, Loader, Notifier } from "ui";
import { baseSensitiveCompare } from "miter-utils";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { Option } from "ui/form/Input";
import {
  NETSUITE_INPUT_LENGTH,
  DEFAULT_NETSUITE_LABEL_STYLE,
  SUITEAPP_CONFIGURATION_MAP,
  suiteAppOptions,
  codeCodeMappingOptions,
  suiteAppsJobStatuesConfigurable,
} from "./constants";

export const NetSuiteConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();

  const { loading, subsidiaries } = useGetSubsidiaries(integration);
  const { loading: loadingJobStatuses, jobStatuses } = useGetJobStatuses(integration);
  const {
    defaultSubsidiary,
    suiteApp,
    queryTypes,
    jobConfiguration,
    ledgerEntryConfiguration,
    maxConcurrentAPIRequests,
  } = integration.connection?.metadata?.netsuite || {};

  const updateSubsidiary = async (selectedId: string | undefined) => {
    const subsidiary = subsidiaries.find((subsidiary) => subsidiary.id === selectedId);

    await updateNetSuiteMetadata({ defaultSubsidiary: subsidiary });
  };

  const updateNetSuiteMetadata = async (
    update: DeepPartial<NetSuiteConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => {
    const raw: DeepPartial<IntegrationConnection> = { metadata: { netsuite: update } };
    const collapseCount = opts?.collapseCount;
    const flattened = buildAtomicMongoUpdateFromNested(raw, {
      collapseCount: collapseCount != null ? collapseCount + 2 : undefined,
    });

    await updateIntegrationConnection(flattened);
  };

  const setSuiteAppOption = async (value?: SuiteAppOptions): Promise<void> => {
    const configuration = value ? SUITEAPP_CONFIGURATION_MAP[value] : {};

    await updateNetSuiteMetadata({ queryTypes: configuration, suiteApp: value });
  };

  const setQueryTypes = async (fieldName: string, fieldValue?: string): Promise<void> => {
    await updateNetSuiteMetadata({ queryTypes: { ...queryTypes, [`${fieldName}.recordName`]: fieldValue } });
  };

  const subsidiaryOptions = useMemo(() => {
    return subsidiaries
      .map((subsidiary) => ({ label: subsidiary.fullname, value: subsidiary.id }))
      .sort((a, b) => baseSensitiveCompare(a.label, b.label));
  }, [subsidiaries]);

  const jobStatusOptions = useMemo(() => {
    return jobStatuses
      .map((job) => ({ label: job.name, value: job.id }))
      .sort((a, b) => baseSensitiveCompare(a.label, b.label));
  }, [jobStatuses]);

  const updateApiLimits = async (text: string) => {
    const num = Number(text || undefined);
    if (Number.isNaN(num) || Math.round(num) !== num || num <= 0) {
      Notifier.error("Please input a positive integer");
      return;
    }
    await updateNetSuiteMetadata({ maxConcurrentAPIRequests: num });
  };

  const debouncedUpdateApiLimits = useDebouncedCallback(updateApiLimits, 1000);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Default">
        {loading ? (
          <Loader />
        ) : (
          <Formblock
            form={form}
            underlineTooltip={true}
            inputProps={{ style: { width: NETSUITE_INPUT_LENGTH } }}
            name="defaultSubsidiary"
            type="select"
            labelInfo="The subsidiary you are using on NetSuite for this Miter company."
            label="Subsidiary"
            options={subsidiaryOptions}
            defaultValue={defaultSubsidiary?.id}
            onChange={(o) => updateSubsidiary(o.value)}
            labelStyle={DEFAULT_NETSUITE_LABEL_STYLE}
            editing={true}
          />
        )}
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: NETSUITE_INPUT_LENGTH } }}
          name="suiteAppSelection"
          type="select"
          labelInfo="The Suite App you are using on NetSuite (if any)."
          label="SuiteApp"
          isClearable
          options={suiteAppOptions}
          defaultValue={suiteApp || "none"}
          onChange={(o) => setSuiteAppOption(o?.value || null)}
          labelStyle={DEFAULT_NETSUITE_LABEL_STYLE}
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: NETSUITE_INPUT_LENGTH } }}
          name="maxConcurrentAPIRequests"
          type="number"
          min={1}
          step={1}
          labelInfo="The maximum number of concurrent API requests your NetSuite instance supports."
          label="Max Concurrent Requests"
          defaultValue={maxConcurrentAPIRequests}
          onChange={(e) => debouncedUpdateApiLimits(e.target.value)}
          labelStyle={DEFAULT_NETSUITE_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      {/*
       * none (direct) and fsm are both use direct integrations
       * with NetSuite these are the only two we want to
       * allow configurability of cost codes on for now
       */}
      {(suiteApp === "none" || suiteApp === "fsm") && (
        <SettingsCard title="Mapping Configuration">
          <Formblock
            form={form}
            underlineTooltip={true}
            inputProps={{ style: { width: NETSUITE_INPUT_LENGTH } }}
            name="costCode"
            type="select"
            labelInfo="Specify what NetSuite record you would like to map to cost codes in Miter."
            label="Cost Code"
            isClearable
            options={codeCodeMappingOptions}
            defaultValue={queryTypes?.costCode?.recordName || "projectTask"}
            onChange={(o) => setQueryTypes("costCode", o?.value || null)}
            labelStyle={DEFAULT_NETSUITE_LABEL_STYLE}
            editing={true}
          />
        </SettingsCard>
      )}

      <SettingsCard title="Journal Entries">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { display: "flex", flexDirection: "column", alignItems: "start" } }}
          name=""
          type="checkbox"
          labelInfo="When syncing journal entries, exclude card transactions related journal entries."
          label="Exclude Card Transaction Journal Entries"
          defaultValue={ledgerEntryConfiguration?.excludeCardTransactionEntries}
          onChange={(e) =>
            updateNetSuiteMetadata({
              ledgerEntryConfiguration: { excludeCardTransactionEntries: e.target.checked },
            })
          }
          labelStyle={DEFAULT_NETSUITE_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>

      {suiteAppsJobStatuesConfigurable.includes(suiteApp || "none") && (
        <SettingsCard title="Jobs">
          {loadingJobStatuses ? (
            <Loader />
          ) : (
            <Formblock
              form={form}
              options={jobStatusOptions}
              name="activeJobStatusValues"
              type="multiselect"
              defaultValue={
                jobConfiguration?.activeJobStatuses ||
                jobStatusOptions.map((jobStatusOption) => jobStatusOption.value)
              }
              onChange={(options: Option<string>[] | null) => {
                const statuses = options?.map((o) => o.value) ?? [];
                updateNetSuiteMetadata({ jobConfiguration: { activeJobStatuses: statuses } });
              }}
              label="Active Job Statuses"
              editing={true}
              labelInfo="NetSuite Job statuses Miter will consider to be 'Active' when syncing jobs"
              underlineTooltip
              isClearable
              inputProps={{ style: DEFAULT_NETSUITE_LABEL_STYLE }}
              height="unset"
            />
          )}
        </SettingsCard>
      )}
    </div>
  );
};
