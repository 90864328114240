import { useEffect, useState } from "react";
import { NetSuiteJobStatus } from "backend/services/netsuite/types/netsuite-types";
import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "ui";

type GetJobStatusesHook = {
  loading: boolean;
  jobStatuses: NetSuiteJobStatus[];
};

export const useGetJobStatuses = (integration: MiterIntegrationForCompany): GetJobStatusesHook => {
  const [loading, setLoading] = useState(false);
  const [jobStatuses, setJobStatuses] = useState<NetSuiteJobStatus[]>([]);

  const getJobStatuses = async () => {
    if (!integration.connection?._id) {
      Notifier.error(`There was a problem getting the setup link for the integration.`);
      return;
    }
    setLoading(true);

    try {
      const response = await MiterAPI.integrations.netsuite.get_job_statuses(integration.connection._id);
      if (response.error) throw new Error(response.error);
      setJobStatuses(response.statuses);
    } catch (e) {
      console.error(e);
      Notifier.error(`There was a problem getting the setup link for the integration.`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getJobStatuses();

    // eslint suggests adding getJobStatuses to dependencies,
    // but we should only run this useEffect once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    jobStatuses,
  };
};
