import { AllocationRuleGroup } from "backend/models";
import {
  useDepartmentOptions,
  useDepartmentNameFormatter,
  useClassOptions,
  useClassNameFormatter,
  useLocationOptions,
  useLocationNameFormatter,
} from "dashboard/hooks/atom-hooks";
import { useOptionalDimensionsUsage } from "dashboard/hooks/dimensions-gating";
import { AllocationRule } from "dashboard/miter";
import { Option } from "packages/ui/form/Input";
import { useMemo } from "react";

export type AllocationRuleDimensions = {
  field: keyof AllocationRule["dimensions"];
  label: string;
  options: Option<string>[];
  labelFormatter: (value: string | undefined) => string;
}[];

export const useAllocationRuleDimensions = (type: AllocationRuleGroup["type"]): AllocationRuleDimensions => {
  const departmentOptions = useDepartmentOptions();
  const departmentNameFormtter = useDepartmentNameFormatter();

  const classOptions = useClassOptions();
  const classNameFormatter = useClassNameFormatter();

  const locationOptions = useLocationOptions();
  const locationNameFormatter = useLocationNameFormatter();

  const { hasClasses, hasDepartments, hasLocations } = useOptionalDimensionsUsage();

  return useMemo(() => {
    switch (type) {
      case "salary":
        const dimensions: AllocationRuleDimensions = [];
        if (hasDepartments) {
          dimensions.push({
            field: "department_id",
            label: "Department",
            options: departmentOptions,
            labelFormatter: departmentNameFormtter,
          });
        }

        if (hasClasses) {
          dimensions.push({
            field: "class_id",
            label: "Class",
            options: classOptions,
            labelFormatter: classNameFormatter,
          });
        }

        if (hasLocations) {
          dimensions.push({
            field: "location_id",
            label: "Location",
            options: locationOptions,
            labelFormatter: locationNameFormatter,
          });
        }

        return dimensions;
    }
  }, [
    type,
    hasDepartments,
    departmentOptions,
    departmentNameFormtter,
    hasClasses,
    classOptions,
    classNameFormatter,
    hasLocations,
    locationOptions,
    locationNameFormatter,
  ]);
};
