import React, { useContext, useState } from "react";

import { Notifier, SliderModal } from "ui";
import {
  ExpenseReimbursementModalForm,
  ExpenseReimbursementModalFormData,
} from "./ExpenseReimbursementModalForm";
import { useForm } from "react-hook-form";
import { FileUploadParams, updateFiles } from "miter-utils";
import {
  cleanExpenseReimbursementFormData,
  useDefaultReimbursementPayoutMethodOption,
  useValidateTeamMemberHasReimbursementPayoutMethod,
} from "../expenseUtils";
import {
  useActiveCompany,
  useActiveTeamMember,
  useDefaultExpenseReimbursementCategory,
  useIsSuperAdmin,
  useLookupDepartment,
  useLookupExpenseReimbursementCategories,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import { CreateExpenseReimbursementModalFooter } from "./CreateExpenseReimbursementModalFooter";
import { DateTime } from "luxon";
import { MiterAPI } from "dashboard/miter";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { PARAGON_COMPANY_ID } from "dashboard/utils";

type Props = {
  onHide: () => void; // hide modal
  onSubmit: (tmId: string) => void;
  teamMemberIdFromPayroll?: string; // if this is set, the modal is opened from the reimbursements tab in payroll.
};

const CreateExpenseReimbursementModal: React.FC<Props> = ({ onHide, onSubmit, teamMemberIdFromPayroll }) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const activeCompany = useActiveCompany();
  const activeTeamMember = useActiveTeamMember();
  const defaultTeamMemberId = teamMemberIdFromPayroll ?? activeTeamMember?._id;

  const isSuperAdmin = useIsSuperAdmin();
  const { payroll } = useContext(PayrollContext);
  const reimbursementSettings = activeCompany?.settings.expense_reimbursements;
  const lookupReimbursementCategory = useLookupExpenseReimbursementCategories();
  const defaultCategory = useDefaultExpenseReimbursementCategory();
  const lookupDepartment = useLookupDepartment();
  const lookupTeamMember = useLookupTeam();
  const defaultPayoutMethod = useDefaultReimbursementPayoutMethodOption(defaultTeamMemberId);
  const validateTeamMemberHasPayoutMethod = useValidateTeamMemberHasReimbursementPayoutMethod();

  const aggregatedDefaultTeamMember = lookupTeamMember(defaultTeamMemberId);

  const form = useForm<ExpenseReimbursementModalFormData>({
    defaultValues: {
      date: DateTime.min(DateTime.fromISO(payroll?.check_payroll.period_end ?? ""), DateTime.now()),
      team_member_id: aggregatedDefaultTeamMember
        ? {
            label: aggregatedDefaultTeamMember?.full_name,
            value: aggregatedDefaultTeamMember?._id,
          }
        : undefined, // defaults to currently logged in team member
      is_taxable: {
        label: "Not Taxable",
        value: false,
      },
      payout_method: defaultPayoutMethod,
      department_id: aggregatedDefaultTeamMember?.department_id
        ? {
            label: lookupDepartment(aggregatedDefaultTeamMember.department_id)?.name,
            value: aggregatedDefaultTeamMember.department_id,
          }
        : undefined,
      expense_reimbursement_category_id: defaultCategory
        ? {
            label: defaultCategory.name,
            value: defaultCategory._id,
          }
        : undefined,
      attachments: [],
    },
    shouldUnregister: false,
  });

  const { handleSubmit } = form;
  /*********************************************************
   *  Initialize state
   **********************************************************/
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createExpenseReimbursementRequest = async (data: ExpenseReimbursementModalFormData) => {
    if (!activeCompany) return;

    setIsLoading(true);
    try {
      // validate payout method
      if (validateTeamMemberHasPayoutMethod(data.team_member_id.value, data.payout_method?.value) === false) {
        if (data.payout_method?.value === "payroll") {
          throw new Error("This team member is not enrolled in payroll.");
        } else if (data.payout_method?.value === "ach") {
          throw new Error(
            "Your company has not set up direct ACH reimbursements yet. Contact Miter Support."
          );
        }
      }

      let statusToCreate: "unapproved" | "approved" =
        (isSuperAdmin && !reimbursementSettings?.default_unapproved_from_dashboard) ||
        teamMemberIdFromPayroll != null
          ? "approved"
          : "unapproved";

      // paragon wants all reimbursements to be unapproved by default, even if from payroll
      if (activeCompany._id === PARAGON_COMPANY_ID) {
        statusToCreate = "unapproved";
      }

      const cleanData = cleanExpenseReimbursementFormData({
        formData: data,
        activeCompany,
        mileageDetail: data.mileage
          ? {
              mileage: data.mileage,
              trip: data.trip,
            }
          : undefined,
        selectedCategory: lookupReimbursementCategory(data.expense_reimbursement_category_id?.value),
        statusToCreate,

        isCreation: true,
        creationMethod: "dashboard_individual",
      });

      const res = await MiterAPI.expense_reimbursements.create(cleanData);
      if (res.error) {
        throw new Error(res.error);
      }

      const params: FileUploadParams = {
        parent_id: res.expense_reimbursement._id,
        parent_type: "expensereimbursement",
        company_id: activeCompany._id,
      };

      await updateFiles(data.attachments, params);
      onSubmit(res.expense_reimbursement.team_member_id);
      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setIsLoading(false);
  };

  const title = "Create new reimbursement";
  return (
    <SliderModal
      title={title}
      onCancel={onHide}
      animate={true}
      shouldOnclickAwayClose={true}
      footer={
        <CreateExpenseReimbursementModalFooter
          onSubmit={async () => {
            await handleSubmit(createExpenseReimbursementRequest)();
          }}
        />
      }
      loading={isLoading}
    >
      <ExpenseReimbursementModalForm editingMode={"all_fields"} form={form} isCreating={true} />
    </SliderModal>
  );
};

export default CreateExpenseReimbursementModal;
