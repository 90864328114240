import { useMessageTemplateOptions, useLookupMessageTemplate, useUser } from "dashboard/hooks/atom-hooks";
import React, { FC, useEffect, useRef, useState } from "react";
import { ActionModal, Formblock, Notifier } from "ui";
import { useForm } from "react-hook-form";
import styles from "./RecruitingModals.module.css";
import { useNavigate } from "react-router-dom";

import {
  convertToTrimmedPlainText,
  hydrateRecruitingMessageTemplate,
  messageTemplateSmartFields,
} from "dashboard/utils/message-templates";
import { AggregatedJobApplication } from "dashboard/types/ats";
import { SMSTemplateEditor } from "dashboard/components/message-templates/SMSTemplateEditor";
import Banner from "dashboard/components/shared/Banner";
import {
  CandidateMessageInput,
  useRecruitingChatUtilities,
} from "dashboard/hooks/chat/useRecruitingChatUtilities";
import { FailureItem } from "dashboard/components/shared/FailuresModal";
import { generateCandidatePrefix } from "dashboard/utils/ats";

type Props = {
  jobApplications: AggregatedJobApplication[];
  onClose: () => void;
  setFailures: (failures: FailureItem[]) => void;
};

const BulkSendMessagesModal: FC<Props> = ({ onClose, jobApplications, setFailures }) => {
  const messageTemplateOptions = useMessageTemplateOptions();
  const form = useForm();
  const { watch } = form;
  const templateOption = watch("template");
  const lookupTemplate = useLookupMessageTemplate();
  const template = lookupTemplate(templateOption?.value);
  const navigate = useNavigate();
  const editorRef = useRef();
  const user = useUser();
  const { bulkSendRecruitingMessages } = useRecruitingChatUtilities();
  const [loading, setLoading] = useState(false);

  const renderNoMessageTemplatesBanner = () => {
    if (messageTemplateOptions.length !== 0) return null;
    return (
      <Banner
        type="warning"
        onClick={() => {
          navigate("/recruiting-settings");
        }}
        className={styles["render-no-message-templates-banner"]}
      >
        It looks like you don&apos;t have any message templates yet. Click here to create one.
      </Banner>
    );
  };

  const renderJobApplicationsBanner = () => {
    const candidateText = generateCandidatePrefix(jobApplications.map((ja) => ja.candidate));
    return (
      <Banner
        type="info"
        className={styles["render-candidates-banner"]}
        content={`You are about to message ${candidateText} candidates.`}
      />
    );
  };

  const onSubmit = async () => {
    setLoading(true);
    if (!user) {
      Notifier.error("Could not find your user profile. Please log out and log back in.");
      return;
    }
    const conversations: CandidateMessageInput[] = [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TinyMCE doesn't have proper typing for ref
    const text = editorRef.current?.getContent();
    const trimmedText = convertToTrimmedPlainText(text);

    if (!trimmedText) {
      Notifier.warning("Please enter a message to send.");
      setLoading(false);
      return;
    }

    jobApplications.forEach((jobApplication) => {
      const hydratedText = hydrateRecruitingMessageTemplate({
        text,
        template,
        candidate: jobApplication.candidate,
        jobPosting: jobApplication.job_posting,
        sender: user,
      });
      conversations.push({
        candidate: jobApplication.candidate,
        message: hydratedText,
      });
    });

    const failures = await bulkSendRecruitingMessages({ conversations });

    if (failures.length) {
      Notifier.error(`Failed message ${failures.length} candidates.`);
    } else {
      Notifier.success("Successfully messaged candidates!");
    }

    setFailures(failures);
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (template) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TinyMCE doesn't have proper typing for ref
      editorRef.current?.setContent(template.raw_html);
    }
  }, [template]);

  return (
    <ActionModal
      headerText={`Bulk message ${jobApplications.length} candidates`}
      showSubmit={true}
      submitText="Send SMS"
      showCancel={true}
      cancelText="Cancel"
      onCancel={onClose}
      onHide={onClose}
      onSubmit={onSubmit}
      wrapperClassName={styles["bulk-send-messages-modal-wrapper"]}
      bodyClassName={styles["bulk-send-messages-modal-body"]}
      loading={loading}
    >
      {renderNoMessageTemplatesBanner()}
      {renderJobApplicationsBanner()}

      <Formblock
        type="select"
        label="Select a message template"
        form={form}
        name="template"
        options={messageTemplateOptions}
        className="modal"
        editing={true}
        disabled={loading}
      />

      <SMSTemplateEditor
        defaultHTML={template?.raw_html}
        smartFields={messageTemplateSmartFields}
        editorRef={editorRef}
        toolbar={""}
        height={300}
        disabled={loading}
      />
    </ActionModal>
  );
};

export default BulkSendMessagesModal;
