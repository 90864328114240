import { useActiveAccount, useActiveCompany, useActiveCompanyId, useIsMiterAdmin } from "./hooks/atom-hooks";
import {
  ALIVE_SOLAR_ROOFING_COMPANY_ID,
  AILEY_SOLAR_ELECTRIC_COMPANY_ID,
  ANCHOR_DESIGN_BUILD_GROUP_COMPANY_ID,
  BECKETT_ELECTRICAL_COMPANY_ID,
  BILBRO_COMPANY_ID,
  CANNING_ELECTRIC_COMPANY_ID,
  CARRIERE_STUMM_COMPANY_ID,
  CVE_DEMO_COMPANY_ID,
  CVE_FRESNO_COMPANY_ID,
  CVE_NORTH_BAY_COMPANY_ID,
  DAN_GRADY_COMPANY_ID,
  DEMO_COMPANY_ID,
  DE_LUCIA_COMPANY_ID,
  EN_TEST_COMPANY,
  FACILITY_SITE_COMPANY_ID,
  FOSTER_COMPANY_ID,
  GLACIER_CONSTRUCTION_COMPANY_ID,
  GREAT_FALLS_CONSTRUCTION_COMPANY_ID,
  HAYS_ELECTRICAL_COMPANY_ID,
  HILLHOUSE_COMPANY_ID,
  INDUSTRIAL_STEEL_COMPANY_ID,
  INTEGRAL_BLUE_COMPANY_ID,
  JGB_AGGREGATES_COMPANY_IDS,
  LOGAN_HEATING_COOLING_COMPANY_ID,
  MARATHON_ELECTRICAL_ID,
  MAROIS_COMPANY_ID,
  MUSE_CONCRETE_COMPANY_ID,
  NORTH_SLOPE_COMPANY_ID,
  ONYX_COMPANY_ID,
  PARAGON_COMPANY_ID,
  PERIMETER_SECURITY_PARTNERS_COMPANY_ID,
  PREMO_ROOFING_ID,
  PRIMARY_UTILITY_COMPANY_ID,
  QOVO_COMPANY_ID,
  SERENITY_COMPANY_ID,
  SOUTHERN_SPEAR_COMPANY_IDS,
  SPENCER_COMPANY_ID,
  STAHL_COMPANY_IDS,
  STILES_COMPANY_ID,
  SUNSTALL_COMPANY_ID,
  TECHNICAL_SYSTEMS_COMPANY_ID,
  THREE_BROTHERS_CONCRETE_COMPANY_ID,
  TRUEBECK_COMPANY_ID,
  UNITED_CONSTRUCTION_COMPANY_ID,
  UNITED_MECHANICAL_COMPANY_ID,
  WHITAKER_ELLIS_COMPANY_ID,
  MASS_PAVEMENT_COMPANY_ID,
  HB_NEILD_COMPANY_ID,
  ENVIRONMENTAL_SYSTEMS_COMPANY_ID,
  TWOH_MECHANICAL_LLC,
  KENT_POWER_COMPANY_IDS,
  MAXIM_CONCRETE_ID,
  HARRINGTON_CONSTRUCTION_COMPANY_ID,
  CORE_ROOFING_ID,
  PEERLESS_MIDWEST_LLC_ID,
  GOODWIN_WELL_WATER_COMPANY_ID,
  AMERICAN_HEATING_ID,
  JANDJ_COMPANY_ID,
  ZONE_STRIPING_COMPANY_ID,
} from "./utils";
import { isClaspCompany } from "./utils/clasp-utils";
import {
  ALLAN_BRITEWAY_COMPANY_IDS,
  AMERICAN_ROOFING_METAL_COMPANY_IDS,
  BOUTEN_COMPANY_ID,
  COASTAL_COMPANY_IDS,
  GREEN_MECHANICAL_COMPANY_ID,
  KW_CONCRETE,
  MARCON_COMPANY_ID,
  RLC_BRIX_COMPANY_IDS,
  UNITED_PAINTING_COMPANY_IDS,
} from "./utils/constants";
import { IS_DEVELOPMENT, IS_PRODUCTION, IS_STAGING } from "./utils/environment";

export const useHasAccessToChecklists = (): boolean => {
  const accessibleCompanies = [
    HAYS_ELECTRICAL_COMPANY_ID,
    ONYX_COMPANY_ID,
    CVE_DEMO_COMPANY_ID,
    CVE_FRESNO_COMPANY_ID,
    CVE_NORTH_BAY_COMPANY_ID,
    BECKETT_ELECTRICAL_COMPANY_ID,
    UNITED_CONSTRUCTION_COMPANY_ID,
  ];
  const activeCompanyId = useActiveCompanyId();
  return !!activeCompanyId && accessibleCompanies.includes(activeCompanyId);
};

export const useIsAddedToAutoBreakTimeInTimesheetPolicies = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  const eligibleCompanies = [
    ...ALLAN_BRITEWAY_COMPANY_IDS,
    HILLHOUSE_COMPANY_ID,
    ...UNITED_PAINTING_COMPANY_IDS,
  ];

  if (!IS_PRODUCTION) return true;
  if (activeCompanyId && eligibleCompanies.includes(activeCompanyId)) return true;
  return false;
};

// if the company is in the list, they have access to card management no matter what - skips the card lander
export const useHasAccessToCardManagement = (): boolean => {
  const list = [MARCON_COMPANY_ID, BILBRO_COMPANY_ID, TRUEBECK_COMPANY_ID];
  const activeCompanyId = useActiveCompanyId();

  return list.includes(activeCompanyId || "");
};

// Has access to timesheet bulk entry pay rates - don't GA this because it's not a feature we want to advertise
export const useHasAccessToBulkEntryPayRates = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  return IS_DEVELOPMENT || RLC_BRIX_COMPANY_IDS.includes(activeCompanyId || "");
};

export const useHasAccessToMiterPaystubsAndChecks = (): boolean => {
  const company = useActiveCompany();
  return !!company?.settings.payroll.use_miter_paystubs_and_checks;
};

export const useHasCompanyCreationAccess = (): boolean => {
  const isMiterAdmin = useIsMiterAdmin();
  const activeAccount = useActiveAccount();

  // Labor compliance pros are an early customer who does prevailing wage consulting. Let's give them access to company creation for now
  return isMiterAdmin || !!activeAccount?.email?.includes("laborcompliancepros.com");
};

export const useHasAccessToAllowanceOnJobLedgerAccount = (): boolean => {
  const list = [...COASTAL_COMPANY_IDS, STILES_COMPANY_ID];
  const activeCompanyId = useActiveCompanyId();

  return !IS_PRODUCTION || list.includes(activeCompanyId || "");
};

export const useCanEditParentJob = (): boolean => {
  const list = [ANCHOR_DESIGN_BUILD_GROUP_COMPANY_ID];
  const activeCompanyId = useActiveCompanyId();

  return list.includes(activeCompanyId || "");
};

export const useHasAccessToTimeOffMaxUsageLimits = (): boolean => {
  const list = [
    MUSE_CONCRETE_COMPANY_ID,
    FACILITY_SITE_COMPANY_ID,
    WHITAKER_ELLIS_COMPANY_ID,
    ALIVE_SOLAR_ROOFING_COMPANY_ID,
    PERIMETER_SECURITY_PARTNERS_COMPANY_ID,
    CANNING_ELECTRIC_COMPANY_ID,
  ];
  const activeCompanyId = useActiveCompanyId();
  return IS_DEVELOPMENT || list.includes(activeCompanyId || "");
};

export const useHasAccessToBenAdmin = (): boolean => {
  const activeCompany = useActiveCompany();
  if (activeCompany?._id === EN_TEST_COMPANY) return false;
  return !IS_PRODUCTION || isClaspCompany(activeCompany);
};

export const useHasBillingFlagOptionForExpMgmt = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  return !IS_PRODUCTION || activeCompanyId === BOUTEN_COMPANY_ID;
};

export const useHasAccessToSiteBoundClockOut = (): boolean => {
  const activeCompanyId = useActiveCompanyId();

  return !IS_PRODUCTION || activeCompanyId === HAYS_ELECTRICAL_COMPANY_ID;
};

export const useHasAccessToImmediateActionCenterNotifs = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  return IS_DEVELOPMENT || activeCompanyId === TRUEBECK_COMPANY_ID;
};

export const useCanEditTmHsaLimitValue = (employmentType: "employee" | "contractor"): boolean => {
  const activeCompanyId = useActiveCompanyId();
  if (employmentType !== "employee") return false;
  return !IS_PRODUCTION || activeCompanyId === DEMO_COMPANY_ID;
};

export const useCanAssociateBensWithNonTaxableContributions = (): boolean => {
  return IS_DEVELOPMENT;
};

export const useHasAccessToEquipmentTracking = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  if (!activeCompanyId) return false;
  const companyIds = [
    PRIMARY_UTILITY_COMPANY_ID,
    ...JGB_AGGREGATES_COMPANY_IDS,
    INTEGRAL_BLUE_COMPANY_ID,
    DE_LUCIA_COMPANY_ID,
    MAROIS_COMPANY_ID,
    SERENITY_COMPANY_ID,
    GREAT_FALLS_CONSTRUCTION_COMPANY_ID,
    THREE_BROTHERS_CONCRETE_COMPANY_ID,
    PARAGON_COMPANY_ID,
    QOVO_COMPANY_ID,
    SUNSTALL_COMPANY_ID,
    DAN_GRADY_COMPANY_ID,
    ...STAHL_COMPANY_IDS,
    ONYX_COMPANY_ID,
    GOODWIN_WELL_WATER_COMPANY_ID,
    JANDJ_COMPANY_ID,
    ZONE_STRIPING_COMPANY_ID,
  ];
  return IS_DEVELOPMENT || IS_STAGING || companyIds.includes(activeCompanyId);
};

export const useHasAccessToAccessFilters = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  if (!activeCompanyId) return false;
  const companyIds = [
    FACILITY_SITE_COMPANY_ID,
    TECHNICAL_SYSTEMS_COMPANY_ID,
    KW_CONCRETE,
    AILEY_SOLAR_ELECTRIC_COMPANY_ID,
    TWOH_MECHANICAL_LLC,
    MAXIM_CONCRETE_ID,
    PEERLESS_MIDWEST_LLC_ID,
    AMERICAN_HEATING_ID,
  ];
  return IS_DEVELOPMENT || IS_STAGING || companyIds.includes(activeCompanyId);
};

export const useHasAccessToJobAccessFilters = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  if (!activeCompanyId) return false;
  const companyIds = [CORE_ROOFING_ID];
  return IS_DEVELOPMENT || IS_STAGING || companyIds.includes(activeCompanyId);
};

export const useHasAccessToBulkCreateTimesheets2 = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  if (
    [
      UNITED_MECHANICAL_COMPANY_ID,
      FOSTER_COMPANY_ID,
      GLACIER_CONSTRUCTION_COMPANY_ID,
      INTEGRAL_BLUE_COMPANY_ID,
      JANDJ_COMPANY_ID,
    ].includes(activeCompanyId || "")
  )
    return true;
  return IS_DEVELOPMENT || IS_STAGING;
};

export const RECRUITING_BETA_COHORT = [
  SUNSTALL_COMPANY_ID,
  MARATHON_ELECTRICAL_ID,
  PERIMETER_SECURITY_PARTNERS_COMPANY_ID,
  ...AMERICAN_ROOFING_METAL_COMPANY_IDS,
  BECKETT_ELECTRICAL_COMPANY_ID,
  NORTH_SLOPE_COMPANY_ID,
  LOGAN_HEATING_COOLING_COMPANY_ID,
  DEMO_COMPANY_ID,
  PRIMARY_UTILITY_COMPANY_ID,
];

export const useHasAccessToCollectDemographicInfo = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  const productionCompanies = [DEMO_COMPANY_ID, SPENCER_COMPANY_ID];
  return !IS_PRODUCTION || productionCompanies.includes(activeCompanyId || "");
};

export const useHasAccessToSimpleIRAReport = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  return IS_DEVELOPMENT || activeCompanyId === PREMO_ROOFING_ID;
};

export const useHasAccessToBenefitsEligibilityPreviewReport = (): boolean => {
  const activeCompany = useActiveCompany();
  const isActiveOnClasp = isClaspCompany(activeCompany);
  if (!isActiveOnClasp) return false;
  return !IS_PRODUCTION || activeCompany?._id === CARRIERE_STUMM_COMPANY_ID;
};

// GMCI and AR&M are two of the earliest EN customers, and therefore have some
// unique issues. In the future, we should definitely lock this down, but for
// now, allow them to make edits to their benefits with the disclaimer that they
// should make sure to reflect any changes in EN as well.
export const useHasAccessToEmployeeNavigatorOverrides = (): boolean => {
  const isMiterAdmin = useIsMiterAdmin();
  const activeCompanyId = useActiveCompanyId();
  return (
    isMiterAdmin ||
    activeCompanyId === GREEN_MECHANICAL_COMPANY_ID ||
    (!!activeCompanyId && AMERICAN_ROOFING_METAL_COMPANY_IDS.includes(activeCompanyId))
  );
};

export const useHasAccessToBenefitsAdminOverrides = (): boolean => {
  const isMiterAdmin = useIsMiterAdmin();
  const activeCompanyId = useActiveCompanyId();
  return (
    !IS_PRODUCTION ||
    isMiterAdmin ||
    activeCompanyId === HB_NEILD_COMPANY_ID ||
    activeCompanyId === MASS_PAVEMENT_COMPANY_ID ||
    activeCompanyId === ENVIRONMENTAL_SYSTEMS_COMPANY_ID ||
    activeCompanyId === STILES_COMPANY_ID ||
    activeCompanyId === INDUSTRIAL_STEEL_COMPANY_ID ||
    SOUTHERN_SPEAR_COMPANY_IDS.includes(activeCompanyId || "")
  );
};

export const useHasAccessToHCSSIntegration = (): boolean => {
  const accessibleCompanies = [...KENT_POWER_COMPANY_IDS, HARRINGTON_CONSTRUCTION_COMPANY_ID];
  const activeCompanyId = useActiveCompanyId();
  return !IS_PRODUCTION || (!!activeCompanyId && accessibleCompanies.includes(activeCompanyId));
};

export const useHasAccessToCollectWorkExperienceAndEducation = (): boolean => {
  return true;
};

export const useHasAccessToBulkSendMessages = (): boolean => {
  return true;
};

export const useHasAccessToFringeMaximums = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  // TODO add companies to roll out to once the fringe max amounts feature is ready
  const eligibleCompanies: string[] = [];

  if (!IS_PRODUCTION) return true;
  if (activeCompanyId && eligibleCompanies.includes(activeCompanyId)) return true;
  return false;
};

export const useHasAccessToWorkAuthorization = (): boolean => {
  return IS_DEVELOPMENT;
};

export const useHasAccessToPayPeriodListView = (): boolean => {
  const activeCompanyId = useActiveCompanyId();
  const eligibleCompanies = [DEMO_COMPANY_ID, UNITED_MECHANICAL_COMPANY_ID];
  return !IS_PRODUCTION || eligibleCompanies.includes(activeCompanyId || "");
};

export const useHasAccessToAllocationRuleGroups = (): boolean => {
  return !IS_PRODUCTION;
};
