import React, { FC } from "react";
import { JobApplication } from "dashboard/types/ats";
import styles from "./WorkEducationHistory.module.css";
import { EducationalExperience } from "./EducationalExperience";
import { DateTime } from "luxon";

type Props = {
  education: NonNullable<JobApplication["education"]>;
};

export const EducationHistory: FC<Props> = ({ education }) => {
  const sortedEducation = education.sort((a, b) => {
    return DateTime.fromISO(b.start_date).diff(DateTime.fromISO(a.start_date)).toMillis();
  });

  if (sortedEducation.length === 0) {
    return <div className={styles["no-education-provided"]}>No educational background was provided.</div>;
  }

  return (
    <div className={styles["work-history-container"]}>
      {sortedEducation.map((edu) => (
        <EducationalExperience education={edu} />
      ))}
    </div>
  );
};
