import React, { useContext, useState } from "react";
import { Badge, TextWithTooltip } from "ui";
import DataBox from "dashboard/components/dataBox/DataBox";

import { formatDate, truncateTeamMemberFullNameList } from "dashboard/utils/utils";
import JobModalForm from "./forms/JobModalForm";
import { JobAdvancedOptions } from "./forms/JobAdvancedOptions";
import CustomerModal from "dashboard/components/customers/CustomerModal";
import { AggregatedJob, Job } from "dashboard/miter";
import AppContext from "dashboard/contexts/app-context";
import { renderCustomFieldDefaultString } from "miter-utils";
import CustomFieldValueModal from "dashboard/components/custom-fields/CustomFieldValuesModal";
import { JobCprInfo } from "./JobCprInfo";
import {
  useLookupClass,
  useLookupDepartment,
  useLookupJob,
  useLookupLocation,
  usePrgs,
  useRefetchJobs,
  useSubJobsConfig,
} from "dashboard/hooks/atom-hooks";
import { JobIntegrations } from "./JobIntegrations";
import { JobLocation } from "./jobLocation/JobLocation";
import { useMiterAbilities } from "../../hooks/abilities-hooks/useMiterAbilities";
import { JobPolicies } from "./JobPolicies";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";
import { Link } from "react-router-dom";
import { JobScopes } from "./JobScopes";
import { JobPayRateGroups } from "./JobPayRateGroups";
import { useOptionalDimensionsUsage } from "dashboard/hooks/dimensions-gating";
import { JobAccessFilters } from "./JobAccessFilters";

// This component produces the databox where a user can view a job's basic background information
type Props = {
  updateJob: (update: Partial<Job>) => Promise<void>;
  updatingJob: boolean;
  job: AggregatedJob;
  errorUpdatingJob: boolean;
};

const JobDetails: React.FC<Props> = (props) => {
  const { updateJob, updatingJob, job } = props;
  const refetchJobs = useRefetchJobs();
  const prgs = usePrgs();
  const lookupClass = useLookupClass();
  const lookupDepartment = useLookupDepartment();
  const lookupLocation = useLookupLocation();
  const lookupJob = useLookupJob();
  const subJobsConfig = useSubJobsConfig();
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const { can } = useMiterAbilities();
  const { customFields } = useContext(AppContext);

  const jobAbilities = useJobAbilities();
  const { hasDepartments, hasLocations, hasClasses } = useOptionalDimensionsUsage();

  // State
  const [editing, setEditing] = useState(false); // Default job object data
  const [editingCustomFields, setEditingCustomFields] = useState(false);

  const canUpdateJob = jobAbilities.can("update", job);

  const handleEditHide = () => {
    setEditing(false);
  };

  const renderCustomFields = () => {
    const jobCustomFields = customFields.filter((cf) => cf.parent_type === "job");
    const jobCustomFieldValues = job.custom_field_values || [];

    if (!jobCustomFields.length) return <></>;

    return (
      <>
        <DataBox
          title="Custom fields"
          className="flex-col no-margin"
          onEdit={canUpdateJob ? () => setEditingCustomFields(true) : undefined}
        >
          {jobCustomFields.map((cf) => {
            const selectedValue = jobCustomFieldValues.find((cfv) => cfv.custom_field_id === cf._id)!;
            const valueString = renderCustomFieldDefaultString(cf, selectedValue);

            return (
              <div className={"data-box-section"} key={cf.name}>
                <span className={"data-box-section-title font-14"}>{cf.name}</span>
                <span className={"data-box-section-value font-14"}>{valueString}</span>
              </div>
            );
          })}
        </DataBox>
        {editingCustomFields && (
          <CustomFieldValueModal
            onHide={() => setEditingCustomFields(false)}
            parentID={job._id}
            parentType={"job"}
            defaultValues={jobCustomFieldValues}
            onFinish={() => refetchJobs(job._id)}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <div className="vertical-spacer-small"></div>
      <DataBox
        title="Job details"
        className="no-margin"
        onEdit={canUpdateJob ? () => setEditing(true) : undefined}
        editText={undefined}
        rows={undefined}
      >
        {editing && <JobModalForm hide={handleEditHide} jobData={job} updating />}
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Job name</span>
          <span className={"data-box-section-value font-14"}>{job.name}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Job code</span>
          <span className={"data-box-section-value font-14"}>{job.code || "-"}</span>
        </div>
        <div className={"data-box-section job-description"}>
          <span className={"data-box-section-title font-14"}>Description</span>
          <span className={"data-box-section-value font-14"}>{job.description || "-"}</span>
        </div>
        <div
          className={"data-box-section"}
          onClick={
            canUpdateJob && can("lists:customers:manage") ? () => setShowCustomerModal(true) : undefined
          }
        >
          <TextWithTooltip
            id="customer"
            text="Customer"
            spanStyle={{ fontSize: 14 }}
            tooltip="Click on this row to edit the customer's information"
          />
          <span className={"data-box-section-value font-14"}>{job.customer?.name || "-"}</span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Customer phone number</span>
          <span className={"data-box-section-value font-14"}>{job.customer?.phone || "-"}</span>
        </div>
        {subJobsConfig.enabled && job.parent_job_id && (
          <div className={"data-box-section job-description"}>
            <span className={"data-box-section-title font-14"}>Parent job</span>
            <span className={"data-box-section-value font-14"}>
              <Link className="purple-link" to={`/jobs/${job.parent_job_id}`}>
                {lookupJob(job.parent_job_id)?.name}
              </Link>
            </span>
          </div>
        )}
        {hasDepartments && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Department</span>
            <span className={"data-box-section-value font-14"}>
              {lookupDepartment(job.department_id)?.name || "-"}
            </span>
          </div>
        )}
        {hasLocations && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Location</span>
            <span className={"data-box-section-value font-14"}>
              {lookupLocation(job.location_id)?.name || "-"}
            </span>
          </div>
        )}
        {hasClasses && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Class</span>
            <span className={"data-box-section-value font-14"}>{lookupClass(job.class_id)?.name || "-"}</span>
          </div>
        )}
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Job status</span>
          <span className={"data-box-section-value font-14"}>
            <Badge text={job.status} table className="no-margin" />
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Start date</span>
          <span className={"data-box-section-value font-14"}>
            {job.start_date ? formatDate(job.start_date, undefined, true) : "-"}
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Supervisors</span>
          <span className={"data-box-section-value font-14"}>
            {truncateTeamMemberFullNameList(job.supervisors) || "-"}
          </span>
        </div>
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Superintendents</span>
          <span className={"data-box-section-value font-14"}>
            {truncateTeamMemberFullNameList(job.superintendents) || "-"}
          </span>
        </div>
      </DataBox>
      <JobLocation data={job} updatingJob={updatingJob} updateJob={updateJob} />
      <JobCprInfo data={job} updatingJob={updatingJob} updateJob={updateJob} />
      <JobPolicies data={job} updatingJob={updatingJob} updateJob={updateJob} />
      <JobAccessFilters data={job} updatingJob={updatingJob} updateJob={updateJob} />
      <JobScopes data={job} updatingJob={updatingJob} updateJob={updateJob} />
      {!!prgs.length && <JobPayRateGroups data={job} updatingJob={updatingJob} updateJob={updateJob} />}
      <JobAdvancedOptions data={job} updatingJob={updatingJob} updateJob={updateJob} />
      <JobIntegrations
        job={job}
        updatingJob={updatingJob}
        updateJob={updateJob}
        errorUpdatingJob={props.errorUpdatingJob}
      />
      {renderCustomFields()}
      {showCustomerModal && (
        <CustomerModal
          customer_id={job?.customer?._id}
          onHide={() => setShowCustomerModal(false)}
          onFinish={(customer) => {
            job.customer = customer;
            setShowCustomerModal(false);
          }}
        />
      )}
    </div>
  );
};

export default JobDetails;
