import React from "react";
import { ClickAwayListener } from "@material-ui/core";

import { Formblock } from "ui";
import { standardizePhoneFormat } from "dashboard/utils/utils";
import { cleanAddress, cleanDateDropdown } from "dashboard/utils/utils";

import * as vals from "dashboard/utils/validators";

import { ModalHeader } from "ui";
import { ModalFooter } from "ui";
import { AggregatedTeamMember } from "dashboard/miter";
import { UseFormMethods } from "react-hook-form";
import { Notifier } from "dashboard/utils";
import { isEmptyAddress, validatePhoneAreaCode } from "miter-utils";
import { LinkedTeamMembersWarning, useLinkedTeamMembers } from "dashboard/hooks/useLinkedTeamMembers";
import { LinkedTeamMember } from "backend/services/team-member-service";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

const EXCLUDE_FIELDS_FROM_UPDATE = ["use_same_address_for_mailing_address"];

type Props = {
  defaultData: AggregatedTeamMember;
  onHide: () => void;
  onSubmit: (data: CleanedUpdateData, scheduleChanges: boolean) => void;
  submitting: boolean;
  form: UseFormMethods;
  submitText?: string;
};

type CleanedUpdateData = $TSFixMe;

const PersonalInformationForm: React.FC<Props> = ({
  defaultData,
  onHide,
  onSubmit,
  submitting,
  form,
  submitText,
}) => {
  const { register, control, errors, handleSubmit, formState, setValue } = form;
  const { can, cannot } = useMiterAbilities();

  const formData = form.watch();

  const linkedTeamMembers = useLinkedTeamMembers({
    email: formData.email,
    phone: formData.phone,
    teamMemberID: defaultData._id,
    defaultEmail: defaultData.email,
    defaultPhone: defaultData.phone,
  });

  const cleanDataForUpdate = (data, scheduleChanges: boolean) => {
    const update: CleanedUpdateData = {};
    // Loop through dirtied fields and make sure formatting is
    // compatible with backend.
    for (const key of Object.keys(formState.dirtyFields)) {
      if (key === "dob") {
        update.dob = cleanDateDropdown(data.dob);
      } else if (key === "address") {
        update.address = cleanAddress(data.address);
      } else if (key === "residence") {
        update.address = cleanAddress(data.residence);
      } else if (key === "mailing_address") {
        update.mailing_address = cleanAddress(data.mailing_address);
      } else if (key === "email") {
        if (data.email?.length === 0) {
          update.email = null;
        } else {
          update.email = data.email?.toLowerCase();
        }
      } else if (key === "phone") {
        if (data.phone?.length === 0) {
          update.phone = null;
        } else {
          update.phone = standardizePhoneFormat(data.phone);
        }
      } else {
        if (!EXCLUDE_FIELDS_FROM_UPDATE.includes(key)) update[key] = data[key];
      }
    }

    if (data.use_same_address_for_mailing_address) {
      update.mailing_address = null;
    }

    // Check to see if this action will cause the team member to have no phone or email. If so, notify the user.
    // We should not allow a team member to have no phone or email.
    const willHaveNoEmail = ("email" in update && !update.email) || (!defaultData.email && !update.email);
    const willHaveNoPhone = !data.phone; // This is different because phone input doesn't register dirty values

    if (willHaveNoEmail && willHaveNoPhone) {
      return Notifier.error("Team members must have a phone number or an email address.");
    }

    // We need to do this due to an issue with the phone number input not registering dirty values
    const deletingPhone = !data.phone && !!defaultData.phone;
    if (deletingPhone) {
      update.phone = null;
    }

    // Verify phone is valid
    if (data.phone && !validatePhoneAreaCode(data.phone)) {
      return Notifier.error("Please enter a valid phone number.");
    }

    onSubmit(update, scheduleChanges);
  };
  const renderLinkedTeamMembersMessage = (type: "phone" | "email", linkedTeamMembers: LinkedTeamMember[]) => {
    return (
      <LinkedTeamMembersWarning
        mode="update"
        type={type}
        linkedTeamMembers={linkedTeamMembers}
        employmentType={defaultData.employment_type}
      />
    );
  };

  return (
    <ClickAwayListener onClickAway={() => {}}>
      <div
        className={`modal-wrapper form ${
          can("team:update_sensitive") || can("team:read_sensitive") ? "two-column" : ""
        }`}
      >
        <ModalHeader onHide={onHide} heading={"Edit " + defaultData.first_name + "'s personal info"} />
        {defaultData && (
          <div className="modal-body form two-column">
            <div className={"modal-form-column"}>
              <Formblock
                label="First name"
                defaultValue={defaultData.first_name}
                type="text"
                name="first_name"
                register={register(vals.required)}
                className="modal small-margin"
                errors={errors}
                editing={true}
              />
              <Formblock
                label="Middle name"
                defaultValue={defaultData.middle_name}
                type="text"
                name="middle_name"
                register={register}
                className="modal small-margin"
                errors={errors}
                editing={true}
              />
              <Formblock
                label="Last name"
                defaultValue={defaultData.last_name}
                type="text"
                name="last_name"
                register={register(vals.required)}
                className="modal small-margin"
                errors={errors}
                editing={true}
              />
              <Formblock
                label="Phone"
                defaultValue={defaultData.phone}
                control={control}
                type="phone"
                name="phone"
                className="modal small-margin"
                errors={errors}
                editing={true}
              />
              {renderLinkedTeamMembersMessage("phone", linkedTeamMembers.phone)}
              <Formblock
                label="Email address"
                defaultValue={defaultData.email}
                type="text"
                name="email"
                register={register(vals.emailNotRequired)}
                className="modal small-margin"
                errors={errors}
                editing={true}
              />
              {renderLinkedTeamMembersMessage("email", linkedTeamMembers.email)}
            </div>
            {can("team:read_sensitive") ? (
              <div className={"modal-form-column"}>
                <Formblock
                  label="Date of birth"
                  defaultValue={defaultData.dob || null}
                  type="date-dropdown"
                  name="dob"
                  control={control}
                  register={defaultData.check_tm ? register(vals.required) : register()}
                  className="modal small-margin"
                  errors={errors}
                  editing={true}
                  disabled={cannot("team:update_sensitive")}
                />
                <Formblock
                  label="SSN"
                  type="ssn"
                  name="ssn"
                  register={register}
                  control={control}
                  className="modal"
                  errors={errors}
                  editing={true}
                  placeholder={
                    "XXX-XX-" + (defaultData?.check_tm?.ssn_last_four || defaultData?.ssn_last_four || "XXXX")
                  }
                  disabled={cannot("team:update_sensitive")}
                />
                {defaultData.employment_type === "employee" ? (
                  <Formblock
                    label="Residence"
                    defaultValue={defaultData?.address || null}
                    type="address"
                    name="residence"
                    control={control}
                    labelInfo={
                      defaultData.check_tm
                        ? "A residence is required to successfully enroll the team member in payroll. Either enter an address or create a personal info onboarding task for the team member."
                        : undefined
                    }
                    className="modal small-margin"
                    register={register()}
                    notRequiredRegister={register}
                    errors={errors}
                    editing={true}
                    autocomplete={true}
                    setValue={setValue}
                    disabled={cannot("team:update_sensitive")}
                  />
                ) : (
                  <Formblock
                    label="Address"
                    defaultValue={defaultData?.address || null}
                    type="address"
                    name="address"
                    labelInfo={
                      defaultData.check_tm
                        ? "An address is required to successfully enroll the team member in payroll. Either enter an address or create a personal info onboarding task for the team member."
                        : undefined
                    }
                    register={register()}
                    notRequiredRegister={register}
                    control={control}
                    className="modal small-margin"
                    errors={errors}
                    editing={true}
                    autocomplete={true}
                    setValue={setValue}
                    disabled={cannot("team:update_sensitive")}
                  />
                )}

                <Formblock
                  form={form}
                  name="use_same_address_for_mailing_address"
                  type="checkbox"
                  text="Use same address for mailing address"
                  editing={true}
                  register={register}
                  defaultValue={isEmptyAddress(defaultData.mailing_address)}
                  className="modal"
                />

                {!formData.use_same_address_for_mailing_address && (
                  <Formblock
                    label="Mailing Address"
                    defaultValue={defaultData?.mailing_address || null}
                    type="address"
                    name="mailing_address"
                    control={control}
                    className="modal small-margin"
                    errors={errors}
                    editing={true}
                    autocomplete={true}
                    setValue={setValue}
                    register={register}
                    notRequiredRegister={register}
                    disabled={cannot("team:update_sensitive")}
                  />
                )}
              </div>
            ) : null}
          </div>
        )}
        <ModalFooter
          loading={submitting}
          onCancel={onHide}
          cancelText={"Cancel"}
          onSubmit={handleSubmit((data) => cleanDataForUpdate(data, false))}
          submitText={submitText || "Submit"}
          className="form"
          showEdit={submitText !== "Request changes"}
          editText={"Schedule changes"}
          onEdit={handleSubmit((data) => cleanDataForUpdate(data, true))}
        />
      </div>
    </ClickAwayListener>
  );
};

export default PersonalInformationForm;
