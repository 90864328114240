import React, { useCallback, useEffect, useState } from "react";

import styles from "./benefits.module.css";
import gdl from "dashboard/assets/Guideline.png";
import { Button } from "ui";
import { Notifier } from "dashboard/utils";
import { MiterAPI } from "dashboard/miter";
import CheckIntegrationModal from "./CheckIntegrationModal";

import { Helmet } from "react-helmet";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

type IntegrationProps = {
  src: string;
  label: string;
  description: string;
  connected: boolean;
  disabled: boolean;
  buttonText: string;
  buttonAction: () => void;
};

export const IntegrationItem: React.FC<IntegrationProps> = ({
  src,
  label,
  description,
  connected,
  disabled,
  buttonText,
  buttonAction,
}) => {
  return (
    <div className={styles["integration-wrapper"]}>
      <div className={styles["integration-logo-wrapper"]}>
        <img src={src} className={styles["integration-logo"]} />
      </div>
      <div className="flex-1"></div>
      <div className={styles["integration-description-wrapper"]}>
        <div className="bold">{label}</div>
        <div className={styles["integration-description"]}>{description}</div>
      </div>
      <div className="flex-1"></div>
      <div className={styles["integration-status-wrapper"]}>
        <div
          className={`${styles["status-circle"]} ${connected && !disabled ? styles["enabled"] : ""}`}
        ></div>
        <div>{connected ? (disabled ? "Disabled" : "Enabled") : "Not yet enabled"}</div>
      </div>
      <div className="flex-1"></div>
      <div className={styles["integration-button-wrapper"]}>
        <Button className="button-2" text={buttonText} onClick={buttonAction} />
      </div>
    </div>
  );
};

export const Integrations: React.FC = () => {
  const [fetched, setFetched] = useState(false);
  const [isEnrolledInGuideline, setIsEnrolledInGuideline] = useState(false);
  const [guidelineLink, setGuidelineLink] = useState("");
  const [displayGuidelineModal, setDisplayGuidelineModal] = useState(false);

  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();

  const getGuidelineEnrollmentStatus = useCallback(async () => {
    try {
      const benefits = await MiterAPI.benefits.company.retrieve_for_company(activeCompanyId!);
      const isEnrolled = benefits.some((benefit) => benefit.guideline_config);
      setIsEnrolledInGuideline(isEnrolled);
    } catch (e) {
      console.error(e);
      Notifier.error("We are unable to get the status of your integration with Guideline at this moment.");
    }
    setFetched(true);
  }, [activeCompanyId]);

  const redirectToGuidelineOnboardingLink = async () => {
    try {
      const res = await MiterAPI.integrations.get_setup_link({
        company_id: activeCompanyId!,
        key: "guideline",
      });
      if (res.error) throw new Error(res.error);
      if (!res.link) throw new Error("No link found.");
      window.open(res.link, "_blank");
    } catch (e) {
      console.error(e);
      Notifier.error("We are unable to get your guideline onboarding link at this time.");
    }
  };

  const redirectToGuideline = async () => {
    if (isEnrolledInGuideline) {
      const resp = await MiterAPI.integrations.guideline.get_modal_link(activeCompanyId!, {
        email: activeCompany?.check_company?.email || "",
      });
      setGuidelineLink(resp.url);
      setDisplayGuidelineModal(true);
    } else {
      redirectToGuidelineOnboardingLink();
    }
  };

  useEffect(() => {
    getGuidelineEnrollmentStatus();
  }, [getGuidelineEnrollmentStatus]);

  return (
    <div>
      <Helmet>
        <title>Benefits Integrations | Miter</title>
      </Helmet>
      <div className="vertical-spacer"></div>
      {fetched && (
        <div>
          <IntegrationItem
            src={gdl}
            label="Guideline 401(k) plans"
            description="Guideline offers easy, low-cost 401(k) plans."
            connected={isEnrolledInGuideline}
            disabled={!isEnrolledInGuideline}
            buttonText={isEnrolledInGuideline ? "Manage plan" : "Get started"}
            buttonAction={redirectToGuideline}
          />
          <div style={{ height: 20 }} />
        </div>
      )}
      {displayGuidelineModal ? (
        <CheckIntegrationModal
          link={guidelineLink}
          onHide={() => setDisplayGuidelineModal(false)}
          headerText="Guideline"
        />
      ) : null}
    </div>
  );
};
