import AppContext from "dashboard/contexts/app-context";
import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { Button, Formblock } from "ui";

interface BambooAuthenticationProps {
  integration: MiterIntegrationForCompany;
  onLogin: () => Promise<void>;
}

export const BambooAuthentication: React.FC<BambooAuthenticationProps> = ({ integration, onLogin }) => {
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const { getIntegrations } = useContext(AppContext);

  const login = async (data) => {
    if (!integration?.connection) return;
    setLoading(true);
    try {
      const { bamboo_domain, api_token } = data;
      const response = await MiterAPI.integrations.submit_access_token({
        id: integration.connection?._id,
        domain: bamboo_domain,
        access_token: api_token,
      });
      if (response.error) throw new Error(response.error);
      // In order, so status is updated before the page refreshes and shows the normal status page!
      await onLogin();
      await getIntegrations();
      Notifier.success("Successfully authenticated with Bamboo.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`Error logging in: ${e.message}`);
    }
    setLoading(false);
  };

  return (
    <div style={{ border: "1px solid lightgray", padding: 20, borderRadius: 5 }}>
      <div className="vertical-spacer" />
      <Formblock
        label={"Bamboo Domain"}
        name="bamboo_domain"
        type="text"
        labelInfo="This is the domain of your Bamboo account, e.g., https://mycompanyname.bamboohr.com/"
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <Formblock
        label="API Token"
        name="api_token"
        type="password"
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <div className="vertical-spacer-small"></div>
      <Button
        text="Connect"
        onClick={form.handleSubmit(login)}
        className="button-2 no-margin"
        loading={loading}
      />
    </div>
  );
};
