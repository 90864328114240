import React from "react";
import { ChangeRequestFieldsChangedType, FieldChange } from "backend/models/change-request";
import styles from "./fieldsChangedTable.module.css";
import {
  convertToDisplayRateString,
  getUserFriendlyTmFieldName,
} from "dashboard/pages/team-members/TeamUtils";
import { addressString } from "dashboard/utils";
import {
  useActiveCompany,
  useLookupActivity,
  useLookupCostType,
  useLookupDepartment,
  useLookupHolidaySchedule,
  useLookupJob,
  useLookupLedgerMapping,
  useLookupOtRule,
  useLookupPaySchedule,
  useLookupPrg,
  useLookupRateClassification,
  useLookupStandardClassification,
  useLookupTeam,
  useLookupWcCode,
} from "dashboard/hooks/atom-hooks";
import { capitalize, isEqual } from "lodash";

// simple table that displays changed fields for a change request

type FieldsChangedTableProps = {
  parentId: string;
  fieldsChanged: ChangeRequestFieldsChangedType;
};

const FieldsChangedTable: React.FC<FieldsChangedTableProps> = ({ parentId, fieldsChanged }) => {
  const lookupPrg = useLookupPrg();
  const lookupRateClassification = useLookupRateClassification();
  const lookupDepartment = useLookupDepartment();
  const lookupPaySchedule = useLookupPaySchedule();
  const lookupHolidaySchedules = useLookupHolidaySchedule();
  const lookupOtRule = useLookupOtRule();
  const lookupActivity = useLookupActivity();
  const lookupCostType = useLookupCostType();
  const lookupGlMapping = useLookupLedgerMapping();
  const lookupJob = useLookupJob();
  const lookupStandardClassification = useLookupStandardClassification();
  const lookupWcCode = useLookupWcCode();
  const lookupTeam = useLookupTeam();
  const company = useActiveCompany();

  const getUserFriendlyTmFieldValue = (field: string, value: string): string | undefined => {
    switch (field) {
      case "department_id":
        return lookupDepartment(value)?.name;
      case "pay_schedule_id":
        return lookupPaySchedule(value)?.label;
      case "default_ot_rule_id":
        return lookupOtRule(value)?.label;
      case "default_job_id":
        return lookupJob(value)?.name;
      case "default_activity_id":
        return lookupActivity(value)?.label;
      case "default_cost_type_id":
        return lookupCostType(value)?.label;
      case "ledger_mapping_id":
        return lookupGlMapping(value)?.name;
      case "standard_classification_id":
        return lookupStandardClassification(value)?.label;
      case "holiday_schedule_id":
        return lookupHolidaySchedules(value)?.title;
      case "wc_code":
        return lookupWcCode(value)?.label;
      case "pay_type":
        return capitalize(value);
      case "pay_rate_group":
        return lookupPrg(value)?.label;
      case "reports_to":
        return lookupTeam(value)?.full_name;
      case "is_universal_supervisor":
      case "can_enable_kiosk":
        return value ? "Yes" : "No";
      default:
        return value;
    }
  };

  const buildRow = (key: string, prevVal, newVal) => {
    return (
      <tr key={key}>
        <td>{getUserFriendlyTmFieldName(key)}</td>
        <td>{getUserFriendlyTmFieldValue(key, prevVal) || "-"}</td>
        <td>{getUserFriendlyTmFieldValue(key, newVal) || "-"}</td>
      </tr>
    );
  };

  const getTableRowsForField = (field: FieldChange) => {
    if (field.key === "demographics") {
      const rows: JSX.Element[] = [];
      for (const key in field.value.new_value) {
        const prevVal = field.value.previous_value?.[key] || null;
        const newVal = field.value.new_value[key];
        if (prevVal !== newVal) rows.push(buildRow(key, prevVal, newVal));
      }
      return rows;
    }
    if (field.key === "emergency_contacts") {
      const rows: JSX.Element[] = [];
      for (let i = 0; i < 2; i++) {
        for (const key in field.value.new_value[i]) {
          const prevVal = field.value.previous_value.length > 0 ? field.value.previous_value[i][key] : null;
          const newVal = field.value.new_value[i][key];
          if ((!!prevVal || !!newVal) && prevVal !== newVal)
            rows.push(buildRow("Emergency contact #" + (i + 1) + " " + key, prevVal, newVal));
        }
      }
      return rows;
    }
    if (field.key === "address") {
      return buildRow(
        "Residence",
        addressString(field.value.previous_value),
        addressString(field.value.new_value)
      );
    }
    if (field.key === "mailing_address") {
      return buildRow(
        "Mailing Address",
        addressString(field.value.previous_value),
        addressString(field.value.new_value)
      );
    }
    if (field.key === "prg_classifications") {
      const rows: JSX.Element[] = [];
      for (const key in field.value.new_value) {
        const prgLabel = lookupPrg(key)?.label;
        const prevRateClassification = lookupRateClassification(field.value.previous_value[key]);
        const newRateClassification = lookupRateClassification(field.value.new_value[key]);
        if (prgLabel && !isEqual(prevRateClassification, newRateClassification)) {
          rows.push(
            buildRow(prgLabel, prevRateClassification?.classification, newRateClassification?.classification)
          );
        }
      }
      return rows;
    }
    if (field.key === "union_rate") {
      const rows: JSX.Element[] = [];
      const prevUnionRate = lookupRateClassification(field.value.previous_value);
      const newUnionRate = lookupRateClassification(field.value.new_value);
      if (prevUnionRate?.classification !== newUnionRate?.classification) {
        rows.push(buildRow("union_rate", prevUnionRate?.classification, newUnionRate?.classification));
      }
      if (prevUnionRate?.pay_rate_group !== newUnionRate?.pay_rate_group) {
        rows.push(buildRow("pay_rate_group", prevUnionRate?.pay_rate_group, newUnionRate?.pay_rate_group));
      }
      return rows;
    }
    if (field.key === "pay_rate") {
      const tm = lookupTeam(parentId);
      const prevPayType =
        fieldsChanged.find((field) => field.key === "pay_type")?.value.previous_value || tm?.pay_type;
      const newPayType =
        fieldsChanged.find((field) => field.key === "pay_type")?.value.new_value || tm?.pay_type;
      const prevSalaryRateDisplay =
        fieldsChanged.find((field) => field.key === "salary_rate_display")?.value.previous_value ||
        tm?.salary_rate_display;
      const newSalaryRateDisplay =
        fieldsChanged.find((field) => field.key === "salary_rate_display")?.value.new_value ||
        tm?.salary_rate_display;
      const prevPayRateDisplay = convertToDisplayRateString(
        company,
        prevPayType,
        field.value.previous_value,
        prevPayType === "salary" ? prevSalaryRateDisplay : "hour"
      );
      const newPayRateDisplay = convertToDisplayRateString(
        company,
        newPayType,
        field.value.new_value,
        newPayType === "salary" ? newSalaryRateDisplay : "hour"
      );
      if (prevPayRateDisplay !== newPayRateDisplay) {
        return buildRow("pay_rate", prevPayRateDisplay, newPayRateDisplay);
      }
    }
    if (field.key === "salary_rate_display") {
      return;
    }
    const prevVal = field.value.previous_value;
    const newVal = field.value.new_value;
    return prevVal !== newVal && buildRow(field.key, prevVal, newVal);
  };

  return (
    <table className={styles["simple-table"]}>
      <thead className={styles["simple-table-header"]}>
        <tr>
          <th>Field</th>
          <th>Previous value</th>
          <th>New value</th>
        </tr>
      </thead>
      <tbody className={styles["simple-table-body"]}>
        {fieldsChanged.map((field) => getTableRowsForField(field))}
      </tbody>
    </table>
  );
};

export default FieldsChangedTable;
