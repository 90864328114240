import React, { ReactNode } from "react";
import SearchBar from "../components/search/searchBar";
import Sidebar from "../components/sidebar/sidebar";
import { useMenu } from "../routes";
import ErrorBoundary from "../components/error/errorBoundary";
import { useContext } from "react";
import AppContext from "../contexts/app-context";
import LoadingPage from "../components/shared/LoadingPage";
import { Navigate } from "react-router-dom";
import Notifier from "dashboard/utils/notifier";
import { useActiveAccount, useAuthToken, useInitializing, useUserFetched } from "dashboard/hooks/atom-hooks";

type Props = {
  children: ReactNode;
  accessible: () => boolean;
};

const DashboardWrapper: React.FC<Props> = ({ children, accessible }) => {
  const { shiftPage, setShiftPage } = useContext(AppContext);
  const activeAccount = useActiveAccount();
  const userFetched = useUserFetched();
  const initializing = useInitializing();
  const menu = useMenu();
  const authToken = useAuthToken();

  if (!authToken) {
    localStorage.setItem("redirect", window.location.pathname + window.location.search);
    return <Navigate to="/login" replace />;
  }

  // Show the loading page until data has been fetched
  if (!userFetched || initializing) return <LoadingPage />;

  if (!activeAccount) {
    if (process.env.REACT_APP_ENVIRONMENT !== "development") {
      // When we switch branches in dev and hot reload, both frontend and backend reset, so frontend tries to fetch user data while backend is restarting, meaning that userFetched is true but user is null. In dev, just show a blank page. In prod, this won't really happen, but if it does somehow, then log them out so they can try again.
      Notifier.error("There was an issue logging in, please try again.");
      return <Navigate to="/logout" replace />;
    } else {
      return null;
    }
  }

  if (!accessible()) {
    Notifier.error("You are not authorized to access this page.");
    return <Navigate to="/home" replace />;
  }

  return (
    <div className="app-wrapper">
      <div className={"active-page-wrapper " + (shiftPage && " shift-page")}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
      <Sidebar menuItems={menu} setShiftPage={setShiftPage} shiftPage={shiftPage} />
      <SearchBar shift={shiftPage} />
    </div>
  );
};

export default DashboardWrapper;
