import { TeamMemberOnboardingTaskType } from "backend/services/team-member-onboarding/team-member-onboarding-types";
import {
  BankAccountsOnboardingTask,
  CertificationOnboardingTask,
  CustomFieldOnboardingTask,
  CustomOnboardingTask,
  FillDocumentOnboardingTask,
  SignDocumentOnboardingTask,
  EEOOnboardingTask,
  FormOnboardingTask,
  I9EmployeeOnboardingTask,
  I9EmployerOnboardingTask,
  PaymentMethodOnboardingTask,
  PersonalInfoOnboardingTask,
  CreateScreeningOnboardingTaskParams,
  WithholdingsOnboardingTask,
} from "dashboard/miter";
import { ChecklistTaskConfig, ChecklistTaskItem } from "./checklist-utils";

/**
 * A utility type that creates a new type based on T, where all properties are optional
 * except for the 'type' property, which remains required.
 *
 * @template T - The base type that extends { type: TeamMemberOnboardingTaskType }
 */
type OnboardingTaskWithRequiredType<T extends { type: TeamMemberOnboardingTaskType }> = Partial<
  Omit<T, "type">
> &
  Pick<T, "type"> & {
    assignee_type?: "admin" | "new_hire";
  };

// Update the individual form types
export type PersonalInfoOnboardingTaskForm = OnboardingTaskWithRequiredType<PersonalInfoOnboardingTask>;
export type EEOOnboardingTaskForm = OnboardingTaskWithRequiredType<EEOOnboardingTask>;
export type WithholdingsOnboardingTaskForm = OnboardingTaskWithRequiredType<WithholdingsOnboardingTask> & {
  isContractor?: boolean;
};
export type PaymentMethodOnboardingTaskForm = OnboardingTaskWithRequiredType<PaymentMethodOnboardingTask>;
export type BankAccountsOnboardingTaskForm = OnboardingTaskWithRequiredType<BankAccountsOnboardingTask>;
export type I9EmployeeOnboardingTaskForm = OnboardingTaskWithRequiredType<I9EmployeeOnboardingTask>;
export type I9EmployerOnboardingTaskForm = OnboardingTaskWithRequiredType<I9EmployerOnboardingTask>;
export type FillDocumentOnboardingTaskForm = OnboardingTaskWithRequiredType<FillDocumentOnboardingTask>;
export type SignDocumentOnboardingTaskForm = OnboardingTaskWithRequiredType<SignDocumentOnboardingTask>;
export type FormOnboardingTaskForm = OnboardingTaskWithRequiredType<FormOnboardingTask>;
export type CertificationOnboardingTaskForm = OnboardingTaskWithRequiredType<CertificationOnboardingTask>;
export type CustomFieldOnboardingTaskForm = OnboardingTaskWithRequiredType<CustomFieldOnboardingTask>;
export type CustomOnboardingTaskForm = OnboardingTaskWithRequiredType<CustomOnboardingTask> & {
  name?: string;
  description?: string;
};
export type ScreeningOnboardingTaskForm = OnboardingTaskWithRequiredType<CreateScreeningOnboardingTaskParams>;

/**
 * Represents the union of all possible onboarding task form types.
 * This type is used as the base for the checklist configuration and task items.
 */
export type TeamMemberOnboardingTaskForm =
  | PersonalInfoOnboardingTaskForm
  | EEOOnboardingTaskForm
  | WithholdingsOnboardingTaskForm
  | PaymentMethodOnboardingTaskForm
  | BankAccountsOnboardingTaskForm
  | I9EmployeeOnboardingTaskForm
  | I9EmployerOnboardingTaskForm
  | FillDocumentOnboardingTaskForm
  | SignDocumentOnboardingTaskForm
  | FormOnboardingTaskForm
  | CertificationOnboardingTaskForm
  | CustomFieldOnboardingTaskForm
  | CustomOnboardingTaskForm
  | ScreeningOnboardingTaskForm;

/**
 * Specific configuration type for team member onboarding tasks.
 * This type uses ChecklistTaskConfig with TeamMemberOnboardingTaskForm
 * to create a strongly-typed configuration object for all possible
 * team member onboarding task types.
 */
export type TeamMemberOnboardingTaskConfig = ChecklistTaskConfig<TeamMemberOnboardingTaskForm>;

/**
 * Checks if an admin task has valid assignees selected
 * @param task The onboarding task to validate
 * @returns boolean indicating if the task has valid assignee(s) when required
 */
export const hasValidAssignees = (
  task: TeamMemberOnboardingTaskForm | ChecklistTaskItem<TeamMemberOnboardingTaskForm>
): boolean => {
  const taskData = "data" in task ? task.data : task;
  return taskData.assignee_type !== "admin" || Boolean(taskData.assignee?.length);
};
