import { useMemo, useState } from "react";
import ActivitiesTable from "dashboard/components/tables/ActivitiesTable";
import { useAccessFilter } from "dashboard/hooks/access-filters/useAccessFilter";
import { useActivities, useJobs } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember } from "dashboard/miter";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { ActionModal } from "ui";
import { Pencil } from "phosphor-react";
import { TeamMemberActivityAssociations } from "./TeamMemberActivityAssociations";
import JobsTable from "dashboard/components/tables/JobsTable";
import { TeamMemberJobAssociations } from "./TeamMemberJobAssociations";

type TeamMemberAssociationsModalProps = {
  entityName: string;
  teamMember: AggregatedTeamMember;
  setOpenModal: (open: boolean) => void;
};

export const TeamMemberAssociationsModal: React.FC<TeamMemberAssociationsModalProps> = ({
  entityName,
  teamMember,
  setOpenModal,
}) => {
  const [showBulkSelector, setShowBulkSelector] = useState<boolean>(false);
  const accessFilters = useAccessFilter({ entityName: entityName });

  const activities = useActivities();
  const jobs = useJobs();

  const associatedEntityIds = useMemo(() => {
    return accessFilters.entityIds({ teamMember });
  }, [accessFilters, teamMember]);

  const hiddenActivitiesSet = useMemo(() => {
    return new Set(activities.filter((activity) => !associatedEntityIds.has(activity._id)));

    // eslint wants to include teamMembers, but at the risk of being
    // passed an unmemoized teamMember, we check for team_member._id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedEntityIds, activities, teamMember?._id]);

  const filteredJobs = useMemo(() => {
    return jobs.filter((job) => associatedEntityIds.has(job._id.toString()));
  }, [associatedEntityIds, jobs]);

  const overrideStaticActions = useMemo(
    () => [
      {
        label: `Edit accessible ${pluralize(entityName, 2)}`,
        className: "button-2 no-margin table-button",
        action: () => setShowBulkSelector(true),
        important: true,
        icon: <Pencil weight="bold" style={{ marginRight: 5 }} />,
      },
    ],
    [entityName]
  );

  const renderTable = () => {
    switch (entityName) {
      case "activity": {
        return (
          <ActivitiesTable
            hideActions={true}
            hideBulkEdit={true}
            hiddenActivitiesSet={hiddenActivitiesSet}
            overrideStaticActions={overrideStaticActions}
          />
        );
      }
      case "job": {
        return (
          <JobsTable
            filteredJobs={filteredJobs}
            hideActions={true}
            hideBulkEdit={true}
            overrideStaticActions={overrideStaticActions}
          />
        );
      }
      default:
        return null;
    }
  };

  const renderBulkSelectorModal = () => {
    switch (entityName) {
      case "activity": {
        return (
          <TeamMemberActivityAssociations
            teamMember={teamMember}
            associatedActivityIds={associatedEntityIds}
            showBulkSelector={showBulkSelector}
            setShowBulkSelector={setShowBulkSelector}
          />
        );
      }
      case "job": {
        return (
          <TeamMemberJobAssociations
            teamMember={teamMember}
            associatedJobIds={associatedEntityIds}
            showBulkSelector={showBulkSelector}
            setShowBulkSelector={setShowBulkSelector}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <ActionModal
      headerText={`${pluralize(capitalize(entityName), 2)} accessible to team member`}
      showSubmit={false}
      showCancel={false}
      onCancel={() => setOpenModal(false)}
      onHide={() => setOpenModal(false)}
      wrapperStyle={{ minWidth: 1000 }}
      bodyStyle={{
        padding: 30,
        paddingTop: 0,
        paddingBottom: entityName === "job" ? 110 : 30,
        overflowY: "visible",
        maxHeight: "fit-content",
      }}
      hideFooter={true}
    >
      <div>
        {renderTable()}
        {renderBulkSelectorModal()}
      </div>
    </ActionModal>
  );
};
