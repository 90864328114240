import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ActionModal, ConfirmModal, Formblock, TableV2 } from "ui";

import { AllocationRuleGroup, MiterAPI } from "dashboard/miter";
import { capitalize, Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import {
  useActiveCompanyId,
  useLookupAllocationRuleGroups,
  useRefetchAllocationRuleGroups,
} from "dashboard/hooks/atom-hooks";
import { AllocationRulesTable, AllocationRuleTableEntry } from "./AllocationRulesTable";
import { useAllocationRuleDimensions } from "./useAllocationRuleDimensions";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { useAllocationRuleGroupItems } from "./useAllocationRuleGroupItems";
import { ArrowRight, Eye } from "phosphor-react";
import ObjectID from "bson-objectid";

type Props = {
  readonly?: boolean;
  ruleGroupId?: string;
  type: AllocationRuleGroup["type"];
  onFinish: (allocationRuleGroup: AllocationRuleGroup) => void;
  onHide: () => void;
};

type AllocationRuleGroupForm = Omit<AllocationRuleGroup, "_id" | "created_at" | "updated_at">;

const AllocationRuleGroupModal: React.FC<Props> = ({ ruleGroupId, type, onFinish, onHide, readonly }) => {
  // General hooks
  const activeCompanyId = useActiveCompanyId();
  const form = useForm<AllocationRuleGroupForm>();
  useWatch({ control: form.control });

  const lookupAllocationRuleGroup = useLookupAllocationRuleGroups();
  const allocationRuleGroup = lookupAllocationRuleGroup(ruleGroupId);
  const refetchAllocationRuleGroups = useRefetchAllocationRuleGroups();
  const dimensions = useAllocationRuleDimensions(type);
  const { getAssociatedItems } = useAllocationRuleGroupItems(type);

  const [showAssociatedItems, setShowAssociatedItems] = useState(false);
  const associatedItems = getAssociatedItems(allocationRuleGroup?._id);
  const associatedItemsTableData = useMemo(
    () => associatedItems.map((item) => ({ ...item, _id: item.item._id })),
    [associatedItems]
  );

  const { setFailures, renderFailuresModal } = useFailuresModal({ onClose: () => setFailures([]) });

  // State functions
  const [name, setName] = useState<string>(allocationRuleGroup?.name || "");
  const [rules, setRules] = useState<AllocationRuleGroup["rules"]>(
    allocationRuleGroup?.rules || [
      {
        _id: "default-" + ObjectID().toHexString(),
        amount: 0,
        amount_type: "percent",
        dimensions: {},
      },
    ]
  );

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (allocationRuleGroup) {
      setName(allocationRuleGroup.name);
      setRules(allocationRuleGroup.rules);
    }
  }, [allocationRuleGroup]);

  const hasDuplicateRules = (data: AllocationRuleTableEntry[]) => {
    const keySet = new Set<string>();
    const errors: string[] = [];

    for (const rule of data) {
      const key = dimensions.map((dim) => rule.dimensions?.[dim.field] || "").join("-");

      if (keySet.has(key)) {
        let message = `Duplicate rule with amount: ${rule.amount}, dimensions: `;

        dimensions.forEach((dim) => {
          message += `${capitalize(dim.label)}: ${
            dim.labelFormatter(rule.dimensions?.[dim.field]) || "n/a"
          }, `.toLocaleLowerCase();
        });

        errors.push(message);
      }

      keySet.add(key);
    }

    return errors.map((error) => ({ label: "Validation error", message: error }));
  };

  const hasValidTotals = (data: AllocationRuleTableEntry[]) => {
    // Ensure that all the percentages don't add up to more than 100
    // Ensure that if all percentages add up to 100, there are no extra fixed rules
    // Ensure that we have at least one rule with a non-zero amount
    // Cannot have any rules with a negative amount or 0

    if (!data.length) {
      return "Must have at least one rule";
    }

    const hasNonZeroAmount = data.some((rule) => rule.amount > 0);
    if (!hasNonZeroAmount) {
      return "Must have at least one rule with a non-zero amount";
    }

    const hasNonNegativeAmount = data.every((rule) => rule.amount > 0);
    if (!hasNonNegativeAmount) {
      return "All rules must have a positive amount";
    }

    const totalPercent = data.reduce((acc, rule) => {
      if (rule.amount_type === "percent") {
        return acc + rule.amount;
      }

      return acc;
    }, 0);

    if (totalPercent !== 100) {
      return "Total percentage must add up to 100";
    }

    const totalFixed = data.reduce((acc, rule) => {
      if (rule.amount_type === "fixed") {
        return acc + rule.amount;
      }

      return acc;
    }, 0);

    if (totalPercent === 100 && totalFixed > 0) {
      return "Cannot have fixed rules if total percentage is 100";
    }
  };

  const createAllocationRuleGroup = async () => {
    if (allocationRuleGroup || !activeCompanyId) return;
    setLoading(true);

    try {
      const duplicateRules = hasDuplicateRules(rules);
      if (duplicateRules?.length) {
        setFailures(duplicateRules);
        throw new Error("There are duplicate rules that need to be fixed");
      }

      const totalErrors = hasValidTotals(rules);
      if (totalErrors) {
        throw new Error(totalErrors);
        return;
      }

      const res = await MiterAPI.allocation_rule_groups.create({
        company_id: activeCompanyId,
        name,
        type,
        rules,
      });

      if (res.error) throw new Error(res.error);

      Notifier.success(`Allocation rule group created successfully`);
      await refetchAllocationRuleGroups();
      onFinish(res);
      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }

    setLoading(false);
  };

  const updateAllocationRuleGroup = async () => {
    if (!allocationRuleGroup) return;
    setLoading(true);

    try {
      const duplicateRules = hasDuplicateRules(rules);
      if (duplicateRules?.length) {
        setFailures(duplicateRules);
        throw new Error("There are duplicate rules that need to be fixed");
      }

      const totalErrors = hasValidTotals(rules);
      if (totalErrors) {
        throw new Error(totalErrors);
      }

      const res = await MiterAPI.allocation_rule_groups.update(allocationRuleGroup._id, {
        name,
        rules,
      });

      if (res.error) throw new Error(res.error);

      await refetchAllocationRuleGroups();
      onFinish(res);
      onHide();
      Notifier.success(`Allocation rule group updated successfully`);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }

    setLoading(false);
  };

  const deleteAllocationRuleGroup = async () => {
    if (!allocationRuleGroup) return;
    setDeleting(true);
    try {
      const res = await MiterAPI.allocation_rule_groups.delete(allocationRuleGroup._id);
      if (res.error) throw new Error(res.error);

      await refetchAllocationRuleGroups();
      onFinish(res);
      onHide();
      Notifier.success(`Allocation rule group deleted successfully`);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setDeleting(false);
  };

  const submit = () => {
    if (allocationRuleGroup) {
      updateAllocationRuleGroup();
    } else {
      createAllocationRuleGroup();
    }
  };

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="name"
          label="Name*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal"
          defaultValue={allocationRuleGroup?.name}
          onChange={(e) => setName(e.target.value)}
          readOnly={readonly}
        />
        <AllocationRulesTable
          type={type}
          allocationRules={rules}
          setAllocationRules={setRules}
          readonly={readonly}
        />
      </div>
    );
  };

  const renderAssociatedItems = () => {
    if (!showAssociatedItems) return;

    return (
      <ActionModal
        headerText={`Associated items`}
        showSubmit={false}
        showCancel={false}
        showDelete={false}
        onCancel={() => setShowAssociatedItems(false)}
        onHide={() => setShowAssociatedItems(false)}
        wrapperStyle={{ width: "70%" }}
      >
        <TableV2
          id="associated-items-table"
          title="Associated Items"
          resource="items"
          data={associatedItemsTableData}
          columns={[
            {
              headerName: "Item",
              field: "label",
              width: 200,
            },
            {
              headerName: "",
              field: "_id",
              cellRenderer: () => (
                <>
                  View &nbsp;
                  <ArrowRight />{" "}
                </>
              ),
            },
          ]}
          rowLinkBuilder={(item) => item?.url || "#"}
        />
      </ActionModal>
    );
  };

  const getModalHeaderText = () => {
    if (allocationRuleGroup) {
      if (readonly) {
        return `View ${allocationRuleGroup.name}`;
      } else {
        return `Edit ${allocationRuleGroup.name}`;
      }
    } else {
      return `Add new ${type} allocation rule group`;
    }
  };

  return (
    <ActionModal
      headerText={getModalHeaderText()}
      showSubmit={!readonly}
      showCancel={true}
      showDelete={!!allocationRuleGroup && !readonly}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={submit}
      onDelete={() => setShowDeleteConfirm(true)}
      loading={loading}
      wrapperStyle={{ width: "70%" }}
      actionsType="button"
      actions={[
        {
          icon: <Eye style={{ marginRight: 7 }} />,
          label: "View associations",
          action: () => setShowAssociatedItems(!showAssociatedItems),
          className: "button-1 no-margin",
          important: true,
          style: { width: 167 },
          shouldShow: () => !!associatedItems.length,
        },
      ]}
    >
      {renderForm()}
      {renderAssociatedItems()}
      {showDeleteConfirm && (
        <ConfirmModal
          title={`Delete ${allocationRuleGroup?.name}`}
          body={`Are you sure you want to delete this allocation rule group? This will remove the group from all associated items.`}
          onYes={deleteAllocationRuleGroup}
          onNo={() => setShowDeleteConfirm(false)}
          loading={deleting}
        />
      )}
      {renderFailuresModal()}
    </ActionModal>
  );
};

export default AllocationRuleGroupModal;
