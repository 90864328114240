import React, { useCallback, useEffect, useState } from "react";
import { MiterAPI } from "dashboard/miter";
import { ActionModal } from "ui";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

type Props = {
  hide: () => void;
};

export const FilingCheckList: React.FC<Props> = ({ hide }) => {
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;
  const [loading, setLoading] = useState(false);

  const [companyCheckListComponent, setCompanyCheckListComponent] = useState<string>();

  const getCheckList = useCallback(async () => {
    try {
      setLoading(true);
      const checkListLinkResponse = await MiterAPI.companies.get_company_checklist_component(
        activeCompanyId!,
        "filing_preparation"
      );
      if (checkListLinkResponse.error) {
        throw checkListLinkResponse.error;
      }
      setCompanyCheckListComponent(checkListLinkResponse?.link);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [activeCompanyId]);

  useEffect(() => {
    getCheckList();
  }, [getCheckList]);

  return (
    <ActionModal
      loading={loading}
      wrapperStyle={{ width: 500, height: 700 }}
      bodyStyle={{ height: 700, maxHeight: 700, paddingLeft: 0, paddingRight: 0 }}
      headerText="Filing checklist"
      hideFooter={true}
      onHide={hide}
    >
      <iframe
        scrolling="no"
        height={"100%"}
        width={"100%"}
        frameBorder="0"
        src={companyCheckListComponent}
      ></iframe>
    </ActionModal>
  );
};
