import { useMemo, useState } from "react";
import { useJobNameFormatter, useJobOptions, useLookupJob, useRefetchJobs } from "dashboard/hooks/atom-hooks";
import { AggregatedJob, AggregatedTeamMember, Job, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { notNullish } from "miter-utils";
import { BulkSelectOptionValue } from "packages/ui/bulk-actions/BulkSelector";
import { BulkSelectorModal } from "ui";
import pluralize from "pluralize";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";

// Accessibility of an job can be edited if:
// 1. It has no exclusion groups.
// 2. All team members were added individually (e.g., team_member_group.type === "team_member").
const canEditAccessibility = (job: Job | AggregatedJob, teamMemberId: string) => {
  const customAccessFilter = job.custom_access_filter;
  const excludedGroups = customAccessFilter?.excluded_groups ?? [];

  if (excludedGroups.length === 0) return true;

  const allExclusionsAreTeamMembers = excludedGroups.every(
    (excludedGroup) => excludedGroup.type === "team_member"
  );
  const teamMemberIsExcluded = excludedGroups.some(
    (excludedGroup) => excludedGroup.type === "team_member" && excludedGroup.value === teamMemberId
  );

  return allExclusionsAreTeamMembers && teamMemberIsExcluded;
};

type TeamMemberJobAssociationsProps = {
  teamMember: AggregatedTeamMember;
  associatedJobIds: Set<string>;
  showBulkSelector: boolean;
  setShowBulkSelector: (showBulkSelector: boolean) => void;
};

export const TeamMemberJobAssociations: React.FC<TeamMemberJobAssociationsProps> = ({
  associatedJobIds,
  teamMember,
  showBulkSelector,
  setShowBulkSelector,
}: TeamMemberJobAssociationsProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const jobOptions = useJobOptions();
  const lookupJob = useLookupJob();
  const refetchJobs = useRefetchJobs();
  const jobFormatter = useJobNameFormatter();
  const { setFailures, renderFailuresModal } = useFailuresModal();

  const selections = useMemo(() => {
    return jobOptions.filter((jobOption) => associatedJobIds.has(jobOption.value)).map((o) => o.value);
  }, [associatedJobIds, jobOptions]);

  const accessibleJobOptions = useMemo(() => {
    return jobOptions.map((jobOption) => {
      const job = lookupJob(jobOption.value);
      const label = job?.name + (job?.code ? `(${job?.code})` : "");
      const canEditActivityAccessibility =
        selections.includes(job!._id) || canEditAccessibility(job!, teamMember._id);

      return {
        label,
        value: jobOption.value,
        locked: !canEditActivityAccessibility
          ? "Team member has access through a group they belong to. Go to job to update accessibility."
          : undefined,
        disabled: !canEditActivityAccessibility,
      };
    });
  }, [jobOptions, lookupJob, selections, teamMember._id]);

  const buildUpdateJobParams = (
    jobId: string,
    teamMemberId: string,
    type: "include" | "exclude"
  ): { id: string; updateParams: Partial<Job> } | undefined => {
    const job = lookupJob(jobId);
    if (!job) return;

    const jobCustomAccessFilter = job.custom_access_filter;
    const includedGroups = jobCustomAccessFilter?.included_groups || [];
    const excludedGroups = jobCustomAccessFilter?.excluded_groups || [];
    const enableCustomAccessFilters = job.enable_custom_access_filter;
    const shouldEnableCustomAccessFilters =
      includedGroups.length === 0 && excludedGroups.length === 0 && !enableCustomAccessFilters;

    const teamMemberGroupValue = { type: "team_member" as const, value: teamMemberId };

    if (type === "exclude") {
      const teamMemberGroupWithoutTeamMember = includedGroups.filter(
        (includedGroup) => includedGroup.type !== "team_member" && includedGroup.value !== teamMemberId
      );

      return {
        id: job._id,
        updateParams: {
          enable_custom_access_filter: shouldEnableCustomAccessFilters
            ? shouldEnableCustomAccessFilters
            : undefined,
          custom_access_filter: {
            // if team member is added directly, just remove them
            included_groups: teamMemberGroupWithoutTeamMember,
            // if team member is added via a group association, explicitly exclude them
            excluded_groups: [...excludedGroups, teamMemberGroupValue],
          },
        },
      };
    } else {
      const teamMemberGroupWithoutTeamMember = excludedGroups.filter(
        (excludedGroup) => excludedGroup.type !== "team_member" && excludedGroup.value !== teamMemberId
      );

      return {
        id: job._id,
        updateParams: {
          enable_custom_access_filter: shouldEnableCustomAccessFilters
            ? shouldEnableCustomAccessFilters
            : undefined,
          custom_access_filter: {
            included_groups: [...includedGroups, teamMemberGroupValue],
            excluded_groups: teamMemberGroupWithoutTeamMember,
          },
        },
      };
    }
  };

  const updateJobs = async (
    jobsUpdateParamsArray: { id: string; updateParams: Partial<Omit<Job, "_id" | "integrations">> }[]
  ): Promise<void> => {
    try {
      const res = await MiterAPI.jobs.bulk_update(
        jobsUpdateParamsArray.map((j) => ({ _id: j.id, params: j.updateParams }))
      );

      if (res.error) throw new Error(res.error);

      if (res.errors.length) {
        Notifier.warning("Some jobs were not updated.");
        setFailures(
          res.errors.map((e) => {
            const label = jobFormatter(e._id) || `Job ${e._id}`;
            return { label, message: e.message };
          })
        );
      } else {
        Notifier.success(`${pluralize("Job", jobsUpdateParamsArray.length)} deleted.`);
      }

      await refetchJobs(res.successes.map((s) => s._id));
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error("There was an error updating the jobs");
    }
  };

  const handleSubmit = async (updatedSelections: BulkSelectOptionValue[]) => {
    const newSelections: BulkSelectOptionValue[] = updatedSelections
      .filter((updatedSelection) => selections.includes(updatedSelection) === false)
      .map((selection) => selection);

    const removedSelections: BulkSelectOptionValue[] = selections
      .filter((originallySelected) => updatedSelections.includes(originallySelected) === false)
      .map((selection) => selection);

    setSubmitting(true);

    const updateJobArrayParams = [
      ...newSelections.map((newSelection) => buildUpdateJobParams(newSelection, teamMember._id, "include")),
      ...removedSelections.map((removeSelection) =>
        buildUpdateJobParams(removeSelection, teamMember._id, "exclude")
      ),
    ];

    await updateJobs(updateJobArrayParams.filter(notNullish));

    setSubmitting(false);
    setShowBulkSelector(false);
  };

  return (
    <>
      {showBulkSelector && (
        <BulkSelectorModal
          options={accessibleJobOptions}
          selections={selections}
          resource={"jobs"}
          properties={["name", "code"]}
          onSubmit={handleSubmit}
          onCancel={() => setShowBulkSelector(false)}
          submitting={submitting}
        />
      )}
      {renderFailuresModal()}
    </>
  );
};
