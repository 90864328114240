import { AggregatedRecruitingConversation, RecruitingConversation } from "dashboard/types/chat";
import {
  useActiveCompanyId,
  useActiveRecruitingConversation,
  useRecruitingTwilioClient,
  useSetActiveRecruitingConversation,
  useSetPaginatedRecruitingConversations,
  useSetUnreadRecruitingConversationsCount,
  useUser,
} from "../atom-hooks";
import { useEffect } from "react";
import { EMPTY_FORAGE_RESPONSE, getUserLabel } from "dashboard/utils";
import {
  getPaginatedRecruitingConversations,
  markTwilioConversationAsRead,
  sendMessageHelper,
} from "dashboard/utils/chat";
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { ForageResponse } from "backend/utils/forage/forage-types";
import { MiterAPI } from "dashboard/miter";
import { Candidate } from "dashboard/types/ats";
import { FailureItem } from "dashboard/components/shared/FailuresModal";

export type useRecruitingChatUtilities = {
  fetchMoreConversations: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<ForageResponse<unknown>, unknown>, Error>>;
  hasNextPage: boolean;
  markConversationAsRead: (conversation: AggregatedRecruitingConversation) => Promise<void>;
  bulkSendRecruitingMessages: (
    input: BulkSendRecruitingMessagesInput
  ) => Promise<BulkSendRecruitingMessagesResponse>;
};

type FetchInput = {
  pageParam: string | undefined;
  searchParam: string;
};

export const RECRUITING_CONVERSATIONS_KEY = "recruiting_conversations.1";

export type CandidateMessageInput = {
  candidate: Candidate;
  message: string;
};
export type BulkSendRecruitingMessagesInput = {
  conversations: CandidateMessageInput[];
};

type BulkSendRecruitingMessagesResponse = FailureItem[];

export const useRecruitingChatUtilities = (input?: {
  recruitingSearch: string;
}): useRecruitingChatUtilities => {
  const { recruitingSearch } = input || {};

  /** Atom hooks */
  const activeCompanyId = useActiveCompanyId();
  const user = useUser();

  const recruitingTwilioClient = useRecruitingTwilioClient();
  const setRecruitingConversations = useSetPaginatedRecruitingConversations();
  const activeRecruitingConversation = useActiveRecruitingConversation();
  const setActiveRecruitingConversation = useSetActiveRecruitingConversation();
  const setRecruitingUnreadCount = useSetUnreadRecruitingConversationsCount();

  const fetchRecruitingConversations = async (input: FetchInput): Promise<ForageResponse> => {
    const { pageParam, searchParam } = input;

    if (!activeCompanyId || !recruitingTwilioClient) return EMPTY_FORAGE_RESPONSE as $TSFixMe;
    const forageResponse = await getPaginatedRecruitingConversations({
      client: recruitingTwilioClient,
      companyId: activeCompanyId,
      cursor: pageParam,
      search: searchParam,
    });

    setRecruitingConversations((prev) => prev.concat(forageResponse.data));
    return forageResponse;
  };

  // Reset recruiting conversations when search changes
  useEffect(() => {
    setRecruitingConversations([]);
  }, [recruitingSearch, setRecruitingConversations]);

  const queryKey = [
    RECRUITING_CONVERSATIONS_KEY,
    { activeCompanyId, client: !!recruitingTwilioClient, recruitingSearch },
  ] as const;

  const { fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam, queryKey: [, params] }) =>
      fetchRecruitingConversations({ pageParam, searchParam: params.recruitingSearch || "" }),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => {
      if (!lastPage) return undefined;
      return lastPage?.next_page;
    },
  });

  /** This utility function marks a conversation as read, and applies any necessary side-effects. */
  const markConversationAsRead = async (conversation: AggregatedRecruitingConversation): Promise<void> => {
    const updatedConversation = { ...conversation, unread: false };

    /** Set new global states */
    setRecruitingConversations((prev) => prev.concat(updatedConversation));
    if (activeRecruitingConversation?._id === conversation._id) {
      setActiveRecruitingConversation(updatedConversation);
    }

    // Decrement counter if conversation was unread
    const wasUnread = conversation.unread;
    if (wasUnread) {
      setRecruitingUnreadCount((prev) => prev - 1);
    }

    // Mark conversation as read in Twilio
    await markTwilioConversationAsRead(conversation.twilio_conversation);
  };

  const createRecruitingConversation = async (
    candidateId: string
  ): Promise<RecruitingConversation | undefined> => {
    if (!activeCompanyId) throw new Error("Please select a company");
    const res = await MiterAPI.chat.recruiting.create(activeCompanyId, candidateId);
    if (res.error) throw new Error(res.error);
    return res;
  };

  const bulkSendRecruitingMessages = async (
    input: BulkSendRecruitingMessagesInput
  ): Promise<BulkSendRecruitingMessagesResponse> => {
    const { conversations } = input;
    const failures: FailureItem[] = [];
    if (!user) throw new Error("Please login");

    for (const { message, candidate } of conversations) {
      const fullName = `${candidate.first_name} ${candidate.last_name}`;
      try {
        const conversation = await createRecruitingConversation(candidate._id);
        if (!conversation) throw new Error("Failed to create conversation");
        const twilioConversation = await recruitingTwilioClient?.getConversationBySid(
          conversation.conversation_sid
        );
        if (!twilioConversation) throw new Error("Failed to get twilio conversation");
        await sendMessageHelper({
          message,
          twilioConversation,
          userLabel: getUserLabel(user),
        });
      } catch (e: $TSFixMe) {
        failures.push({ label: fullName, message: e.message });
      }
    }
    return failures;
  };

  return {
    fetchMoreConversations: fetchNextPage,
    hasNextPage: !!hasNextPage,
    markConversationAsRead,
    bulkSendRecruitingMessages,
  };
};
