import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MiterAPI } from "../../miter";
import Notifier from "../../utils/notifier";
import { useOneFactorAuthAtom, useSetAuthenticatedUserData } from "dashboard/hooks/atom-hooks";
import ReactCodeInput from "react-code-input";
import styles from "./auth.module.css";

const Verify2FA: React.FC = () => {
  const navigate = useNavigate();
  const setAuthenticatedUserData = useSetAuthenticatedUserData();
  const [oneFactorAuth, setOneFactorAuth] = useOneFactorAuthAtom();
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [authCode, setAuthCode] = useState<string>("");

  const verifyCode = async () => {
    if (!authCode || authCode.length !== 6 || !oneFactorAuth) return;
    setVerifyingCode(true);
    try {
      const { token, methodId } = oneFactorAuth;
      if (!token || !methodId) throw new Error("Missing 1FA authentication data");
      const res = await MiterAPI.users.verifyTwoFactor(authCode, methodId, token);
      if (!res.auth_token || typeof res.auth_token !== "string") throw new Error("Invalid verification code");

      setAuthenticatedUserData(res);
      setOneFactorAuth(null);

      const loginRedirectPath = localStorage.getItem("redirect");

      setTimeout(() => {
        if (loginRedirectPath) {
          localStorage.removeItem("redirect");
          navigate(loginRedirectPath, { replace: true });
        } else {
          navigate("/home", { replace: true });
        }
        window.location.reload();
      }, 1);
    } catch (e: $TSFixMe) {
      setVerifyingCode(false);
      setAuthenticatedUserData(null);
      setOneFactorAuth(null);
      Notifier.error(e.message);
      navigate("/login", { replace: true });
    }
  };

  return (
    <div className={styles["login-wrapper"]}>
      <div className={styles["login-container"]}>
        <div className={styles["login"]}>
          <div className={styles["login-header"]}>
            <h1>Two-Factor Authentication</h1>
            <p>Please enter the 6-digit code sent to your phone</p>
          </div>
          <div className={styles["verify-form"]}>
            <ReactCodeInput
              className={styles["react-code"]}
              inputMode="numeric"
              name="authCode"
              type="number"
              fields={6}
              onChange={setAuthCode}
              disabled={verifyingCode}
            />
            <button
              onClick={verifyCode}
              disabled={verifyingCode || authCode.length !== 6}
              className={styles["login-submit"]}
            >
              {verifyingCode ? "Verifying..." : "Verify"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify2FA;
