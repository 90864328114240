import { AllocationRuleGroup } from "backend/models";
import { useActiveTeam } from "dashboard/hooks/atom-hooks";
import { useMemo, useCallback } from "react";

export type AllocationRuleGroupItems = {
  getAssociatedItems: (allocationRuleGroupId?: string) => { label: string; item: $TSFixMe; url: string }[];
};

export const useAllocationRuleGroupItems = (type: AllocationRuleGroup["type"]): AllocationRuleGroupItems => {
  const teamMembers = useActiveTeam();

  const getAssociatedItems = useCallback(
    (allocationRuleGroupId?: string) => {
      if (!allocationRuleGroupId) return [];

      switch (type) {
        case "salary":
          const associatedTeamMembers = teamMembers
            .filter((tm) => tm.salary_allocation_rule_group_id === allocationRuleGroupId)
            .map((tm) => ({ label: tm.full_name, item: tm, url: `/team-members/${tm._id}` }));

          return associatedTeamMembers;
        default:
          return [];
      }
    },
    [teamMembers, type]
  );

  return useMemo(() => ({ getAssociatedItems }), [getAssociatedItems]);
};
