import React, { useState, useMemo, useCallback } from "react";
import { Wizard, Notifier } from "ui";
import { CreateJobPostingStep } from "./CreateJobPostingStep";
import { AggregatedJobPosting } from "dashboard/types/ats";
import { FormBuilderScreen } from "../forms/FormBuilderScreen";
import { Form } from "dashboard/miter";
import { useRefetchJobPostings, useLookupFormTemplate } from "dashboard/hooks/atom-hooks";
import { useNavigate } from "react-router-dom";
import { TableActionLink } from "ui/table-v2/Table";
import { SelectFormTemplateModal } from "./modals/SelectFormTemplateModal";
import { DetachFormTemplateModal } from "./modals/DetachFormTemplateModal";
import { duplicateFormComponents } from "dashboard/utils/form-templates";
import { MiterAPI } from "dashboard/miter";
import { ArrowsClockwise, FileText, LinkBreak } from "phosphor-react";

type Props = {
  onExit: () => void;
  jobPosting?: AggregatedJobPosting;
  /* If true, the current process is duplicating a job posting */
  isDuplicating?: boolean;
};

type TemplateModalStatus = "select" | "detach" | "switch";

export const JobPostingWizard: React.FC<Props> = ({ onExit, jobPosting, isDuplicating }) => {
  const [updatedJobPosting, setJobPosting] = useState<AggregatedJobPosting | undefined>(jobPosting);
  const [form, setForm] = useState<Form | undefined>(jobPosting?.question_form);
  const refetchJobPosting = useRefetchJobPostings();
  const navigate = useNavigate();
  const [templateModalStatus, setTemplateModalStatus] = useState<TemplateModalStatus>();
  const lookupFormTemplate = useLookupFormTemplate();

  const refetchAlongComplete = async () => {
    await syncJobPostingForm();
    refetchJobPosting(updatedJobPosting?._id);

    // If isDuplicating, navigate to the new job posting
    if (isDuplicating) {
      navigate(`/recruiting/job-postings`);
    }

    onExit();
  };

  /** Updates the job posting to match local state at the time of the wizard completion */
  const syncJobPostingForm = useCallback(async () => {
    try {
      if (!updatedJobPosting) {
        return;
      }

      const res = await MiterAPI.job_postings.update(updatedJobPosting._id, {
        ...updatedJobPosting,
        question_form_template_id: updatedJobPosting?.question_form_template_id || null,
      });
      if (res.error) {
        throw new Error(res.error);
      }
    } catch (e: $TSFixMe) {
      Notifier.error("Failed to update job posting: " + e.message + ".");
    }
  }, [updatedJobPosting]);

  const closeTemplateModal = () => {
    setTemplateModalStatus(undefined);
  };

  const handleFormTemplateSelection = (formTemplateId: string) => {
    const formTemplate = lookupFormTemplate(formTemplateId);
    if (!updatedJobPosting) {
      Notifier.error("Job posting not found");
      return;
    }
    if (!formTemplate) {
      Notifier.error("Form template not found");
      return;
    }
    setForm(formTemplate.form);
    setJobPosting({
      ...updatedJobPosting,
      question_form_template_id: formTemplateId,
      question_form_template: formTemplate,
    });
    Notifier.success("Application form switched to template!");
  };

  const handleDetachingTemplate = () => {
    if (!updatedJobPosting || !updatedJobPosting.question_form_template) {
      Notifier.error("Failed to detach template");
      return;
    }
    const newForm = {
      ...updatedJobPosting.question_form,
      name: updatedJobPosting.question_form_template.form.name,
      components: duplicateFormComponents(updatedJobPosting.question_form_template.form.components),
    };
    setForm(newForm);
    setJobPosting({ ...updatedJobPosting, question_form_template_id: undefined });
    closeTemplateModal();
    Notifier.success("Application form detached from template!");
  };

  const renderModal = () => {
    if (templateModalStatus === "select" || templateModalStatus === "switch") {
      return (
        <SelectFormTemplateModal
          onSubmit={handleFormTemplateSelection}
          onClose={closeTemplateModal}
          formTemplateId={updatedJobPosting?.question_form_template_id || undefined}
        />
      );
    }
    if (templateModalStatus === "detach") {
      return <DetachFormTemplateModal onSubmit={handleDetachingTemplate} onClose={closeTemplateModal} />;
    }
    return null;
  };

  const actions: TableActionLink[] = useMemo(() => {
    const formTemplate = updatedJobPosting?.question_form_template_id;
    return [
      ...(formTemplate
        ? [
            {
              label: "Detach and edit",
              action: () => {
                setTemplateModalStatus("detach");
              },
              className: "button-2 table-button",
              icon: <LinkBreak size={16} style={{ marginRight: 3 }} />,
            },
            {
              label: "Switch template",
              action: () => {
                setTemplateModalStatus("switch");
              },
              className: "button-2 table-button",
              icon: <ArrowsClockwise size={16} style={{ marginRight: 3 }} />,
            },
          ]
        : [
            {
              label: "Select template",
              action: () => {
                setTemplateModalStatus("select");
              },
              className: "button-2 table-button",
              icon: <FileText size={16} style={{ marginRight: 3 }} />,
            },
          ]),
    ];
  }, [updatedJobPosting?.question_form_template_id]);

  return (
    <div>
      <Wizard onExit={onExit} onComplete={refetchAlongComplete}>
        <CreateJobPostingStep
          isDuplicating={isDuplicating}
          setJobPosting={setJobPosting}
          jobPosting={updatedJobPosting}
          name={"Create a job posting"}
          setForm={setForm}
        />

        <FormBuilderScreen
          name={"Application form"}
          formItem={form}
          setFormItem={setForm}
          parentType={"job_posting"}
          parentId={jobPosting?._id}
          actions={actions}
          readonly={!!updatedJobPosting?.question_form_template_id}
        />
      </Wizard>
      {renderModal()}
    </div>
  );
};
