import React, { useEffect, useRef, useState } from "react";
import { Formblock } from "ui";
import { DeepPartial } from "utility-types";
import { IntegrationConnection } from "dashboard/miter";
import { FieldValues, UseFormMethods } from "react-hook-form";
import {
  AcumaticaConnectionMetadata,
  ProjectTaskRestrictionBasis,
} from "backend/services/acumatica/acumatica-types";
import { Option } from "ui/form/Input";
import { ACUMATICA_INPUT_LENGTH, DEFAULT_ACUMATICA_LABEL_STYLE } from "./AcumaticaConfig";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  integrationConnnection: IntegrationConnection | undefined;
  updateAcumaticaMetadata: (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    options?: { collapseCount?: number }
  ) => Promise<void>;
  form: UseFormMethods<FieldValues>;
};

export const syncProjectTasksAsOptions = [
  { value: "sub_jobs", label: "Sub-jobs" },
  { value: "activities", label: "Activities" },
  { value: "do_not_import", label: "Do not import" },
];

export const projectTaskRestrictionFieldOptions: Option<ProjectTaskRestrictionBasis>[] = [
  { label: "Visibility Settings", value: "visibility_settings" },
  { label: "Attributes", value: "attributes" },
];

export const AcumaticaWbsSettings: React.FC<Props> = ({
  integrationConnnection,
  updateAcumaticaMetadata,
  form,
}) => {
  const [configObject, setConfigObject] = useState<AcumaticaConnectionMetadata["configObject"]>(
    integrationConnnection?.metadata?.acumatica?.configObject || {}
  );

  const isMounted = useRef(false);

  const { syncProjectTasksAsSubJobs } = configObject?.jobs || {};
  const { useApproversOnProjectTasks, restrictionFieldForProjectTasks } = configObject?.activities || {};

  const [selectedSyncProjectTasksAs, setSelectedSyncProjectTasksAs] = useState(
    syncProjectTasksAsSubJobs
      ? "sub_jobs"
      : configObject?.activities?.costCodeField === "project_task"
      ? "activities"
      : "do_not_import"
  );

  const debouncedUpdateAcumaticaMetadata = useDebouncedCallback(updateAcumaticaMetadata, 500);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    debouncedUpdateAcumaticaMetadata({ configObject }, { collapseCount: 2 });
  }, [configObject, debouncedUpdateAcumaticaMetadata]);

  const handleSyncProjectTasksAsSubJobsChange = async (
    value: "sub_jobs" | "activities" | "do_not_import"
  ) => {
    setSelectedSyncProjectTasksAs(value);
    const configObject = {};
    if (value === "sub_jobs") {
      configObject["jobs"] = { syncProjectTasksAsSubJobs: true };
      setConfigObject((prev) => ({
        ...prev,
        jobs: { ...prev?.jobs, syncProjectTasksAsSubJobs: true },
        activities: {
          ...prev?.activities,
          costCodeField: "cost_code",
          pullCostCodesFromBudget: false,
        },
      }));
    } else if (value === "activities") {
      setConfigObject((prev) => ({
        ...prev,
        jobs: { ...prev?.jobs, syncProjectTasksAsSubJobs: false },
        activities: {
          ...prev?.activities,
          costCodeField: "project_task",
          pullCostCodesFromBudget: false,
        },
      }));
    } else if (value === "do_not_import") {
      setConfigObject((prev) => ({
        ...prev,
        jobs: { ...prev?.jobs, syncProjectTasksAsSubJobs: false },
        activities: {
          ...prev?.activities,
          costCodeField: "cost_code",
          pullCostCodesFromBudget: false,
        },
      }));
    }
  };

  const handleImportCostCodesChange = async (e) => {
    setConfigObject((prev) => ({
      ...prev,
      activities: {
        ...prev?.activities,
        costCodeField: e.target.checked ? "cost_code" : null,
        pullCostCodesFromBudget: false,
      },
    }));
  };

  const handlePullCostCodesFromBudgetChange = async (e) => {
    setConfigObject((prev) => ({
      ...prev,
      activities: { ...prev?.activities, pullCostCodesFromBudget: e.target.checked },
    }));
  };

  return (
    <>
      <Formblock
        form={form}
        underlineTooltip={true}
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
        options={syncProjectTasksAsOptions}
        name="syncProjectTasksAsSubJobs"
        type="select"
        value={syncProjectTasksAsOptions.find((o) => o.value === selectedSyncProjectTasksAs)}
        labelInfo="How project tasks will be represented in Miter."
        label="Import project tasks as"
        onChange={(o) => handleSyncProjectTasksAsSubJobsChange(o.value)}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        editing={true}
        style={{ alignItems: "center" }}
      />
      <Formblock
        form={form}
        options={projectTaskRestrictionFieldOptions}
        name="restrictionFieldForProjectTasks"
        type="select"
        defaultValue={restrictionFieldForProjectTasks}
        onChange={(option: Option<ProjectTaskRestrictionBasis> | null) => {
          updateAcumaticaMetadata(
            {
              configObject: { activities: { restrictionFieldForProjectTasks: option?.value || null } },
            },
            { collapseCount: 2 }
          );
        }}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Project task restriction"
        labelInfo="The field on the project task Miter will use to restrict non labor related activities when pulling."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
        isClearable
        style={{ alignItems: "center" }}
      />
      {selectedSyncProjectTasksAs === "sub_jobs" && (
        <>
          <Formblock
            form={form}
            underlineTooltip={true}
            inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
            name="useApproversOnProjectTasks"
            type="checkbox"
            labelInfo="Use project task approver as supervisor on Miter sub-jobs."
            label="Import project task approver"
            defaultValue={!!useApproversOnProjectTasks}
            onChange={(e) =>
              updateAcumaticaMetadata(
                {
                  configObject: { activities: { useApproversOnProjectTasks: e.target.checked } },
                },
                { collapseCount: 2 }
              )
            }
            labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
            editing={true}
            style={{ alignItems: "center" }}
          />
        </>
      )}
      {selectedSyncProjectTasksAs !== "activities" && (
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="activityField"
          type="checkbox"
          label="Import cost codes as activities"
          checked={configObject?.activities?.costCodeField === "cost_code"}
          onChange={handleImportCostCodesChange}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
          style={{ alignItems: "center" }}
        />
      )}
      {configObject?.activities?.costCodeField === "cost_code" && (
        <Formblock
          form={form}
          underlineTooltip={true}
          labelInfo="When selected, Miter will import cost codes from the project budget"
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="pullCostCodesFromBudget"
          type="checkbox"
          label="Import cost codes from budget"
          checked={configObject.activities?.pullCostCodesFromBudget}
          onChange={handlePullCostCodesFromBudgetChange}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
          style={{ alignItems: "center" }}
        />
      )}
    </>
  );
};
