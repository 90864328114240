import React, { FC } from "react";
import { JobApplication } from "dashboard/types/ats";
import styles from "./WorkEducationHistory.module.css";
import { DateTime } from "luxon";
import { Badge } from "ui";
import { educationBadgeColors } from "dashboard/utils/ats";

type Education = NonNullable<JobApplication["education"]>[number];

type Props = {
  education: Education;
};

export const EducationalExperience: FC<Props> = ({ education }) => {
  const startDate = DateTime.fromISO(education.start_date).toLocaleString(DateTime.DATE_MED);
  const endDate = education.end_date
    ? DateTime.fromISO(education.end_date).toLocaleString(DateTime.DATE_MED)
    : "Present";

  return (
    <div className={styles["experience-container"]}>
      <div>
        <div className={styles["educational-experience-school-name-and-degree"]}>
          <div className={styles["educational-experience-school-name"]}>{education.school_name}</div>
          <Badge text={education.degree} color={educationBadgeColors[education.degree]} />
        </div>
        <div className={styles["work-experience-dates"]}>
          {startDate} - {endDate}
        </div>
      </div>

      <div className={styles["work-experience-paragraph-content"]}>
        <div className={styles["work-experience-responsibilities"]}>
          <p>{education.field_of_study}</p>
        </div>
      </div>
    </div>
  );
};
