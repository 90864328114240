import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { useFeatureFlag } from "dashboard/hooks/useFeatureFlag";
import { convertSecondsToObject } from "miter-utils";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Label, Notifier } from "ui";

type CompanySecurityFields = {
  dashboard_session_lifespan: {
    days: number;
    hours: number;
    minutes: number;
  };
  mobile_session_lifespan: {
    days: number;
    hours: number;
    minutes: number;
  };
  enable_security_notifs: boolean;
  enable_mfa: boolean;
};

type Props = {
  onHide: () => void;
  onFinish: () => void;
  onUpdate: (data: $TSFixMe, setError: $TSFixMe) => Promise<void>;
};

export const CompanySecurityModal: React.FC<Props> = ({ onHide, onFinish, onUpdate }) => {
  const [saving, setSaving] = useState(false);
  const activeCompany = useActiveCompany();

  const form = useForm<CompanySecurityFields>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: {
      dashboard_session_lifespan: convertSecondsToObject(
        activeCompany?.settings.security?.dashboard_session_lifespan
      ),
      mobile_session_lifespan: convertSecondsToObject(
        activeCompany?.settings.security?.mobile_session_lifespan
      ),
      enable_security_notifs: activeCompany?.settings.security?.enable_security_notifs || false,
      enable_mfa: activeCompany?.settings.security?.mfa_enabled || false,
    },
  });

  const handleSave = async (data: CompanySecurityFields) => {
    setSaving(true);

    try {
      if (!activeCompany) throw new Error("No active company");

      const dashboard_session_lifespan =
        data.dashboard_session_lifespan.days * 86400 +
        data.dashboard_session_lifespan.hours * 3600 +
        data.dashboard_session_lifespan.minutes * 60;

      const mobile_session_lifespan =
        data.mobile_session_lifespan.days * 86400 +
        data.mobile_session_lifespan.hours * 3600 +
        data.mobile_session_lifespan.minutes * 60;

      const params = {
        "settings.security.dashboard_session_lifespan": dashboard_session_lifespan,
        "settings.security.mobile_session_lifespan": mobile_session_lifespan,
        "settings.security.enable_security_notifs": data.enable_security_notifs,
        "settings.security.mfa_enabled": data.enable_mfa,
      };

      await onUpdate(params, () => {});

      Notifier.success("Company security settings saved");
      onFinish();
    } catch (error) {
      console.error(error);
      Notifier.error("Error saving company security settings");
    }

    setSaving(false);
  };

  return (
    <ActionModal
      headerText={"Company security settings"}
      onHide={onHide}
      onSubmit={form.handleSubmit(handleSave)}
      showSubmit={true}
      submitText={"Save"}
      onCancel={onHide}
      showCancel={true}
      cancelText={"Cancel"}
      loading={saving}
      wrapperStyle={{ width: 500 }}
    >
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <div className="form-section">
          {useFeatureFlag("multi_factor_authentication") && (
            <>
              <Formblock
                type="checkbox"
                text="Enable dashboard two-factor authentication"
                name="enable_mfa"
                form={form}
                editing={true}
                className="modal"
              />
              <div
                style={{ marginLeft: 24, marginTop: -15, marginBottom: 12, color: "#666", fontSize: "0.9em" }}
              >
                By enabling two-factor authentication, dashboard users are required to link a phone number
                capable of receiving SMS messages to their Miter account. Users who have not added an
                SMS-enabled phone number will be restricted from accessing the dashboard.
              </div>
            </>
          )}
          <Formblock
            type="checkbox"
            text="Enable security notifications"
            name="enable_security_notifs"
            form={form}
            editing={true}
            className="modal"
          />
          <Label label={"Dashboard session lifespan"} style={{ marginBottom: 10 }} />
          <div className="flex space-between">
            <Formblock
              type="append"
              appendText="days"
              name="dashboard_session_lifespan.days"
              placeholder="Days"
              form={form}
              editing={true}
              min={0}
              className="modal"
              style={{ width: "30%" }}
            />
            <Formblock
              type="append"
              appendText="hours"
              name="dashboard_session_lifespan.hours"
              placeholder="Hours"
              form={form}
              editing={true}
              min={0}
              className="modal"
              style={{ width: "30%" }}
            />
            <Formblock
              type="append"
              appendText="mins"
              name="dashboard_session_lifespan.minutes"
              placeholder="Minutes"
              form={form}
              editing={true}
              min={0}
              className="modal"
              style={{ width: "30%" }}
            />
          </div>
        </div>

        <Label
          label={"Mobile session lifespan"}
          style={{ marginBottom: 10, width: "100%" }}
          sublabel="We recommend keeping mobile sessions as long as possible to reduce the chance that someone's session expires min clock-in."
        />
        <div className="flex space-between">
          <Formblock
            type="append"
            appendText="days"
            name="mobile_session_lifespan.days"
            placeholder="Days"
            form={form}
            editing={true}
            min={0}
            className="modal"
            style={{ width: "30%" }}
          />
          <Formblock
            type="append"
            appendText="hours"
            name="mobile_session_lifespan.hours"
            placeholder="Hours"
            form={form}
            editing={true}
            min={0}
            className="modal"
            style={{ width: "30%" }}
          />
          <Formblock
            type="append"
            appendText="mins"
            name="mobile_session_lifespan.minutes"
            placeholder="Minutes"
            form={form}
            editing={true}
            min={0}
            className="modal"
            style={{ width: "30%" }}
          />
        </div>
      </div>
    </ActionModal>
  );
};
