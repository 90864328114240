import React, { FC } from "react";
import { JobApplication } from "dashboard/types/ats";
import styles from "./WorkEducationHistory.module.css";
import { DateTime } from "luxon";
type WorkExperience = NonNullable<JobApplication["work_experience"]>[number];

type Props = {
  workExperience: WorkExperience;
};

export const WorkExperience: FC<Props> = ({ workExperience }) => {
  const startDate = DateTime.fromISO(workExperience.start_date).toLocaleString(DateTime.DATE_MED);
  const endDate = workExperience.end_date
    ? DateTime.fromISO(workExperience.end_date).toLocaleString(DateTime.DATE_MED)
    : "Present";

  return (
    <div className={styles["experience-container"]}>
      <div>
        <div className={styles["work-experience-job-title"]}>{workExperience.title}</div>
        <div className={styles["work-experience-company"]}>{workExperience.company_name}</div>
        <div className={styles["work-experience-dates"]}>
          {startDate} - {endDate}
        </div>
      </div>

      <div className={styles["work-experience-paragraph-content"]}>
        <div className={styles["work-experience-responsibilities"]}>
          <p>{workExperience.responsibilities}</p>
        </div>
        {workExperience.reason_for_leaving ? (
          <div className={styles["work-experience-reason-for-leaving"]}>
            <p>{workExperience.reason_for_leaving}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
