import React, { FC } from "react";
import { JobApplication } from "dashboard/types/ats";
import styles from "./WorkEducationHistory.module.css";
import { WorkExperience } from "./WorkExperience";
import { DateTime } from "luxon";

type Props = {
  workHistory: NonNullable<JobApplication["work_experience"]>;
};

export const WorkHistory: FC<Props> = ({ workHistory }) => {
  const sortedWorkHistory = workHistory.sort((a, b) => {
    return DateTime.fromISO(b.start_date).diff(DateTime.fromISO(a.start_date)).toMillis();
  });

  if (sortedWorkHistory.length === 0) {
    return <div className={styles["no-work-experience-provided"]}>No work experience was provided.</div>;
  }

  return (
    <div className={styles["work-history-container"]}>
      {sortedWorkHistory.map((work) => (
        <WorkExperience workExperience={work} />
      ))}
    </div>
  );
};
