import React, { useContext } from "react";
import { BankAccountsTable } from "miter-components/bank-accounts/BankAccountsTable";
import { useActiveCompanyId, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { InlineNetPaySplits } from "miter-components/bank-accounts/InlineNetPaySplits";
import { AggregatedTeamMember } from "dashboard/miter";
import { PaymentInfoWarningBanners } from "miter-components/bank-accounts/PaymentInfoWarningBanners";
import { InlinePaymentMethodAndWithholdings } from "miter-components/onboarding/InlinePaymentMethodAndWithholdings";
import AppContext from "dashboard/contexts/app-context";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";

type Props = {
  teamMember: AggregatedTeamMember;
};

export const TeamMemberPaymentInfo: React.FC<Props> = ({ teamMember }) => {
  const { setReverifyUser, setOnReverifyUser } = useContext(AppContext);
  const teamAbilities = useTeamAbilities();
  const refetchTeam = useRefetchTeam();

  const activeCompanyId = useActiveCompanyId();

  const onUpdate = () => {
    refetchTeam(teamMember._id);
  };

  if (!activeCompanyId) {
    return null;
  }

  const verify2FA = (onSuccess: () => void) => {
    setReverifyUser(true);
    setOnReverifyUser(() => onSuccess);
  };

  return (
    <>
      <PaymentInfoWarningBanners teamMember={teamMember} />
      <BankAccountsTable
        teamMember={teamMember}
        companyId={activeCompanyId}
        verify2FA={verify2FA}
        teamAbilities={teamAbilities}
      />
      {teamMember.check_tm && (
        <InlinePaymentMethodAndWithholdings
          teamMember={teamMember}
          onUpdate={onUpdate}
          teamAbilities={teamAbilities}
        />
      )}
      {/* only show for team members enrolled in payroll and selected direct deposit */}
      {teamMember.check_tm?.payment_method_preference === "direct_deposit" && (
        <div className="margin-top-50">
          <InlineNetPaySplits
            teamMember={teamMember}
            onUpdate={onUpdate}
            verify2FA={verify2FA}
            teamAbilities={teamAbilities}
          />
        </div>
      )}
    </>
  );
};
