import InfoButton from "dashboard/components/information/information";
import { useActiveCompany, useActiveCompanyId, useSetActiveCompany } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { set } from "lodash";
import { CodeSimple, WarningCircle } from "phosphor-react";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Formblock, IconWithTooltip, Link, SettingsCard } from "ui";
import styles from "./Ats.module.css";
import * as vals from "dashboard/utils/validators";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import { MessageTemplatesTable } from "dashboard/components/message-templates/MessageTemplatesTable";
import { useHasAccessToCollectWorkExperienceAndEducation } from "dashboard/gating";
import { RejectionReasonsTable } from "dashboard/components/ats/rejection-reasons/RejectionReasonsTable";
import { FormTemplatesTable } from "dashboard/components/form-templates/FormTemplatesTable";

export const RecruitingSettings: React.FC = () => {
  /** Navigation  */
  const navigate = useNavigate();

  /** Gating */
  const hasAccessToCollectWorkExperienceAndEducation = useHasAccessToCollectWorkExperienceAndEducation();
  /** Atom hooks  */
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const setActiveCompany = useSetActiveCompany();

  /** Form management */
  const form = useForm();
  const { trigger } = form;

  /** State management */
  const [recruitingSettings, setRecruitingSettings] = useState(activeCompany?.settings.ats);

  useEffect(() => {
    trigger();
  }, [recruitingSettings?.job_boards?.linkedin?.enabled, trigger]);

  /* Constants */

  const iframeSnippet = `<iframe
  src={"${process.env.REACT_APP_CAREER_PAGE_URL}/${activeCompany?.slug}?iframe=true"}
  style={{
    width: "100%",
    height: 700,
    border: "2px solid rgb(221, 221, 221)",
    borderRadius: 5,
  }}
  scrolling="no"
></iframe>`;

  /** Handlers + utility functions */

  const copyIframeURL = () => {
    navigator.clipboard.writeText(iframeSnippet);
    Notifier.success("Successfully copied iframe snippet to clipboard!");
  };

  const renderCareerPageTitle = () => {
    const hasWebsite = !!activeCompany?.website_url;
    if (hasWebsite) {
      return "Career page";
    }

    return (
      <div className="flex">
        <div>Career page</div>
        <IconWithTooltip
          PhosphorIcon={WarningCircle}
          tooltip={"Please add your company's website from the Company tab of the Settings page."}
          size={14}
          place="right"
          style={{ color: "#CB3331", marginLeft: 5 }}
          weight="fill"
        />
      </div>
    );
  };

  /** Saves the recruiting settings to the backend */

  const handleChange = async (input: { path: string; value: string | boolean | number }) => {
    if (!activeCompanyId) {
      Notifier.error("No active company found. Please refresh the page and try again.");
      return;
    }
    const { path, value } = input;
    const updatedSettings = { ...recruitingSettings };
    set(updatedSettings, path, value);

    setRecruitingSettings(updatedSettings);

    try {
      const updatedCompany = await MiterAPI.companies.update(activeCompanyId, {
        $set: { "settings.ats": updatedSettings },
      });
      setActiveCompany(updatedCompany);
      Notifier.success("Settings updated successfully!");
    } catch (error: $TSFixMe) {
      Notifier.error(`Failed to update settings: ${error.message}`);
    }
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Recruiting settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={() => navigate("/recruiting/job-postings")}>
          Back to recruiting
        </div>
        <div className="flex">
          <h1>Recruiting settings</h1>
          <div className="flex-1"></div>
        </div>
        <SettingsCard title={"Notifications"}>
          <div className={styles["settings-card-content"]}>
            <div className="flex" style={{ alignItems: "flex-start" }}>
              <input
                type="checkbox"
                name="notifications.new_application.hiring_manager"
                checked={recruitingSettings?.notifications?.new_application?.hiring_manager}
                onChange={(e) =>
                  handleChange({
                    path: "notifications.new_application.hiring_manager",
                    value: !!e.target.checked,
                  })
                }
              />
              <span style={{ marginLeft: 10 }}>
                <strong>Hiring manager: </strong>
                {`should receive a notification for each new job application.`}
              </span>
            </div>
            <div className="flex" style={{ alignItems: "flex-start", marginTop: 8 }}>
              <input
                type="checkbox"
                name="notifications.new_application.hiring_team"
                checked={recruitingSettings?.notifications?.new_application?.hiring_team}
                onChange={(e) =>
                  handleChange({
                    path: "notifications.new_application.hiring_team",
                    value: !!e.target.checked,
                  })
                }
              />
              <span style={{ marginLeft: 10 }}>
                <strong>Hiring team: </strong>
                {`should receive a notification for each new job application.`}
              </span>
            </div>
          </div>
        </SettingsCard>
        <SettingsCard title={"Job boards"}>
          <div className={styles["settings-card-content"]}>
            <div className="flex" style={{ alignItems: "flex-start" }}>
              <input
                type="checkbox"
                name="job_boards.indeed.enabled"
                checked={recruitingSettings?.job_boards?.indeed?.enabled}
                onChange={(e) =>
                  handleChange({
                    path: "job_boards.indeed.enabled",
                    value: !!e.target.checked,
                  })
                }
              />
              <span className={styles["job-board-section"]}>
                Sync recruiting jobs from Miter to <b style={{ marginLeft: 4, marginRight: 4 }}>Indeed.</b>
                <InfoButton
                  text={
                    "Automatically post open positions on Indeed and have candidates apply directly through Indeed."
                  }
                  place={"right"}
                />
              </span>
            </div>

            <div style={{ marginTop: 8 }}>
              <div className="flex" style={{ alignItems: "flex-start" }}>
                <input
                  type="checkbox"
                  name="job_boards.linkedin.enabled"
                  checked={recruitingSettings?.job_boards?.linkedin?.enabled}
                  onChange={(e) =>
                    handleChange({
                      path: "job_boards.linkedin.enabled",
                      value: !!e.target.checked,
                    })
                  }
                />

                <span className={styles["job-board-section"]}>
                  Sync recruiting jobs from Miter to{" "}
                  <b style={{ marginLeft: 4, marginRight: 4 }}> LinkedIn. </b>
                  <InfoButton
                    text={
                      "Sync your jobs in Miter with your LinkedIn company page to automatically post open positions."
                    }
                    place={"right"}
                  />
                </span>
              </div>
              {recruitingSettings?.job_boards?.linkedin?.enabled && (
                <div style={{ marginTop: 8, marginLeft: 30 }}>
                  <Link
                    style={{ marginBottom: 8 }}
                    onClick={() => goToMiterGuide(`/hr/introduction-1/job-postings#linkedin-job-board`)}
                  >
                    {"Learn how to provide Miter with your LinkedIn ID"}
                  </Link>
                  <Formblock
                    style={{ width: 300 }}
                    type="text"
                    name="job_boards.linkedin.company_id"
                    defaultValue={recruitingSettings?.job_boards?.linkedin?.company_id}
                    editing={true}
                    onChange={(e) => {
                      handleChange({
                        path: "job_boards.linkedin.company_id",
                        value: e.target.value,
                      });
                      trigger();
                    }}
                    register={form.register(vals.isValidLinkedInID)}
                    form={form}
                    label="LinkedIn ID"
                    className="modal"
                  />
                </div>
              )}
            </div>
          </div>
        </SettingsCard>
        <SettingsCard title={"Duplicate applications"}>
          <div className="flex" style={{ alignItems: "flex-start" }}>
            <input
              type="checkbox"
              name="prevent_duplicate_applications.enabled"
              checked={recruitingSettings?.prevent_duplicate_applications?.enabled}
              onChange={(e) =>
                handleChange({
                  path: "prevent_duplicate_applications.enabled",
                  value: !!e.target.checked,
                })
              }
            />
            <span style={{ marginLeft: 10 }}>
              {`Prevent duplicate applications from the same candidate for the same job posting.`}
            </span>
          </div>

          {recruitingSettings?.prevent_duplicate_applications?.enabled && (
            <div className={styles["first-card-element"]}>
              <Formblock
                style={{ width: 300 }}
                type="number"
                name="prevent_duplicate_applications.days_before_applying"
                defaultValue={recruitingSettings?.prevent_duplicate_applications?.days_before_applying}
                editing={true}
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  if (isNaN(newValue) || !Number.isInteger(newValue) || newValue < 0) {
                    Notifier.error("Please enter a valid positive whole number.");
                    return;
                  }
                  handleChange({
                    path: "prevent_duplicate_applications.days_before_applying",
                    value: newValue,
                  });
                }}
                labelStyle={{ width: 500 }}
                form={form}
                label="Days before a candidate can re-apply to the same job posting"
                className="modal"
              />
            </div>
          )}
        </SettingsCard>
        <SettingsCard title={renderCareerPageTitle()}>
          <div className={"flex" + " " + styles["settings-card-content"]} style={{ marginBottom: "10px" }}>
            Embed your career page into your company&apos;s website to display all open roles.
            <InfoButton
              text={
                "Share this snippet with your website manager to display open positions and accept applications directly from your company website!"
              }
              place={"right"}
            />
          </div>
          <div className="flex" style={{ alignItems: "flex-start" }}>
            <Button onClick={copyIframeURL} className="button-1" style={{ marginLeft: 2, height: 31 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CodeSimple size={16} style={{ marginRight: 5 }}></CodeSimple>Copy snippet
              </div>
            </Button>
            <div style={{ marginLeft: 8 }}>
              <Formblock
                style={{ minWidth: 500 }}
                type="text"
                defaultValue={iframeSnippet}
                editing={true}
                disabled={true}
                form={form}
              />
            </div>
          </div>
        </SettingsCard>
        {hasAccessToCollectWorkExperienceAndEducation ? (
          <SettingsCard title={"Job application settings"}>
            <div className={styles["data-collection-checkbox-container"]} style={{ marginTop: 0 }}>
              <input
                type="checkbox"
                name="collect_eeo"
                checked={recruitingSettings?.collect_eeo}
                onChange={(e) =>
                  handleChange({
                    path: "collect_eeo",
                    value: !!e.target.checked,
                  })
                }
              />
              <span className={styles["data-collection-checkbox-text"]}>
                {`Collect voluntary demographic (EEO/OFCCP) information.`}
              </span>
            </div>
            <div className={styles["data-collection-checkbox-container"]}>
              <input
                type="checkbox"
                name="collect_work_experience"
                checked={recruitingSettings?.collect_work_experience}
                onChange={(e) =>
                  handleChange({
                    path: "collect_work_experience",
                    value: !!e.target.checked,
                  })
                }
              />
              <span
                className={styles["data-collection-checkbox-text"]}
              >{`Collect applicant's work history.`}</span>
            </div>
            <div className={styles["data-collection-checkbox-container"]}>
              <input
                type="checkbox"
                name="collect_education"
                checked={recruitingSettings?.collect_education}
                onChange={(e) =>
                  handleChange({
                    path: "collect_education",
                    value: !!e.target.checked,
                  })
                }
              />
              <span
                className={styles["data-collection-checkbox-text"]}
              >{`Collect applicant's educational background.`}</span>
            </div>
          </SettingsCard>
        ) : (
          <SettingsCard title={"Collect demographic information (EEO/OFCCP)"}>
            <div className="flex" style={{ alignItems: "flex-start" }}>
              <input
                type="checkbox"
                name="collect_eeo"
                checked={recruitingSettings?.collect_eeo}
                onChange={(e) =>
                  handleChange({
                    path: "collect_eeo",
                    value: !!e.target.checked,
                  })
                }
              />
              <span style={{ marginLeft: 10 }}>
                {`Collect demographic information from candidates when they apply to job postings.`}
              </span>
            </div>
          </SettingsCard>
        )}
        {
          <SettingsCard
            title="SMS message templates"
            info="Create reusable templates to simplify sending detailed SMS messages to candidates."
          >
            <div className={styles["message-templates-table"]}>
              <MessageTemplatesTable />
            </div>
          </SettingsCard>
        }
        {
          <SettingsCard
            title="Rejection reasons"
            info="Also known as dispositions, rejection reasons help you assess and report on your hiring practices."
          >
            <div className={styles["message-templates-table"]}>
              <RejectionReasonsTable />
            </div>
          </SettingsCard>
        }
        <SettingsCard
          title="Form templates"
          info="Create reusable templates to simplify the process of collecting information from candidates."
        >
          <div className={styles["message-templates-table"]}>
            <FormTemplatesTable />
          </div>
        </SettingsCard>
      </div>
    </div>
  );
};
