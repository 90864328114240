import { useActiveAccount, useActiveCompany, useActiveTeamMember, useUser } from "dashboard/hooks/atom-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

type HelpScoutBeacon = {
  toggleBeacon: () => void;
  isBeaconOpen: boolean;
};

export const useHelpScoutBeacon = (): HelpScoutBeacon => {
  const [beaconScriptLoaded, setBeaconScriptLoaded] = useState<boolean>(false);
  const [isBeaconOpen, setIsBeaconOpen] = useState<boolean>(false);

  const activeUser = useUser();
  const activeAccount = useActiveAccount();
  const activeCompany = useActiveCompany();
  const activeTeamMember = useActiveTeamMember();

  // Load the Beacon script
  useEffect(() => {
    if (!window.Beacon) {
      (function (e: $TSFixMe, t, n) {
        function a() {
          const e = t.getElementsByTagName("script")[0]!;
          n = t.createElement("script");
          n.type = "text/javascript";
          n.async = true;
          n.src = "https://beacon-v2.helpscout.net";
          e.parentNode!.insertBefore(n, e);
        }
        if (
          ((e.Beacon = n =
            function (t, n, a) {
              e.Beacon.readyQueue.push({ method: t, options: n, data: a });
            }),
          (n.readyQueue = []),
          t.readyState === "complete")
        )
          return a();
        e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, false);
      })(window, document, window.Beacon || function () {});

      window.Beacon("config", { display: { style: "manual" } });
      window.Beacon("init", "56e6f672-19ba-4619-956e-eae0d5b65e30");

      setBeaconScriptLoaded(true);
    }
  }, []);

  // Identify the user
  useEffect(() => {
    if (!beaconScriptLoaded || !window.Beacon) return;

    window.Beacon("identify", {
      email: activeUser?.email,
      name: activeAccount?.full_name,
      company: activeCompany?.check_company?.trade_name,
      jobTitle: activeTeamMember?.title,
    });
  }, [activeAccount, activeCompany, activeTeamMember, activeUser, beaconScriptLoaded]);

  const toggleBeacon = useCallback(() => {
    window.Beacon("toggle");
    setIsBeaconOpen((prev) => !prev);
  }, []);

  return useMemo(() => ({ toggleBeacon, isBeaconOpen }), [toggleBeacon, isBeaconOpen]);
};
