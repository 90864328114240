import { useForm } from "react-hook-form";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { DeepPartial } from "utility-types";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { IntegrationConnection } from "dashboard/miter";
import { HCSSConnectionMetadata } from "backend/services/hcss/hcss-types";
import { Formblock, SettingsCard } from "ui";

export const HcssConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();

  const { limitToIsAccepted } = integration.connection?.metadata?.hcss?.timesheets || {};

  const updateLimitToIsAccepted = async (value: boolean) => {
    const update = {
      timesheets: { limitToIsAccepted: value },
    };

    await updateHcssMetadata(update);
  };

  const updateHcssMetadata = async (
    update: DeepPartial<HCSSConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => {
    const raw: DeepPartial<IntegrationConnection> = { metadata: { hcss: update } };
    const collapseCount = opts?.collapseCount;
    const flattened = buildAtomicMongoUpdateFromNested(raw, {
      collapseCount: collapseCount != null ? collapseCount + 2 : undefined,
    });

    await updateIntegrationConnection(flattened);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Timesheets">
        <Formblock
          form={form}
          name={"limitToIsAccepted"}
          checked={!!limitToIsAccepted}
          editing={true}
          checkboxWrapperStyle={{ alignItems: "flex-start" }}
          text="Limit timesheet syncs to only include HCSS TimeCards that have been 'accepted' in HCSS."
          type="checkbox"
          onChange={(e) => updateLimitToIsAccepted(e.target.checked)}
        />
      </SettingsCard>
    </div>
  );
};
