import React, { useState, useMemo, useEffect } from "react";

import { MiterAPI, AllocationRuleGroup } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { BasicModal } from "ui";

import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { Plus, TrashSimple } from "phosphor-react";
import {
  useActiveCompanyId,
  useRefetchAllocationRuleGroups,
  useAllocationRuleGroups,
} from "dashboard/hooks/atom-hooks";
import { useNavigate } from "react-router-dom";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import AllocationRuleGroupModal from "./AllocationRuleGroupModal";
import { useQuery } from "miter-utils";
import { useAllocationRuleGroupItems } from "./useAllocationRuleGroupItems";

type AllocationRuleGroupRow = AllocationRuleGroup;

type Props = {
  type: AllocationRuleGroup["type"];
  path: string;
};

const AllocationRuleGroupsTable: React.FC<Props> = ({ type, path }) => {
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const query = useQuery();
  const argId = query.get("argId");

  const [loading, setLoading] = useState(false);
  const activeCompanyId = useActiveCompanyId();
  const { setFailures, renderFailuresModal } = useFailuresModal({ onClose: () => setFailures([]) });

  const navigate = useNavigate();
  const refetchAllocationRuleGroups = useRefetchAllocationRuleGroups();
  const allocationRuleGroups = useAllocationRuleGroups().filter((group) => group.type === type);
  const { getAssociatedItems } = useAllocationRuleGroupItems(type);

  const tableData = useMemo(() => {
    return allocationRuleGroups.map((group) => ({
      ...group,
      associated_items_count: getAssociatedItems(group._id).length,
    }));
  }, [allocationRuleGroups, getAssociatedItems]);

  // States related to the table
  const [selectedRows, setSelectedRows] = useState<AllocationRuleGroupRow[]>([]);

  // States related to table actions
  const [adding, setAdding] = useState(false);
  const [archiving, setArchiving] = useState(false);

  useEffect(() => {
    refetchAllocationRuleGroups();
  }, [activeCompanyId, refetchAllocationRuleGroups]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/

  const handleAdd = () => {
    setAdding(true);
  };

  const handleArchive = async () => {
    setLoading(true);
    try {
      const archivedGroups: AllocationRuleGroup[] = [];
      const failures: AllocationRuleGroup[] = [];
      await Promise.all(
        selectedRows.map(async (allocationRule_group) => {
          const response = await MiterAPI.allocation_rule_groups.update(allocationRule_group._id, {
            archived: true,
          });

          if (response.error) {
            failures.push(allocationRule_group);
          } else {
            archivedGroups.push(allocationRule_group);
          }
        })
      );

      if (failures.length) {
        setFailures(failures.map((group) => ({ label: group.name, message: "Failed to delete group" })));
      }

      refetchAllocationRuleGroups();
      setArchiving(false);
      setSelectedRows([]);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error deleting one or more groups. We're looking into it.");
    }
    setLoading(false);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add group",
        className: "button-2 no-margin",
        action: handleAdd,
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ],
    []
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiving(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
      },
    ],
    []
  );

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id={"allocation-rule-groups"}
        resource="allocation rule groups"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        rowLinkBuilder={(row) => `${path}?argId=${row?._id || ""}`}
        hideFooter={true}
        containerStyle={{ paddingBottom: 0 }}
      />
    );
  };

  return (
    <div className="allocationRule-groups-table-wrapper">
      {renderFailuresModal()}
      {adding && (
        <AllocationRuleGroupModal
          type={type}
          onHide={() => setAdding(false)}
          onFinish={() => navigate(path)}
        />
      )}
      {argId && (
        <AllocationRuleGroupModal
          type={type}
          ruleGroupId={argId}
          onHide={() => navigate(path)}
          onFinish={() => navigate(path)}
        />
      )}
      {archiving && (
        <BasicModal
          loading={loading}
          button2Text="Delete"
          button1Action={() => setArchiving(false)}
          button1Text="Cancel"
          button2Action={handleArchive}
          headerText={"Delete group" + (selectedRows.length > 1 ? "s" : "")}
          bodyText={
            "Are you sure you want to delete the selected group" +
            (selectedRows.length > 1 ? "s" : "") +
            "? To restore a deleted group, you must contact Miter Support."
          }
        />
      )}

      {renderTable()}
    </div>
  );
};

export default AllocationRuleGroupsTable;

const columns: ColumnConfig<AllocationRuleGroupRow>[] = [
  {
    headerName: "Name",
    field: "name",
    dataType: "string",
  },
  {
    headerName: "Associated items",
    field: "associated_items_count",
    dataType: "number",
  },
];
