import { MiterAPI } from "dashboard/miter";
import { MITER_COMPANY_ID, DEMO_COMPANY_ID } from "dashboard/utils";
import { Option } from "packages/ui/form/Input";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useCompanyUserOptions, usePermissionGroups, useActiveCompanyId } from "./atom-hooks";

export type useSuperAdmins = {
  adminOptions: Option<string>[];
  adminOptionsLoading: boolean;
  lookupAdminOption: (value: string) => Option<string> | undefined;
};

export const useSuperAdmins = (): useSuperAdmins => {
  const [adminOptions, setAdminOptions] = useState<Option<string>[]>([]);
  const [adminOptionsLoading, setAdminOptionsLoading] = useState(true);
  const [adminOptionsMap, setAdminOptionsMap] = useState<Map<string, Option<string>>>(new Map());
  const userOptions = useCompanyUserOptions();
  const companyId = useActiveCompanyId();
  const permissionGroups = usePermissionGroups();

  useEffect(() => {
    // This is intended to filter out miter admins from non-miter companies
    const isExternalMiterAdmin = (email: string | undefined | null): boolean => {
      // NOTE(sega): This is very not ideal but if we don't do this only in production we will filter
      // out all our accounts from our many dev/staging accounts, the alternative is to hard code every
      // companyId here which will not be sustainable.
      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        // If no email is provided we can't determine if it is an external miter admin
        if (email && companyId !== MITER_COMPANY_ID && companyId !== DEMO_COMPANY_ID) {
          if (email.endsWith("@miter.com") || email.endsWith("@miterhr.com")) {
            return true;
          }
        }
      }
      return false;
    };

    const getAdminOptions = async () => {
      try {
        const superAdminPermissionGroup = permissionGroups.filter((group) => group.super_admin)[0];
        if (!superAdminPermissionGroup) return [];
        const { roles, teamMembers } = await MiterAPI.permission_groups.retrieve_members(
          superAdminPermissionGroup._id
        );
        const rolesUserIds = roles.flatMap((role) => (isExternalMiterAdmin(role.email) ? [] : [role.user]));
        const teamMembersUserIds = teamMembers.flatMap((teamMember) =>
          isExternalMiterAdmin(teamMember.email) ? [] : [teamMember.user]
        );
        const filteredOptions = userOptions.filter(
          (user) => rolesUserIds.includes(user.value) || teamMembersUserIds.includes(user.value)
        );
        setAdminOptions(filteredOptions);

        // Create a map for O(1) lookup
        const optionsMap = new Map<string, Option<string>>();
        filteredOptions.forEach((option) => optionsMap.set(option.value, option));
        setAdminOptionsMap(optionsMap);
      } catch (e) {
        console.error("Error fetching super admin members");
      } finally {
        setAdminOptionsLoading(false);
      }
    };

    getAdminOptions();
  }, [companyId, permissionGroups, userOptions]);

  const lookupAdminOption = useCallback(
    (value: string): Option<string> | undefined => {
      return adminOptionsMap.get(value);
    },
    [adminOptionsMap]
  );

  return useMemo(
    () => ({
      adminOptions,
      adminOptionsLoading,
      lookupAdminOption,
    }),
    [adminOptions, adminOptionsLoading, lookupAdminOption]
  );
};
