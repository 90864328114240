import React, { FC } from "react";
import { Formblock } from "ui";
import { FieldRequirement } from "dashboard/miter";
import { set } from "lodash";
import { useForm } from "react-hook-form";
import { CompanySettings } from "backend/models/company";
import InfoButton from "../information/information";
import { FieldProps } from "./TimesheetDashboardFieldRequirements";
import { convertFieldRequirementToBoolean } from "dashboard/utils";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";
import { useOptionalDimensionsUsage } from "dashboard/hooks/dimensions-gating";

type Settings = CompanySettings["timesheets"];

export type Props = {
  settings: Settings;
  handleChange: (newFields: Partial<Settings>) => Promise<void>;
};

const options = [
  { label: "Required", value: "required" },
  { label: "Optional", value: "optional" },
  { label: "Hidden", value: "hidden" },
];

const mapNewFieldToOld = (
  newField: string,
  newVal: FieldRequirement
): { oldField: string; val: string | boolean } | null => {
  switch (newField) {
    case "job":
      return {
        oldField: "require_job_on_mobile",
        val: convertFieldRequirementToBoolean(newVal),
      };
    case "activity":
      return {
        oldField: "require_activity_on_mobile",
        val: convertFieldRequirementToBoolean(newVal),
      };
    case "clock_in_photo":
      return {
        oldField: "require_photo_id",
        val: convertFieldRequirementToBoolean(newVal),
      };
    case "clock_out_photo":
      return {
        oldField: "require_clockout_photo_id",
        val: convertFieldRequirementToBoolean(newVal),
      };
    case "classification":
      return {
        oldField: "classification_overrides",
        val: newVal === "hidden" ? "always_disabled" : "always_enabled",
      };
    case "injury_form":
      return {
        oldField: "hide_injury_form",
        val: newVal === "hidden" ? true : false,
      };
    default:
      return null;
  }
};

export const TimesheetMobileFieldRequirements: FC<Props> = ({ settings, handleChange }) => {
  const form = useForm();
  const { control } = form;
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const { hasCostTypes } = useOptionalDimensionsUsage();

  const fields: FieldProps[] = [
    {
      name: "clock_in_photo",
      label: "Clock in photo",
      value: settings.field_requirements.mobile.clock_in_photo,
      description: "A selfie before clocking into a timesheet.",
    },
    {
      name: "clock_out_photo",
      label: "Clock out photo",
      value: settings.field_requirements.mobile.clock_out_photo,
      description: "A selfie before clocking out of a timesheet.",
    },
    {
      name: "job",
      label: "Job",
      value: settings.field_requirements.mobile.job,
    },
    {
      name: "activity",
      label: "Activity",
      value: settings.field_requirements.mobile.activity,
    },
    {
      name: "classification",
      label: "Classification",
      value: settings.field_requirements.mobile.classification,
      description: "Override the default classification / pay rate on any timesheet.",
    },
    {
      name: "notes",
      label: "Notes",
      value: settings.field_requirements.mobile.notes,
    },
    {
      name: "injury_form",
      label: "Injury form",
      value: settings.field_requirements.mobile.injury_form,
      description: "A yes/no form asking whether the employee was injured during the shift.",
    },
  ];

  if (hasCostTypes) {
    fields.push({
      name: "cost_type_id",
      label: "Cost type",
      value: settings.field_requirements.mobile.cost_type_id,
    });
  }

  if (hasAccessToEquipmentTracking) {
    fields.push({
      name: "equipment_ids",
      label: "Equipment",
      value: settings.field_requirements.mobile.equipment_ids,
    });
  }

  return (
    <div>
      {fields.map((field) => {
        const { name, label, value, description } = field;

        const update = async (requirement: { label: string; value: FieldRequirement }) => {
          const updatedSettings = { ...settings };
          set(updatedSettings, `field_requirements.mobile.${name}`, requirement.value);
          const oldMapping = mapNewFieldToOld(name, requirement.value);
          if (oldMapping) {
            set(updatedSettings, oldMapping.oldField, oldMapping.val);
          }
          handleChange(updatedSettings);
        };

        return (
          <div style={{ display: "flex", minWidth: 600, marginBottom: 10 }}>
            <div style={{ width: 150, display: "flex" }}>
              {label}
              {description && (
                <div style={{ marginTop: 5 }}>
                  <InfoButton text={description} />
                </div>
              )}
            </div>

            <Formblock
              name={name}
              type="select"
              options={options}
              defaultValue={value}
              onChange={update}
              editing={true}
              control={control}
              style={{ width: 200, marginTop: -5 }}
            />
          </div>
        );
      })}
    </div>
  );
};
