// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BasicModal, Notifier, TableV2 } from "ui";
import { ColGroupDef } from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import { AggregatedJobApplication, Candidate, JobPosting } from "dashboard/types/ats";
import { useActiveCompanyId, useLookupFormTemplate } from "dashboard/hooks/atom-hooks";
import { BulkUpdateResult, Form, MiterAPI } from "dashboard/miter";
import { ValueFormatterParams } from "ag-grid-community";
import { ArrowSquareRight, ChatCircleText, TrashSimple } from "phosphor-react";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useFormColumns } from "dashboard/hooks/table/useFormColumns";
import { FormAnswer, useQuery } from "miter-utils";
import { ESignatureAnswerModal } from "../forms/FormSubmissionAnswersTable";
import { useJobApplicationAbilities } from "dashboard/hooks/recruiting/useJobApplicationAbilities";
import {
  APPLICATION_STATUS_VALUE_LABEL,
  CANDIDATE_STATUSES,
  APPLICATION_STATUS_COLOR,
} from "dashboard/utils/ats";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { useOfferLetterAbilities } from "dashboard/hooks/abilities-hooks/useOfferLetterAbilities";
import Banner from "../shared/Banner";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import { DuplicateCandidatesPopover } from "./DuplicateCandidatesPopover";
import { FaCircle } from "react-icons/fa";
import { useHasAccessToBulkSendMessages } from "dashboard/gating";
import { useJobApplicationEEOColumns } from "dashboard/hooks/recruiting/useJobApplicationColumns";
import BulkSendMessagesModal from "./modals/BulkSendMessagesModal";

export const CURRENT_JOB_TITLE_QUESTION_ID = "651c4e8a5233bb79425332d5";
type Props = {
  jobPosting?: JobPosting;
  candidate?: Candidate;
  secondary?: boolean;
};

export const JobApplicationsTable: React.FC<Props> = ({ jobPosting, candidate, secondary }) => {
  const navigate = useNavigate();
  const query = useQuery();
  const status = query.get("status");
  const [selectedRows, setSelectedRows] = useState<AggregatedJobApplication[]>([]);
  const activeCompanyId = useActiveCompanyId();
  const [deleteModal, setDeleteModal] = useState(false);
  const [bulkSendMessagesModalOpen, setBulkSendMessagesModalOpen] = useState(false);
  const { can, cannot } = useMiterAbilities();
  const jobApplicationAbilities = useJobApplicationAbilities();
  const [form, setForm] = useState<Form>();
  const [data, setData] = useState<AggregatedJobApplication[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<FormAnswer>();
  const { setFailures, renderFailuresModal } = useFailuresModal();
  const { formColumns } = useFormColumns({
    form,
    setSelectedAnswer: setSelectedAnswer as React.Dispatch<React.SetStateAction<FormAnswer>>,
    pathToAnswers: "response_submission.answers",
  });
  const hasAccessToOfferLetters = useOfferLetterAbilities();
  const hasAccessToBulkSendMessages = useHasAccessToBulkSendMessages();
  const eeoColumns = useJobApplicationEEOColumns({ jobPosting });
  const lookupFormTemplate = useLookupFormTemplate();

  const getForm = useCallback(async () => {
    if (!jobPosting) return;
    try {
      const formTemplate = lookupFormTemplate(jobPosting.question_form_template_id);
      const formId = formTemplate?.form_id ?? jobPosting.question_form_id;
      const res = await MiterAPI.forms.retrieve(formId);
      if (res.error) throw new Error(res.error);
      const { submissions, ...rest } = res;
      setForm(rest);
    } catch (e) {
      Notifier.error("Failed to get job application questions.");
    }
  }, [lookupFormTemplate, jobPosting]);

  useEffect(() => {
    getForm();
  }, [getForm, jobPosting]);

  const getData = useCallback(async () => {
    const scopesFilter = jobApplicationAbilities.filter("read");

    const filter = [
      { field: "company_id", value: activeCompanyId },
      ...(jobPosting ? [{ field: "job_posting_id", value: jobPosting._id }] : []),
      ...(candidate ? [{ field: "candidate_id", value: candidate._id }] : []),
      ...(scopesFilter ? [scopesFilter] : []),
    ];
    const res = await MiterAPI.job_applications.forage({
      filter,
      select: undefined,
      limit: 1000,
      sort: [
        {
          field: "applied_on",
          direction: -1,
        },
      ],
    });

    setData(res.data);
  }, [jobApplicationAbilities, activeCompanyId, jobPosting, candidate]);

  useEffect(() => {
    getData();
  }, [getData]);

  const closeBulkSendMessagesModal = () => {
    setBulkSendMessagesModalOpen(false);
    setSelectedRows([]);
  };

  const togglerConfig = useMemo(() => {
    return {
      config: [
        { path: "applied", label: "Applied" },
        { path: "in_review", label: "In review" },
        { path: "interviewing", label: "Interviewing" },
        { path: "offer", label: "Offer" },
        { path: "hired", label: "Hired" },
        { path: "rejected", label: "Rejected" },
        { path: "all", label: "All" },
      ],
      path: "applied",
      secondary: secondary,
      field: "status",
    };
  }, [secondary]);

  const columns: ColGroupDef<AggregatedJobApplication>[] = useMemo(() => {
    // We already show this in the current job title column
    const castedFormColumns = [
      {
        headerName: "Responses",
        children: formColumns.filter((col) => col.field !== CURRENT_JOB_TITLE_QUESTION_ID),
      },
    ] as ColGroupDef<AggregatedJobApplication>[];

    const statusOptions = CANDIDATE_STATUSES.filter(
      (applicationStatus) =>
        applicationStatus !== status &&
        !(applicationStatus === "hired" && jobApplicationAbilities.cannot("hire", undefined))
    ).map((status) => ({
      value: status,
      label: APPLICATION_STATUS_VALUE_LABEL[status],
    }));

    return (
      [
        ...(!candidate
          ? [
              {
                headerName: "Candidate",
                children: [
                  {
                    headerName: "Name",
                    valueFormatter: (params: ValueFormatterParams<AggregatedJobApplication>) => {
                      const { first_name, last_name } = params?.data?.candidate || {
                        first_name: "Unknown",
                        last_name: "Candidate",
                      };
                      return `${first_name} ${last_name}`;
                    },
                    minWidth: 200,
                    pinned: "left" as const,
                    tooltipValueGetter: (params) => params.data?.duplicate_candidates?.length,
                    tooltipComponent: (params) => {
                      return params.data?.duplicate_candidates?.length ? (
                        <DuplicateCandidatesPopover candidates={params.data?.duplicate_candidates} />
                      ) : null;
                    },
                    cellRenderer: (params) => {
                      const fullName = `${params.data?.candidate?.first_name} ${params.data?.candidate?.last_name}`;
                      const duplicateCount = params.data?.duplicate_candidates?.length;
                      return (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <p style={{ marginLeft: 4 }}>{fullName}</p>
                          {duplicateCount ? (
                            <FaCircle size={8} style={{ marginLeft: 8, marginBottom: 0, color: "#E70000" }} />
                          ) : null}
                        </div>
                      );
                    },
                  },
                ],
              },
            ]
          : []),
        ...(!jobPosting
          ? [
              {
                headerName: "Job posting",
                children: [
                  {
                    headerName: "Title",
                    field: "job_posting.title",
                    pinned: "left" as const,
                  },
                ],
              },
            ]
          : []),
        {
          headerName: "Application",
          children: [
            ...(!candidate
              ? [
                  {
                    headerName: "Job title",
                    field: "current_job_title",
                    dataType: "string" as const,
                  },
                ]
              : []),

            {
              headerName: "Applied on",
              field: "applied_on",
              dataType: "date" as const,
              dateType: "timestamp" as const,
            },

            {
              headerName: "Resume",
              dataType: "boolean" as const,
              valueGetter: (params) => !!params.data?.resume_id,
              width: 50,
            },
            {
              headerName: "Source",
              dataType: "string" as const,
              field: "source",
              displayType: "badge" as const,
              colors: {
                indeed: "light-blue",
                linkedin: "blue",
                careers_page: "light-purple",
                bulk_import: "gray",
              },
            },
            {
              headerName: "Status",
              dataType: "string" as const,
              field: "status",
              displayType: "badge",
              colors: APPLICATION_STATUS_COLOR,
              cellEditorParams: {
                options: statusOptions,
              },
              editable: true,
              editorType: "select",
            },
            ...(hasAccessToOfferLetters
              ? [
                  {
                    headerName: "Offer sent",
                    dataType: "boolean" as const,
                    valueGetter: (params) => !!params.data?.offer_letter,
                    width: 50,
                  },
                  {
                    headerName: "Offer signed",
                    dataType: "boolean" as const,
                    valueGetter: (params) => params.data?.offer_letter?.status === "signed",
                    width: 50,
                  },
                ]
              : []),
          ],
        },
      ]
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - TS doesn't like the fact that we're using a custom displayType
        .concat(castedFormColumns)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - TS doesn't like the fact that we're using a custom displayType
        .concat(eeoColumns) as ColGroupDef<AggregatedJobApplication>[]
    );
  }, [
    formColumns,
    candidate,
    jobPosting,
    status,
    eeoColumns,
    hasAccessToOfferLetters,
    jobApplicationAbilities,
  ]);

  const handleRowClick = (jobApplication) => {
    navigate(`/recruiting/job-applications/${jobApplication._id}${candidate ? `?path=candidate` : ""}`);
  };

  const dynamicActions = useMemo(
    () => [
      ...(hasAccessToBulkSendMessages
        ? [
            {
              label: "Bulk message",
              className: "button-2 table-button",
              action: () => setBulkSendMessagesModalOpen(true),
              icon: <ChatCircleText size={16} weight="bold" style={{ marginRight: 3 }} />,
            },
          ]
        : []),
      {
        label: "Delete",
        className: "button-3 table-button",
        action: () => setDeleteModal(true),
        icon: <TrashSimple size={16} weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("recruiting:job_applications:delete"),
      },
    ],
    [setDeleteModal, can, hasAccessToBulkSendMessages]
  );

  const refreshPage = () => {
    setDeleteModal(false);
    setSelectedRows([]);
    getData();
  };

  const updateJobs = async (
    applicationParamsArray: AggregatedJobApplication[]
  ): Promise<BulkUpdateResult> => {
    try {
      const params = applicationParamsArray.map((application) => ({
        _id: application._id,
        params: { status: application.status },
      }));
      const res = await MiterAPI.job_applications.update(params);
      if (res.errors.length) {
        setFailures(
          res.errors.map((error) => ({
            label: error.label || "Candidate",
            message: error.message,
          }))
        );
      }

      refreshPage();
      return res;
    } catch (e: $TSFixMe) {
      Notifier.error("There was a problem updating the job applications.");
      return { successes: [], errors: [] };
    }
  };

  const archiveJobApplications = async () => {
    if (selectedRows.length === 0 || !activeCompanyId || cannot("recruiting:job_applications:delete")) return;
    try {
      const jobApplications = selectedRows.map((row) => row._id);
      const res = await MiterAPI.job_applications.archive({
        ids: jobApplications,
        company_id: activeCompanyId,
      });
      if (res.failures.length || res.error) throw new Error(res.error + res.failures.join(", "));
      Notifier.success("Successfully deleted job applications.");
    } catch (e) {
      Notifier.error("Failed to delete job applications.");
    }
    refreshPage();
  };

  /*********************************************************
    Functions to render table components
  **********************************************************/

  return (
    <div>
      {hasAccessToOfferLetters ? (
        <Banner
          type="info"
          onClick={() => goToMiterGuide("/hr/introduction-1/offers")}
          content="Click here to learn more about creating and sending offer letters!"
          style={{ marginTop: 20 }}
        />
      ) : null}
      <TableV2
        id={"job-applications-table"}
        resource="job applications"
        columns={columns}
        dynamicActions={dynamicActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={handleRowClick}
        toggler={togglerConfig}
        data={data}
        editable={status !== "hired" && can("recruiting:job_applications:update")}
        onSave={updateJobs}
        editableLabel="Update status"
        editableIcon={<ArrowSquareRight size={18} style={{ marginRight: 3 }} />}
      />
      {deleteModal ? (
        <BasicModal
          headerText={"Are you sure?"}
          bodyText={"Are you sure about permanently deleting the selected job applications?"}
          button1Text={"Cancel"}
          button2Text={"Delete"}
          button1Action={() => setDeleteModal(false)}
          button2Action={archiveJobApplications}
          button2ClassName={"button-3"}
        />
      ) : null}
      {bulkSendMessagesModalOpen ? (
        <BulkSendMessagesModal
          onClose={closeBulkSendMessagesModal}
          jobApplications={selectedRows}
          setFailures={setFailures}
        />
      ) : null}

      {selectedAnswer && (
        <ESignatureAnswerModal answer={selectedAnswer} onHide={() => setSelectedAnswer(undefined)} />
      )}
      {renderFailuresModal()}
    </div>
  );
};
