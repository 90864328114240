import Select, { ValueType } from "react-select";
import { ActionModal, Formblock } from "ui";
import { usePrgOptions } from "dashboard/hooks/atom-hooks";
import { useForm } from "react-hook-form";
import { styles } from "ui/form/styles";
import { Option } from "ui/form/Input";
import groupStyles from "../../settings/payrollSettings/payRateGroups/payRateGroupPage/PayRateGroupModal.module.css";
import { useMemo, useState } from "react";
import { AggregatedJob, Job, Trade } from "dashboard/miter";
import { useTrades } from "dashboard/hooks/useTrades";
import { usePrgOptionsByTradeMap } from "dashboard/hooks/usePrgOptionsByTradeMap";

type Props = {
  data: AggregatedJob;
  updateJob: (params: Partial<Job>) => Promise<void>;
  updatingJob: boolean;
  hide: () => void;
};

export const JobPayRateGroupsForm: React.FC<Props> = (props) => {
  const { data: jobData, updateJob, updatingJob, hide } = props;
  const { register, errors, handleSubmit, control, watch } = useForm({
    defaultValues: { enable_trades: !!jobData.trades },
  });
  const formData = watch();

  const allPrgOptions = usePrgOptions();

  const { trades } = useTrades();

  const [newMapping, setNewMapping] = useState(jobData.trades || {});

  const prgOptionsByTradeMap = usePrgOptionsByTradeMap();

  const relevantTrades = useMemo(() => {
    return trades?.filter((trade) => !!prgOptionsByTradeMap[trade._id]);
  }, [trades, prgOptionsByTradeMap]);

  const handleSelectInputChange = (trade: Trade, option: Option<string> | null | undefined) => {
    setNewMapping((prev) => {
      const mapping = { ...prev };
      if (option?.value) mapping[trade._id] = option.value;
      else delete mapping[trade._id];
      return mapping;
    });
  };

  const submit = async (data) => {
    let updateParams: Partial<Job>;
    if (data.enable_trades) {
      const hasTrades = Object.values(newMapping).find((prg) => !!prg);
      updateParams = {
        pay_rate_group: null,
        trades: hasTrades ? newMapping : null,
      };
    } else {
      updateParams = {
        pay_rate_group: data.pay_rate_group?.value || null,
        trades: null,
      };
    }
    await updateJob(updateParams);
    hide();
  };

  return (
    <ActionModal
      headerText={
        !!formData.enable_trades
          ? `Edit pay rate groups for ${jobData.name}`
          : `Edit pay rate group for ${jobData.name}`
      }
      showSubmit={true}
      showCancel={true}
      cancelText={"Close"}
      onCancel={hide}
      submitText={"Update"}
      onHide={hide}
      onSubmit={handleSubmit(submit)}
      loading={updatingJob}
      wrapperStyle={{ width: "700px" }}
      submitDisabled={updatingJob}
    >
      {!!relevantTrades?.length && (
        <Formblock
          text="Use multiple pay rate groups for different trades"
          type="checkbox"
          control={control}
          className="modal"
          defaultValue={!!jobData?.trades}
          name="enable_trades"
          register={register}
          editing={true}
          errors={errors}
          style={{ marginTop: 10 }}
        />
      )}
      {!formData.enable_trades && (
        <Formblock
          label="Pay rate group"
          type="select"
          control={control}
          className="modal"
          options={allPrgOptions}
          defaultValue={jobData?.pay_rate_group}
          name="pay_rate_group"
          editing={true}
          isClearable={true}
          errors={errors}
          style={{ marginTop: 10 }}
        />
      )}
      {!!formData.enable_trades && (
        <>
          <div className="vertical-spacer-small" />
          <div className={groupStyles["person-row"] + " " + groupStyles["columns"]}>
            <div className={groupStyles["person-name"] + " " + groupStyles["column"]}>Trade</div>
            <div className={groupStyles["miter-tm-select"] + " " + groupStyles["column"]}>Pay rate group</div>
          </div>
          <div className={groupStyles["rows-wrapper"]}>
            {relevantTrades?.map((trade) => {
              return (
                <TradePrgRow
                  prgOptions={prgOptionsByTradeMap[trade._id]!}
                  key={trade._id}
                  trade={trade}
                  handleSelectInputChange={handleSelectInputChange}
                  newMapping={newMapping}
                />
              );
            })}
            <div className="vertical-spacer-large" />
          </div>
        </>
      )}
    </ActionModal>
  );
};

export const TradePrgRow: React.FC<{
  trade: Trade;
  handleSelectInputChange: (trade: Trade, o: Option<string> | null | undefined) => void;
  newMapping: Record<string, string>;
  prgOptions: Option<string>[];
}> = ({ trade, handleSelectInputChange, newMapping, prgOptions }) => {
  const value = prgOptions.find((o) => o.value === newMapping[trade._id]);

  return (
    <div key={trade._id} className={groupStyles["person-row"]}>
      <div className={groupStyles["person-name"]}>{<div>{trade.name}</div>}</div>
      <div className={groupStyles["miter-tm-select"]}>
        <Select
          key={trade._id}
          name="trade_pay_rate_group"
          options={prgOptions}
          maxMenuHeight={175}
          value={value}
          width="300px"
          height="32px"
          styles={styles}
          onChange={(o: ValueType<Option<string>, false>) => handleSelectInputChange(trade, o)}
          isClearable
        />
      </div>
    </div>
  );
};
