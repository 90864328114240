import React from "react";
import styles from "./TertiaryToggler.module.css";
import { TogglerProps, TogglerConfigItem } from "../toggler/Toggler";
import { Badge } from "../badge";

type TertiaryTogglerProps = Omit<
  TogglerProps,
  "secondary" | "modal" | "children" | "classname" | "rightHandContent" | "type" | "wrapperStyle"
>;

const TertiaryToggler: React.FC<TertiaryTogglerProps> = ({ config, toggle, active }) => {
  const renderCountBadge = (option: TogglerConfigItem) => {
    if (option.count == null) return;
    return (
      <div className={styles["toggler-count-badge-container"]}>
        <Badge className={styles["toggler-count-badge"]} text={option.count + ""} />
      </div>
    );
  };

  const getOptionClassName = (option: TogglerConfigItem) => {
    return option.path === active
      ? styles["tertiary-toggler-option-highlighted"]
      : styles["tertiary-toggler-option"];
  };

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["main-container"]}>
        {config.map((option, index) => {
          const togglePath = () => toggle(option.path);
          const optionClassName = getOptionClassName(option);
          return (
            <div onClick={togglePath} key={index} className={optionClassName}>
              <div className={styles["toggler-option-label-container"]}>
                <span>{option.label}</span>
                {renderCountBadge(option)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TertiaryToggler;
