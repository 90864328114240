import styles from "../TeamMemberChecklistBuilder.module.css";
import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { I9EmployeeOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";
import { Formblock } from "ui";
import { useCallback } from "react";
import { useHasAccessToWorkAuthorization } from "dashboard/gating";

export const I9EmployeeTaskContent: React.FC<ChecklistTaskContentProps<I9EmployeeOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  const hasAccessToWorkAuthorization = useHasAccessToWorkAuthorization();

  const handleEVerifyChange = useCallback(
    (checked: boolean) => {
      if (!setTask) return;

      setTask({
        ...task,
        data: {
          ...task.data,
          e_verify: checked,
        },
      });
    },
    [setTask, task]
  );

  return (
    <div className={styles["team-member-checklist-task-content"]}>
      Employee must complete Section 1 of Form I-9.
      {hasAccessToWorkAuthorization && (
        <Formblock
          name="i9-e-verify"
          type="checkbox"
          text="Use E-Verify"
          tooltipText="Miter will use E-Verify on your behalf to verify the team member's employment authorization in the US."
          defaultValue={task.data?.e_verify}
          editing={true}
          className="modal"
          style={{ marginBottom: 0, marginTop: 10 }}
          onChange={(e) => handleEVerifyChange(e.target.checked)}
        />
      )}
      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
