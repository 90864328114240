import React from "react";
import { Formblock } from "ui";
import { DeepPartial } from "utility-types";
import { UseFormMethods, FieldValues } from "react-hook-form";
import { DEFAULT_ACUMATICA_LABEL_STYLE, ACUMATICA_INPUT_LENGTH } from "./AcumaticaConfig";
import {
  AcumaticaDataForConfig,
  AcumaticaConfigObject,
  AcumaticaConnectionMetadata,
} from "../../../../../../backend/services/acumatica/acumatica-types";
import { Option } from "ui/form/Input";
import { useDebouncedCallback } from "use-debounce/lib";

type Props = {
  updateAcumaticaMetadata: (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => Promise<void>;
  dataForConfig?: AcumaticaDataForConfig;
  configObject?: AcumaticaConfigObject;
  loading?: boolean;
  form: UseFormMethods<FieldValues>;
};

export const standardClassificationLabelOptions: Option<string>[] = [
  { label: "Inventory ID", value: "inventory_id" },
  { label: "Description", value: "description" },
];

export const AcumaticaClassificationSync: React.FC<Props> = ({
  updateAcumaticaMetadata,
  configObject,
  form,
}) => {
  const updateDefaultSelection = async (subObj: AcumaticaConfigObject["standard_classifications"]) => {
    await updateAcumaticaMetadata(
      { configObject: { standard_classifications: subObj } },
      { collapseCount: 2 }
    );
  };

  const debouncedHandler = useDebouncedCallback(
    (
      property: keyof NonNullable<AcumaticaConfigObject["standard_classifications"]>,
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      updateDefaultSelection({ [property]: e.target.value || null });
    },
    400
  );

  const { defaultItemClassId, defaultPostingClassId, whatToSync } =
    configObject?.standard_classifications || {};

  return (
    <>
      <Formblock
        form={form}
        name="whatToSync"
        type="checkbox"
        label="Classification push"
        defaultValue={whatToSync === "prgClassifications"}
        labelInfo=""
        text="Create a Non Stock Item for each Pay Rate Group-classification combination"
        onChange={(e) => {
          updateDefaultSelection({
            whatToSync: e.target.checked ? "prgClassifications" : "standardClassifications",
          });
        }}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        underlineTooltip
      />
      <Formblock
        form={form}
        name="defaultItemClassId"
        type="text"
        defaultValue={defaultItemClassId}
        onChange={(s) => debouncedHandler("defaultItemClassId", s)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default item class"
        labelInfo="The item class Miter will use when creating Non Stock Items in Acumatica."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="defaultPostingClassId"
        type="text"
        defaultValue={defaultPostingClassId}
        onChange={(s) => debouncedHandler("defaultPostingClassId", s)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default posting class"
        labelInfo="The posting class Miter will use when creating Non Stock Items in Acumatica."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
    </>
  );
};
