import { ClickAwayListener } from "@material-ui/core";
import { AggregatedJob, Job } from "dashboard/miter";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, ClickableText, Formblock, ModalHeader } from "ui";
import DataBox from "dashboard/components/dataBox/DataBox";
import { Option } from "ui/form/Input";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";
import { useHasAccessToJobAccessFilters } from "dashboard/gating";
import {
  TeamMemberGroupSelectValue,
  useTeamMemberGroupOptions,
} from "dashboard/components/team-members/useTeamMemberGroupOptions";
import { isEqual } from "lodash";
import { EntityTeamMembersModal } from "dashboard/components/access-filters/EntityTeamMembersModal";
import { useAccessFilter } from "dashboard/hooks/access-filters/useAccessFilter";

type Props = {
  data: AggregatedJob;
  updateJob: (update: Partial<Job> & Record<string, $TSFixMe>) => Promise<void>;
  updatingJob: boolean;
};

export const JobAccessFilters: React.FC<Props> = (props) => {
  const { data } = props;
  const [editing, setEditing] = useState(false);

  const jobAbilities = useJobAbilities();
  const accessFilters = useAccessFilter({ entityName: "job" });

  const teamMemberWithAccessCount = useMemo(() => {
    const teamMemberIds = accessFilters.teamMemberIds({ entity: data }) || [];

    return teamMemberIds.size;
  }, [data, accessFilters]);

  const hasAccessToJobAccessFilters = useHasAccessToJobAccessFilters();

  if (!hasAccessToJobAccessFilters) return null;
  return (
    <DataBox
      title="Access Filters"
      className={undefined}
      onEdit={jobAbilities.can("update", data) ? () => setEditing(true) : undefined}
      editText={undefined}
      rows={undefined}
    >
      <>
        {editing && <JobAccessFiltersModal {...props} hide={() => setEditing(false)} />}
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>Team members with access</span>
          <span className={"data-box-section-value font-14"}>{teamMemberWithAccessCount}</span>
        </div>
      </>
    </DataBox>
  );
};

export const JobAccessFiltersModal: React.FC<Props & { hide: () => void }> = (props) => {
  const { data: jobData, updateJob, updatingJob, hide } = props;
  const { errors, handleSubmit, control } = useForm();

  const [showCustomAccessFilter, setShowCustomAccessFilter] = useState<boolean>(
    !!jobData.enable_custom_access_filter
  );
  const [accessFilterViewType, setAccessFilterViewType] = useState<"included" | "excluded">("included");
  const [openTeamMembersModal, setOpenTeamMembersModal] = useState<boolean>(false);

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: true,
    excludedGroups: ["self"],
  });

  const [selectedIncludedTMGroupsOption, setSelectedIncludedTMGroupsOption] = useState<
    Option<TeamMemberGroupSelectValue>[]
  >(
    teamMemberGroupOptions
      .flatMap((o) => o.options)
      ?.filter((g) =>
        (jobData?.custom_access_filter?.included_groups || []).some((sg) => isEqual(sg, g.value))
      )
  );

  const [selectedExcludedTMGroupsOption, setSelectedExcludedTMGroupsOption] = useState<
    Option<TeamMemberGroupSelectValue>[]
  >(
    teamMemberGroupOptions
      .flatMap((o) => o.options)
      ?.filter((g) =>
        (jobData?.custom_access_filter?.excluded_groups || []).some((sg) => isEqual(sg, g.value))
      )
  );

  const includedTeamMemberGroupOptions = useMemo(() => {
    const includedTMGroupOptions = teamMemberGroupOptions.map((teamMemberGroupOption) => {
      const options = teamMemberGroupOption.options;

      const updatedOptions = options.filter(
        (option) => !selectedExcludedTMGroupsOption.some((o) => isEqual(o, option))
      );

      return { label: teamMemberGroupOption.label, options: updatedOptions };
    });

    return includedTMGroupOptions;
  }, [selectedExcludedTMGroupsOption, teamMemberGroupOptions]);

  const excludedTeamMemberGroupOptions = useMemo(() => {
    const excludedTMGroupOptions = teamMemberGroupOptions.map((teamMemberGroupOption) => {
      const options = teamMemberGroupOption.options;

      const updatedOptions = options.filter(
        (option) => !selectedIncludedTMGroupsOption.some((o) => isEqual(o, option))
      );

      return { label: teamMemberGroupOption.label, options: updatedOptions };
    });

    return excludedTMGroupOptions;
  }, [selectedIncludedTMGroupsOption, teamMemberGroupOptions]);

  const submit = async () => {
    const customAccessFiltersPayload = formatCustomAccessFilterPayload();

    await updateJob({
      ...customAccessFiltersPayload,
    });
    hide();
  };

  const formatCustomAccessFilterPayload = () => {
    if (showCustomAccessFilter) {
      return {
        enable_custom_access_filter: true,
        custom_access_filter: {
          included_groups: selectedIncludedTMGroupsOption?.map((option) => option.value),
          excluded_groups: selectedExcludedTMGroupsOption?.map((option) => option.value),
        },
      };
    } else {
      return {
        enable_custom_access_filter: false,
        custom_access_filter: { included_groups: [], excluded_groups: [] },
      };
    }
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form" style={{ width: 800 }}>
          <ModalHeader heading={`Access filters for ${jobData.name}`} onHide={hide} />
          <div className="modal-body form">
            <>
              <h4 style={{ marginBottom: -2 }}>Team member access filters</h4>
              <Formblock
                text="Override global access filters by specifying who can access this job"
                type="checkbox"
                control={control}
                defaultValue={jobData?.enable_custom_access_filter}
                name="enable_custom_access_filter"
                className="modal"
                errors={errors}
                editing={true}
                onChange={() => setShowCustomAccessFilter(!showCustomAccessFilter)}
              />
              {showCustomAccessFilter && (
                <>
                  <>
                    <Formblock
                      type="multiselect"
                      name="custom_access_filter.included_groups"
                      label={"Team member groups with access"}
                      control={control}
                      editing={true}
                      className="modal"
                      placeholder={"Select team members"}
                      value={selectedIncludedTMGroupsOption}
                      options={includedTeamMemberGroupOptions}
                      height="unset"
                      onChange={(selectedOptions) => {
                        setSelectedIncludedTMGroupsOption(selectedOptions || []);
                      }}
                    />
                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                      <ClickableText
                        text="View team members with access"
                        onClick={() => {
                          setAccessFilterViewType("included");
                          setOpenTeamMembersModal(true);
                        }}
                      />
                    </div>
                  </>
                  <>
                    <Formblock
                      type="multiselect"
                      name="excluded_groups"
                      label={"Team member groups without access"}
                      control={control}
                      editing={true}
                      className="modal"
                      placeholder={"Select team members"}
                      value={selectedExcludedTMGroupsOption}
                      options={excludedTeamMemberGroupOptions}
                      height="unset"
                      onChange={(selectedOptions) => {
                        setSelectedExcludedTMGroupsOption(selectedOptions || []);
                      }}
                    />
                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                      <ClickableText
                        text="View team members without access"
                        onClick={() => {
                          setAccessFilterViewType("excluded");
                          setOpenTeamMembersModal(true);
                        }}
                      />
                    </div>
                    <div className="vertical-spacer-small"></div>
                    {openTeamMembersModal && (
                      <EntityTeamMembersModal
                        selectedEntity={jobData}
                        setOpenTeamMembersModal={setOpenTeamMembersModal}
                        accessType={accessFilterViewType}
                        entityType={"job"}
                      />
                    )}
                  </>
                </>
              )}
            </>
            <div className="vertical-spacer"></div>
          </div>
          <div className="modal-footer form">
            <button className={updatingJob ? "button-1 inactive " : "button-1"} onClick={hide}>
              Cancel
            </button>
            <Button onClick={handleSubmit(submit)} loading={updatingJob} className="button-2" text="Update" />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
