import { LedgerAccount } from "dashboard/miter";
import React from "react";
import BulkOptionInfo from "dashboard/pages/expenses/BulkOptionInfo";
import { setSelectableGLAccountsForExpenses } from "dashboard/pages/expenses/expenseUtils";
import {
  useLedgerAccountOptions,
  useLookupLedgerAccount,
  useRefetchLedgerAccounts,
} from "dashboard/hooks/atom-hooks";
import { BulkSelectOptionValue } from "ui/bulk-actions/BulkSelector";
import { Label } from "ui";

const selectableLedgerAccountPredicate = (a: LedgerAccount) => !!a.is_selectable_by_team_members;

export const ExpensesAccountingSettings: React.FC = () => {
  const refetchLedgerAccounts = useRefetchLedgerAccounts();
  const ledgerAccountOptions = useLedgerAccountOptions();
  const selectedSelectableAccountOptions = useLedgerAccountOptions({
    predicate: selectableLedgerAccountPredicate,
  });
  const minGLSelectWidth = 600;

  const lookupLedgerAccount = useLookupLedgerAccount();

  const onSelectableAccountSubmission = async (
    newSelections: BulkSelectOptionValue[],
    removedSelections: BulkSelectOptionValue[]
  ) => {
    // filter out the selected options that are already selected
    const newlySelectedOptions = newSelections.filter(
      (id) => !lookupLedgerAccount(id)?.is_selectable_by_team_members
    );

    await setSelectableGLAccountsForExpenses(newlySelectedOptions, removedSelections);
    await refetchLedgerAccounts();
  };

  return (
    <div className="billing-card-wrapper">
      <h3>GL accounts</h3>
      <div className="flex" style={{ alignItems: "flex-start", width: minGLSelectWidth }}>
        <div>
          <Label
            label="GL accounts selectable for spend management products:"
            labelInfo="Applies to card transactions, reimbursements and bills."
            className="form2-label"
            style={{ width: "100%" }}
          />
          <BulkOptionInfo
            resource="accounts"
            options={ledgerAccountOptions}
            onSubmit={onSelectableAccountSubmission}
            selectedOptions={selectedSelectableAccountOptions}
          />
        </div>
      </div>
    </div>
  );
};
