import React from "react";
import Banner from "../shared/Banner";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { useNavigate } from "react-router-dom";

export const SecuritySettingsBanner: React.FC = () => {
  const activeCompany = useActiveCompany();
  const navigate = useNavigate();

  if (activeCompany?.settings?.security?.enable_security_notifs) {
    return null;
  }

  return (
    <Banner
      type="warning"
      content="Security notifications are currently disabled. Enable them in your company settings to receive alerts about important security events."
      onClick={() => navigate("/settings/company")}
    />
  );
};
