import {
  useClasses,
  useLookupClass,
  useLookupLedgerMapping,
  useRefetchClasses,
} from "dashboard/hooks/atom-hooks";
import { MiterAPI, Class } from "dashboard/miter";
import { Gear, Plus, TrashSimple } from "phosphor-react";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import { DeleteModal, Notifier, TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { FailureItem } from "../shared/FailuresModal";
import { ClassModal } from "./ClassModal";
import { useNavigate } from "react-router-dom";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useEnhancedSearchParams } from "miter-utils";

const CLASS_SEARCH_PARAM_KEY = "clid";

export const ClassesTable: React.FC = () => {
  const classes = useClasses();
  const lookupClass = useLookupClass();
  const lookupLedgerMapping = useLookupLedgerMapping();

  const navigate = useNavigate();
  const { can } = useMiterAbilities();

  const { setFailures, renderFailuresModal } = useFailuresModal();

  const { setSearchParams, parsedSearchParams } = useEnhancedSearchParams();

  const clickedClass = lookupClass(parsedSearchParams[CLASS_SEARCH_PARAM_KEY]);

  // table select
  const [selectedClasses, setSelectedClasses] = useState<Class[]>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);
  const refreshClasses = useRefetchClasses();

  const columns = useMemo(() => {
    const cols: ColumnConfig<Class>[] = [
      {
        headerName: "Name",
        field: "name",
      },
      {
        headerName: "ID",
        field: "code",
      },
      {
        headerName: "Status",
        field: "status",
        displayType: "badge",
      },
      {
        headerName: "GL account mapping",
        field: "ledger_mapping_id",
        valueGetter: (params) => {
          const mapping = lookupLedgerMapping(params.data?.ledger_mapping_id);
          return mapping?.name;
        },
      },
    ];
    return cols;
  }, [lookupLedgerMapping]);

  const staticActions = useMemo(() => {
    const actions: TableActionLink[] = [
      {
        label: "Add class",
        className: "button-2",
        action: () => setAdding(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:classes:manage"),
      },
      {
        label: "Settings",
        className: "button-1",
        important: true,
        icon: <Gear weight="bold" style={{ marginRight: 3 }} />,
        action: () => navigate("/settings/classes/settings"),
        shouldShow: () => can("lists:classes:manage"),
      },
    ];
    return actions;
  }, [navigate, can]);

  const handleModalOnFinish = () => {
    setAdding(false);
    refreshClasses();
  };

  const handleModalClose = () => {
    setAdding(false);
    setSearchParams({ [CLASS_SEARCH_PARAM_KEY]: undefined });
  };

  const dynamicActions = useMemo(() => {
    const actions = [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setDeleteModal(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:classes:manage"),
      },
    ];

    return actions;
  }, [can]);

  const handleArchive = async () => {
    setDeleting(true);

    const idsToArchive = selectedClasses.map((r) => r._id);

    const errors: FailureItem[] = [];

    await Promise.all(
      idsToArchive.map(async (id) => {
        try {
          const response = await MiterAPI.classes.archive(id);
          if (response.error) throw new Error(response.error);
        } catch (e: $TSFixMe) {
          errors.push({
            label: id,
            message: e.message,
          });
        }
      })
    );

    if (errors.length) {
      setFailures(errors);
    } else {
      Notifier.success(`Deleted ${idsToArchive.length} ${pluralize("class", idsToArchive.length)}.`);
    }

    setSelectedClasses([]);
    setDeleting(false);
    setDeleteModal(false);

    // after deleting, refresh the classes
    refreshClasses();
  };

  return (
    <>
      {(clickedClass || adding) && (
        <ClassModal onFinish={handleModalOnFinish} onHide={handleModalClose} selectedClass={clickedClass} />
      )}
      {deleteModal && (
        <DeleteModal
          header={"Are you sure?"}
          body={`${selectedClasses.length > 1 ? "These classes" : "This class"} will be deleted.`}
          cancelText={"Cancel"}
          onHide={() => setDeleteModal(false)}
          deleteText={"Yes"}
          onDelete={handleArchive}
          loading={deleting}
        />
      )}
      <TableV2
        id="class-entries-table"
        resource="classes"
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        // individual row click
        onClick={(d) => setSearchParams({ [CLASS_SEARCH_PARAM_KEY]: d._id })}
        columns={columns}
        data={classes}
        editable={false}
        // table select
        onSelect={setSelectedClasses}
      />
      {renderFailuresModal()}
    </>
  );
};
