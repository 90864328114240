import React, { useEffect, useState, useCallback } from "react";
import { Button, Notifier, WizardScreen } from "ui";
import styles from "../forms/Forms.module.css";
import useWizard from "ui/modal/useWizard";
import { AggregatedI9, MiterAPI } from "dashboard/miter";
import { AggregatedTeamMember, TeamMember } from "dashboard/miter";
import { useTranslation } from "react-i18next";
import I9Wizard from "dashboard/components/i-9s/I9Wizard";
import { saveAs } from "file-saver";
import onboardingV2WizardStyles from "./onboardingChecklistWizardScreens.module.css";
import { OnboardingTaskRow } from "dashboard/components/tables/OnboardingTasksTable";
import { markTaskComplete } from "./utils";

type Props = {
  name: string;
  teamMember: AggregatedTeamMember | TeamMember;
  onboardingTask: OnboardingTaskRow;
  updateOnboardingChecklistTask: (task: OnboardingTaskRow) => Promise<void>;
  i9?: AggregatedI9;
  getI9: () => Promise<AggregatedI9 | undefined>;
};

export const EmployerI9WizardScreen: React.FC<Props> = ({
  name,
  teamMember,
  onboardingTask,
  updateOnboardingChecklistTask,
  ...props
}) => {
  const { t } = useTranslation<$TSFixMe>();
  const [fillingI9, setFillingI9] = useState(false);
  const [downloadingI9, setDownloadingI9] = useState(false);
  const { curIndex, handleComplete, screens, setCanNext, setNextButtonText } = useWizard();
  const [i9, setI9] = useState<AggregatedI9 | undefined>(props.i9);

  useEffect(() => {
    setI9(props.i9);
  }, [props.i9]);

  useEffect(() => {
    setCanNext(i9?.status === "complete" || !i9?.employee_esignature);
    setNextButtonText(
      !i9?.employee_esignature
        ? t("Continue and complete later")
        : curIndex + 1 >= screens.length
        ? t("Save and exit")
        : t("Save and continue")
    );
  }, [i9?.status, i9?.employee_esignature, curIndex, screens.length, setCanNext, setNextButtonText, t]);

  const fetchAndUpdateI9 = useCallback(async () => {
    try {
      const res = await props.getI9();
      if (res) setI9(res);
      return res;
    } catch (error) {
      console.error("Error fetching I9:", error);
      Notifier.error(t("Error fetching I-9. Please try again or contact support."));
      return undefined;
    }
  }, [props, t]);

  const onExit = async () => {
    await fetchAndUpdateI9();
    setFillingI9(false);
  };

  const onComplete = async () => {
    const updatedI9 = await fetchAndUpdateI9();
    if (updatedI9) {
      Notifier.success("I-9 reviewed successfully.");
      setFillingI9(false);
      setCanNext(true);
    }
  };

  const onNext = async () => {
    if (i9?.status === "complete") {
      if (curIndex + 1 >= screens.length) {
        handleComplete();
      }
      await markTaskComplete(onboardingTask, updateOnboardingChecklistTask);
    }
  };

  const handleDownloadClick = async () => {
    setDownloadingI9(true);
    try {
      if (!i9) throw new Error("I-9 not found");
      const res = await MiterAPI.i_9s.retrieve_pdf(i9._id);
      if (res.error) throw new Error(res.error);

      const blob = await res.blob();
      saveAs(blob, `I-9_${teamMember.full_name}.pdf`);
    } catch (e: $TSFixMe) {
      Notifier.error(t("Error downloading I-9 preview. Please contact support."));
      console.error("Error previewing I-9", e);
    }
    setDownloadingI9(false);
  };

  const fillI9 = () => {
    if (teamMember.ssn_last_four) {
      setFillingI9(true);
    } else {
      Notifier.error(t("Team member must first fill out their SSN before you can complete their I-9."));
    }
  };

  return (
    <WizardScreen name={name} key={name || "no-section"} onNext={onNext}>
      <div className={styles["content"]}>
        <div className={styles["subheader"]}>
          <h2 className={styles["subheader-title"]}>
            {t("Complete I-9 for")} {teamMember?.full_name}
          </h2>
          <p className={styles["subheader-description"]}>
            {t("Please follow the interactive steps to complete the employer section of the I-9")}
          </p>
        </div>
        <div className="form-section">
          {i9?.employee_esignature ? (
            i9?.status === "complete" ? (
              <div>
                <p>{t("I-9 has been successfully completed.")}</p>
                <Button
                  onClick={handleDownloadClick}
                  text={t("Download I-9")}
                  className={`button-2 no-margin ${onboardingV2WizardStyles["large-button"]}`}
                  loading={downloadingI9}
                />
              </div>
            ) : (
              <Button
                onClick={fillI9}
                text={t("Finalize I-9")}
                className={`button-2 no-margin ${onboardingV2WizardStyles["large-button"]}`}
              />
            )
          ) : (
            <div>
              {teamMember.full_name} {t("must first complete their section of the I-9.")}
            </div>
          )}
        </div>
        {fillingI9 && <I9Wizard onExit={onExit} onComplete={onComplete} I9={i9!} getI9={async () => i9} />}
      </div>
    </WizardScreen>
  );
};
